import { updateAdmin } from "../../../apis/admin";
import { addAdmin as addAdminAPI } from "../../../apis/admin";
import { VendorUser } from "../../../schemas";

export const editAdmin = async (data) => {
  // console.log("data for edit admin", data);
  const name = data.adminName;
  const email = data.adminEmail;
  const orgName = data.organizationName;
  const isAdmin = data.access;

  const dataToSend = {
    name,
    email,
    isAdmin,
    orgName,
  };

  // console.log("data to send", dataToSend);

  // let isValidated = validateTenantData(dataToSend);

  try {
    const res = await updateAdmin(dataToSend);
    return { status: res.status, message: "" };
  } catch (error) {
    console.log("error in edit admin", error);
    return error.response.status;
  }
};

export const addAdmin = async (data) => {
  const vendorName = data.adminName;
  const orgName = data.organizationName;
  const vendorUserEmail = data.adminEmail;
  // const access = data.access === "true" ? true : false;
  const access = data.access;

  const vendor = new VendorUser(access, vendorUserEmail, orgName, vendorName);

  const vendorJSON = JSON.parse(JSON.stringify(vendor));

  // alert("Creating a new admin. This might take a few minutes. Please wait....");

  let endpoint = "/api/users/create-vendor";
  try {
    const res = await addAdminAPI(endpoint, vendorJSON);
    return { status: res.status, message: "" };
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
};
