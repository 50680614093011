// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import { Link } from "react-router-dom";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import TenantInfoCard from "../../examples/Cards/InfoCards/TenantInfoCard";
import TimelineItem from "../../examples/Timeline/TimelineItem";
import PlatformSettings from "../profile/components/PlatformSettings";
import { useState, useEffect } from "react";
import { getTenantOnboardingStatus } from "../../apis/tenant";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, logout } from "../../redux/userSlice";
import { useLocation } from "react-router-dom";
import { element } from "prop-types";
import MDSnackbar from "../../components/MDSnackbar";
import getDateForSnackbar from "../../helperFunctions/getDate";

function TenantOnboardingStatus() {
	// const { columns, rows } = authorsTableData();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const [tenantData, setTenantData] = useState([]);
	const [tenantStatusData, setTenantStatusData] = useState([]);
	const [errorSB, setErrorSB] = useState(false);
	const closeErrorSB = () => setErrorSB(false);

	// const getDate = () => {
	//   var d = new Date(); // for now
	//   d.getHours(); // => 9
	//   d.getMinutes(); // =>  30
	//   return d.getHours + ":" + d.getMinutes;
	// };
	const location = useLocation();
	const subdomain = new URLSearchParams(location.search).get("subdomain");

	useEffect(() => {
		getTenantOnboardingStatus(subdomain)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					setTenantData(response.data);
					setTenantStatusData(response.data.statusArray);
				}
			})
			.catch((error) => {
				// alert(
				//   `Oops, an error occured while loading the page. Please try again after some time: ${error}`
				// );
				setErrorSB(true);
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			});
	}, []);

	// console.log(tenantStatusData);
	const listBuilds = tenantStatusData.map((element, index) => {
		let startTime = new Date(element.startTime).toLocaleString("en-US", {
			dateStyle: "long",
			timeStyle: "short",
		});

		return (
			<TimelineItem
				color={
					element.status === "SUCCESS"
						? "success"
						: element.status === "FAILURE"
						? "error"
						: element.status === "WORKING"
						? "warning"
						: "dark"
				}
				icon={
					element.status === "SUCCESS"
						? "check"
						: element.status === "FAILURE"
						? "clear"
						: element.status === "WORKING"
						? "query_builder"
						: "hourglass_empty"
				}
				title={element.groupTitle}
				dateTime={
					element.status === "PENDING"
						? ""
						: "Started on " + startTime
				}
				key={index}
				description={
					element.status === "FAILURE" ? (
						<a href={element.logURL} target="_blank">
							View Logs
						</a>
					) : null
				}
				lastItem={index == tenantStatusData.length - 1 ? true : false}
			/>
		);
	});
	const Reloadpage = () => {
		getTenantOnboardingStatus(subdomain)
			.then((response) => {
				if (response.status === 200) {
					setTenantData(response.data);
					setTenantStatusData(response.data.statusArray);
					// console.log("reloaded done");
				}
			})
			.catch((error) => {
				// alert(
				//   `Oops, an error occured while loading the page. Please try again after some time.`
				// );
				setErrorSB(true);
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			});
	};

	// Dummy Response for testing
	// let StatusArray = [
	//   {
	//     "finishTime": "April 13, 2023 at 6:22 PM",
	//     "sourceRepo": "some/random/path",
	//     "startTime": "April 13, 2023 at 6:21 PM",
	//     "id": "1234",
	//     "buildTitle": "Deploy multi Tenant Application",
	//     "logUrl": "some/random/URL",
	//     "status": "SUCCESS"
	//   },
	//   {
	//     "finishTime": "April 13, 2023 at 6:25 PM",
	//     "sourceRepo": "some/random/path",
	//     "startTime": "April 13, 2023 at 6:24 PM",
	//     "id": "1235",
	//     "buildTitle": "map the LB ip's with cloud dns",
	//     "logUrl": "some/random/URL",
	//     "status": "SUCCESS"
	//   },
	//   {
	//     "finishTime": "April 13, 2023 at 6:28 PM",
	//     "sourceRepo": "some/random/path",
	//     "startTime": "April 13, 2023 at 6:26 PM",
	//     "id": "1236",
	//     "buildTitle": "Grant iap web secure permission to customer email",
	//     "logUrl": "some/random/URL",
	//     "status": "FAILURE"
	//   },
	//   {
	//     "finishTime": "April 13, 2023 at 6:31 PM",
	//     "sourceRepo": "some/random/path",
	//     "startTime": "April 13, 2023 at 6:29 PM",
	//     "id": "1237",
	//     "buildTitle": " Destroy all the created secrets",
	//     "logUrl": "some/random/URL",
	//     "status": "PENDING"
	//   }
	// ];

	return (
		<>
			<MDSnackbar
				color="error"
				icon="warning"
				title="SaaS Admin Portal"
				content={"Error while loading tenant status"}
				dateTime={`at ${getDateForSnackbar()}`}
				open={errorSB}
				onClose={closeErrorSB}
				close={closeErrorSB}

				// bgWhite
			/>
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									pt={2}
									px={2}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									mx={2}
									mt={-3}
									py={3}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
								>
									<MDTypography variant="h6" color="white">
										Tenant Onboarding Status
									</MDTypography>
									<MDButton
										variant="gradient"
										color="dark"
										onClick={Reloadpage}
									>
										<Icon sx={{ fontWeight: "bold" }}>
											refresh
										</Icon>
										&nbsp;Refresh
									</MDButton>
								</MDBox>

								<MDBox p={2} pt={3} px={10}>
									<Grid container spacing={1}>
										<Grid sm={12} md={6}>
											<MDTypography
												variant="h6"
												// fontWeight="medium"
												textTransform="capitalize"
												py={2}
											>
												Tenant Details
											</MDTypography>
											<TenantInfoCard
												title="Tenant Details"
												metadata={tenantData?.info}
												info={{
													tenantId: [
														tenantData.subdomain,
													],
													adminEmail: [
														tenantData.adminEmail,
													],
													domain: [tenantData.domain],
													organizationName: [
														tenantData.orgName,
													],
													emailStatus:
														tenantData.emailVerified ===
														true
															? ["Verified"]
															: [
																	"Verification Pending",
															  ],
												}}
												logo={{
													logo: [tenantData.logoUrl],
													subdomain: [
														tenantData.subdomain,
													],
													tier: [tenantData.tier],
													domain: [tenantData.domain],
												}}
												action={{
													route: "",
													tooltip: "Edit Profile",
												}}
												shadow={false}
											/>
										</Grid>

										<Grid sm={12} md={6}>
											<MDTypography variant="h6" py={2}>
												Onboarding Process
											</MDTypography>
											{!tenantStatusData ? (
												<> Loading...</>
											) : tenantStatusData.length ===
											  0 ? (
												<MDTypography
													variant="h6"
													fontWeight="light"
													textTransform="capitalize"
												>
													Please wait for Tenant to
													start onboarding
												</MDTypography>
											) : (
												<>{listBuilds}</>
											)}
										</Grid>
									</Grid>
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>
			</DashboardLayout>
		</>
	);
}

export default TenantOnboardingStatus;
