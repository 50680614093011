// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import DataTable from "../../examples/Tables/DataTable";
import { Link, useNavigate } from "react-router-dom";

// Data
import authorsTableData from "./data/authorsTableData";
import MDSnackbar from "../../components/MDSnackbar";
import { useEffect, useState } from "react";

import { listTenantUser } from "../../apis/tenant";
import getDateForSnackbar from "../../helperFunctions/getDate";

function Admin() {
	const deleteStatusForAdmin = (status) => {
		setDeleteStatus(status);
	};

	const checkIsDataPresent = (status) => {
		setIsDataPresent(status);
	};

	const updateStatusNodeCount=(status)=>{
		setupdateStatus(status)
	}

	const navigate = useNavigate();
	const { columns, rows } = authorsTableData(
		deleteStatusForAdmin,
		checkIsDataPresent,
		updateStatusNodeCount,
	);
	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState("");
	const [isDataPresent, setIsDataPresent] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [updateStatus, setupdateStatus] = useState("");
	const [statusColor, setStatusColor] = useState("success")
	const [successMessage, setSuccessMessage] = useState("")

	const closeSuccessSB = () => setSuccessSB(false);
	const closeErrorSB = () => setErrorSB(false);
	useEffect(() => {
		if (isDataPresent === "false") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setErrorMessage(
				"Error while loading tenants-user,please try later"
			);
			setErrorSB(true);
			setIsDataPresent("");
		}
		if (deleteStatus === "deleted") {
			//   setGetDate(`at ${hour} : ${minutes}`);
			setSuccessSB(true);
			setStatusColor("success")
			setDeleteStatus("");
			setSuccessMessage("Deleted tenant-user successfully")
			window.location.href = "/sign-in";
		} 
		if (updateStatus==="updated")
		{
			setSuccessSB(true);
			setStatusColor("info")
			setSuccessMessage("Updated tenant-user request accepted successfully")
			setupdateStatus("");
		}
		if (updateStatus==="notUpdated")
		{
			setErrorMessage("Error while updating tenant-user");
			setErrorSB(true);
			setupdateStatus("");
		}
		else if (deleteStatus === "notDeleted") {
			setErrorMessage("Error while deleting tenant-user");
			setErrorSB(true);
			setDeleteStatus("");
		}
	}, [deleteStatus,updateStatus]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{/* {successSB ? ( */}
			<MDSnackbar
				color={statusColor}
				icon="check"
				title="SaaS Admin Portal"
				content={successMessage}
				dateTime={`at ${getDateForSnackbar()}`}
				open={successSB}
				onClose={closeSuccessSB}
				close={closeSuccessSB}

				// bgWhite
			/>
			{/* // ) : ( */}
			<MDSnackbar
				color="error"
				icon="warning"
				title="SaaS Admin Portal"
				content={errorMessage}
				dateTime={`at ${getDateForSnackbar()}`}
				open={errorSB}
				onClose={closeErrorSB}
				close={closeErrorSB}

				// bgWhite
			/>
			{/* // )} */}
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								pt={2}
								px={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mx={2}
								mt={-3}
								py={3}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
							>
								<MDTypography variant="h6" color="white">
									Users
								</MDTypography>
								<MDButton
									variant="gradient"
									color="dark"
									component={Link}
									to={"/add-tenantuser"}
								>
									<Icon sx={{ fontWeight: "bold" }}>add</Icon>
									&nbsp;Add new user
								</MDButton>
							</MDBox>
							<MDBox pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default Admin;
