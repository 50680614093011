// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import DataTable from "../../examples/Tables/DataTable";
import { Link, useNavigate } from "react-router-dom";

// Data
import authorsTableData from "./data/authorsTableData";
import MDSnackbar from "../../components/MDSnackbar";
import { useEffect, useState } from "react";

import { listTenantUser } from "../../apis/tenant";
import getDateForSnackbar from "../../helperFunctions/getDate";
import { useSelector } from "react-redux";
import { selectVendor } from "../../redux/userSlice";

function Admin() {
	const [infoSB, setInfoSB] = useState(false);
	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState("");
	const [isDataPresent, setIsDataPresent] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const vendor=useSelector(selectVendor)

	const closeSuccessSB = () => setSuccessSB(false);
	const closeInfosSB = () => setInfoSB(false);
	const closeErrorSB = () => setErrorSB(false);

	const deleteStatusForAdmin = (status) => {
		setDeleteStatus(status);
	};

	const checkIsDataPresent = (status) => {
		setIsDataPresent(status);
	};

	const navigate = useNavigate();
	const { columns, rows } = authorsTableData(
		deleteStatusForAdmin,
		checkIsDataPresent
	);
	
	useEffect(() => {
		// let d = new Date();
		// let hour = d.getHours();
		// let minutes = d.getMinutes();
		if (isDataPresent === "false") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setErrorMessage("Error while loading tenants,please try later");
			setErrorSB(true);
			setIsDataPresent("");
		}
		if (deleteStatus === "inprogress") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setInfoSB(true);
			setDeleteStatus("");
			// window.location.reload();
		} else if (deleteStatus === "deleted") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setSuccessSB(true);
			setDeleteStatus("");
			setTimeout(()=>{
				window.location.reload();
			},1500)
		} else if (deleteStatus === "notDeleted") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setErrorMessage("Error while deleting tenant");
			setErrorSB(true);
			setDeleteStatus("");
		}
	}, [deleteStatus, isDataPresent]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{infoSB && (
				<MDSnackbar
					color="info"
					icon="notifications"
					title="SaaS Admin Portal"
					content="Adding Tenant To Deletion Queue."
					// content="Deleting tenant in progress"
					open={infoSB}
					onClose={closeInfosSB}
					close={closeInfosSB}

					// bgWhite
				/>
			)}
			{successSB ? (
				<MDSnackbar
					color="success"
					icon="check"
					title="SaaS Admin Portal"
					content="Tenant Deletion In Progress."
					// content="Deleted tenant successfully"
					open={successSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}
					// bgWhite
				/>
			) : (
				<MDSnackbar
					color="error"
					icon="warning"
					title="SaaS Admin Portal"
					content={errorMessage}
					open={errorSB}
					onClose={closeErrorSB}
					close={closeErrorSB}
					// bgWhite
				/>
			)}
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								pt={2}
								px={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mx={2}
								mt={-3}
								py={3}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
							>
								<MDTypography variant="h6" color="white">
									Tenants
								</MDTypography>
								{
									!vendor.isAdmin ? null
									: <>
									<MDButton
									// disabled={!vendor.isAdmin}
									variant="gradient"
									color="dark"
									component={Link}
									to={"/new-tenant"}
								>
									<Icon sx={{ fontWeight: "bold" }}>add</Icon>
									&nbsp;Add new tenant
								</MDButton>
									</>
								}
							</MDBox>
							<MDBox pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default Admin;
