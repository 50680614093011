import apiClient from "../apiClient";

export const getAllTenant = async () => {
	const response = await apiClient.get("/api/tenants/list");
	return response;
};

export const getRegions = async () => {
	const response = await apiClient.get("/api/locations?resources=regions");
	return response;
};

export const getZones = async (region) => {
	const response = await apiClient.get(
		`/api/locations?resources=zones&region=${region}`
	);
	return response;
};

export const addTenant = async (endpoint, dataToSend) => {
	const response = await apiClient.post(endpoint, dataToSend);
	return response;
};

export const updateTenant = async (dataToSend) => {
	const response = await apiClient.post("/api/trigger/update", dataToSend);
	return response;
};

export const deleteTenant = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/trigger/delete", dataToSend
		);
	return response;
};

export const addTenantUsers = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/users/new/tenant-user",
		dataToSend
	);
	return response;
};

export const listTenantUser = async (subdomain) => {
	const response = await apiClient.get(
		`/api/tenants/tenantUser/list/${subdomain}`
	);
	return response;
};

export const deleteTenantUser = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/users/delete/tenant-user",
		dataToSend
	);
	return response;
};

export const loginTenant = async (idToken, email) => {
	const response = await apiClient.post("/api/tenants/authenticate", {
		idToken: idToken,
		email: email,
	});
	return response;
};

export const selfOnboardTenant = async (dataToSend) => {
	const response = await apiClient.post("/api/trigger/freemium", dataToSend);
	return response.data.tenants;
};

export const getTenantOnboardingStatus = async (dataToSend) => {
	const response = await apiClient.get("/api/tenants/status", {
		params: { subdomain: dataToSend },
	});
	return response;
};

export const getIdentityProviders = async (subdomain) => {
	const response = await apiClient.get(
		`/api/tenants/provider-configurations/list/${subdomain}`
	);
	return response;
};

export const getIdentityProviderdetails = async (subdomain, providerId) => {
	const response = await apiClient.get(
		`/api/tenants/provider-configurations/${subdomain}/${providerId}`
	);
	return response;
};

export const addOidcProvider = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/tenants/provider-configurations/new/oidc",
		dataToSend
	);
	return response;
};

export const updateOidcProvider = async (dataToSend) => {
	const response = await apiClient.patch(
		"/api/tenants/provider-configurations/update/oidc",
		dataToSend
	);
	return response;
};

export const addSamlProvider = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/tenants/provider-configurations/new/saml",
		dataToSend
	);
	return response;
};

export const updateSamlProvider = async (dataToSend) => {
	const response = await apiClient.patch(
		"/api/tenants/provider-configurations/update/saml",
		dataToSend
	);
	return response;
};

export const addGoogleProvider = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/tenants/provider-configurations/googleIdp",
		dataToSend
	);
	return response;
};

export const deleteIdenityProvider = async (subdomain, providerId) => {
	const response = await apiClient.delete(
		`/api/tenants/provider-configurations/${subdomain}/${providerId}`
	);
	return response;
};

export const resetPassword = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/tenants/reset-password",
		dataToSend
	);
	return response;
};

export const getCurrentNodeCount = async (dataToSend) => {
	const response = await apiClient.post(
		`/api/tenants/getNodeCount`,
		dataToSend
	);
	return response;
};

export const updateCurrentNodeCount = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/tenants/updateNodeCount",
		dataToSend
	);
	return response;
};

export const getGPUTypes = async (machineType) => {
	const response = await apiClient.get(
		`/api/getGPU/${machineType}`,
	);
	return response;
};

//gpuRoutes
export const getGPUcount = async (gpuType, machineType) => {
	const response = await apiClient.get(
		`/api/getGPU/${machineType}/${gpuType}`
	);
	return response;
}

//vm routes
export const addVmTenant = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/vmtrigger/create",
		dataToSend
	);
	return response;
}


export const updateVmTenant = async (dataToSend) => {
	const response = await apiClient.post("/api/vmtrigger/update", dataToSend);
	return response;
};


export const deleteVmTenant = async (dataToSend) => {
		const response = await apiClient.post("/api/vmtrigger/delete", dataToSend);
		return response;
};