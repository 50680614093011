import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import EditVmTenantForm from "../../../vmForms/editTenant";

function EditVmTenant() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EditVmTenantForm />
    </DashboardLayout>
  );
}

export default EditVmTenant;
