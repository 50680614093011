// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import { Link } from "react-router-dom";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import TenantInfoCard from "../../examples/Cards/InfoCards/TenantInfoCard";
import TimelineItem from "../../examples/Timeline/TimelineItem";
import PlatformSettings from "../profile/components/PlatformSettings";
import { useState, useEffect } from "react";
import { getTenantOnboardingStatus } from "../../apis/tenant";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import { useLocation } from "react-router-dom";
import { element } from "prop-types";
import MDSnackbar from "../../components/MDSnackbar";
import getDateForSnackbar from "../../helperFunctions/getDate";

function ApigeeInstructions() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mx={2}
                  mt={-3}
                  py={3}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Apigee Instructions
                  </MDTypography>
                </MDBox>

                <MDBox p={2} pt={3} px={10}>
                  <Grid container spacing={1}>
                    <Grid item sm={12}>
                      <h4 style={{ padding: "10px 0", color: "#1A73E8" }}>
                        <u>
                          Testing the Apigee RESTful API proxies via postman
                        </u>
                      </h4>
                      <p style={{ paddingBottom: "15px", fontSize: "18px" }}>
                        Note: The APIs require a valid OAuth access token when
                        calling. The
                        <code>auth-code-api-v1</code> proxy deployed as part of
                        the demo implements a 3-legged
                        <a href="https://cloud.google.com/apigee/docs/api-platform/security/oauth/oauth-v2-policy-authorization-code-grant-type">
                          OAuth authorization code
                        </a>
                        flow. In order to obtain an access token, the resource
                        owner (i.e. the end user) must login and provide consent
                        for the app to access their banking data.
                      </p>
                      <ol>
                        <li style={{ paddingBottom: "15px", fontSize: "18px" }}>
                          <h4
                            style={{
                              padding: "10px 0",
                            }}
                          >
                            To start the flow, execute the following request in
                            a postman using the key for the App that was
                            registered in Apigee: (This call can be made via
                            browser as well)
                          </h4>
                          <div
                            style={{
                              padding: "8px 8px",
                              borderStyle: "solid",
                              borderRadius: "10px",
                              borderColor: "#49a3f1",
                            }}
                          >
                            <p>
                              “https://{"{"}
                              APIGEE_HOST{"}"}
                              /oauth/authorize-code?client_id=
                              {"{"}
                              APPKEY{"}"}
                              &amp;response_type=code&amp;redirect_uri=https://
                              {"{"}
                              APIGEE_DEVPORTAL_HOST
                              {"}"}
                              /oauth_redirect&amp;scope=READ"
                            </p>
                            <p>for example:</p>

                            <code>
                              https://[a]saas-apigee-internal-eval.apigee.net/oauth/authorize-code?client_id=MhdpG70XMYZmQ34zGppGSmUFTsydF0NgEA6ArTLEsQFM1lpe&amp;response_type=code&amp;redirect_uri=https://saas-apigee-internal-cymbalbank.apigee.io/oauth_redirect&amp;scope=READ
                            </code>
                          </div>

                          <p style={{ paddingTop: "10px" }}>
                            <strong>NOTE</strong>: The <code>redirect_uri</code>{" "}
                            parameter above MUST match the "Callback URL"
                            specified in the App
                          </p>
                          <p>
                            Copy the authorization code in the headers section
                          </p>
                          <p>
                            Note:copy the code value from the url in the browser
                            If you are hitting the request from browser you will
                            get below response in the browser and copy the code
                          </p>
                          <p>
                            Eg:
                            <a href="https://qwiklabs-gcp-02-80227f0e1ee2-t10.apigee.io/oauth_redirect?code=z862A2Vo">
                              https://qwiklabs-gcp-02-80227f0e1ee2-t10.apigee.io/oauth_redirect?code=z862A2Vo
                            </a>
                          </p>
                        </li>
                        <li style={{ paddingBottom: "15px", fontSize: "18px" }}>
                          <h4
                            style={{
                              padding: "10px 0",
                            }}
                          >
                            Exchange the code for an access token by running the
                            following request using the credentials for the
                            registered App:
                          </h4>
                          <div
                            style={{
                              padding: "8px 8px",
                              borderStyle: "solid",
                              borderRadius: "10px",
                              borderColor: "#49a3f1",
                            }}
                          >
                            <code>
                              curl -X POST https://
                              {"{"}
                              APIGEE_HOST
                              {"}"}
                              /oauth/token \{"\n"}
                              {"                  "}
                              -u {"{"}
                              APPKEY{"}"}:{"{"}
                              APPSECRET
                              {"}"} \{"\n"}
                              {"                  "}
                              -H "Content-Type:
                              application/x-www-form-urlencoded" \
                              {"             "}
                              -d"grant_type=authorization_code&amp;redirect_uri=https://
                              {"{"}
                              APIGEE_DEVPORTAL_HOST
                              {"}"}
                              /oauth_redirect&amp;code=
                              {"{"}CODE FROM STEP 1{"}"}"
                            </code>
                          </div>

                          <p style={{ padding: "10px 0px" }}>for example:</p>

                          <div
                            style={{
                              padding: "8px 8px",
                              borderStyle: "solid",
                              borderRadius: "10px",
                              borderColor: "#49a3f1",
                            }}
                          >
                            <code>
                              curl -X POST
                              saas-apigee-internal-eval.apigee.net/oauth/token \
                              {"\n"}
                              {"                  "}
                              -u
                              MhdpG70XMYZmQ34zGppGSmUFTsydF0NgEA6ArTLEsQFM1lpe:VERYSECRET
                              \{"\n"}
                              {"                  "}
                              -H "Content-Type:
                              application/x-www-form-urlencoded" \
                              -d"grant_type=authorization_code&amp;redirect_uri=https://saas-apigee-internal-cymbalbank.apigee.io/oauth_redirect&amp;code=0Mk7vCZB"
                            </code>
                          </div>

                          <p style={{ paddingTop: "10px" }}>
                            You should receive a response containing an access
                            token like this (extra fields omitted):
                          </p>

                          <code>
                            {" "}
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                            {"{"}
                            {"\n"}
                            {"                  "}
                            ...{"\n"}
                            {"                  "}
                            "access_token": "QLwGBr3Wcg5KlInfDDFre1jKVKJq",
                            {"\n"}
                            {"                  "}
                            ...{"\n"}
                            {"                "}
                            {"}"}
                          </code>

                          <p>
                            Copy the value of <code>access_token</code>.
                          </p>
                        </li>
                        <li style={{ paddingBottom: "15px", fontSize: "18px" }}>
                          <h4
                            style={{
                              padding: "10px 0",
                            }}
                          >
                            Call the APIs using the token from the previous
                            step:
                          </h4>
                          <div
                            style={{
                              padding: "8px 8px",
                              borderStyle: "solid",
                              borderRadius: "10px",
                              borderColor: "#49a3f1",
                            }}
                          >
                            <p>
                              <code>
                                curl https://
                                {"{"}APIGEE_HOST
                                {"}"}
                                /v1/boa-balancereader/balances/1011226111
                                <br />
                                -H "Authorization: Bearer {"{"}
                                ACCESS_TOKEN
                                {"}"}"
                              </code>
                            </p>
                            <p>
                              <code>
                                curl https://
                                {"{"}APIGEE_HOST
                                {"}"}
                                /v1/boa-transactionhistory/transactions/1011226111
                                <br />
                                -H "Authorization: Bearer {"{"}
                                ACCESS_TOKEN
                                {"}"}"
                              </code>
                            </p>
                            <p>
                              <code>
                                curl https://
                                {"{"}APIGEE_HOST
                                {"}"}
                                /v1/boa-contacts/contacts/testuser
                                <br />
                                -H "Authorization: Bearer {"{"}
                                ACCESS_TOKEN
                                {"}"}"
                              </code>
                            </p>
                          </div>
                        </li>
                      </ol>
                      <ol>
                        <h4
                          style={{
                            padding: "10px 0",
                            color: "#1A73E8",
                          }}
                          id="testing-from-the-developer-portal"
                        >
                          <u>Testing from the Developer Portal</u>
                        </h4>
                        <p style={{ paddingBottom: "15px", fontSize: "18px" }}>
                          You can test API Proxy using the "Try this API"
                          feature in the developer portal.
                        </p>
                        <p style={{ paddingBottom: "15px", fontSize: "18px" }}>
                          <strong>NOTE</strong>: In order to test the
                          authorization flow from the Developer Portal, you must
                          register an app with a "Callback URL" that matches
                          this URI format:
                          <code>
                            https://{"{"}
                            PORTAL_DOMAIN
                            {"}"}
                            /oauth_redirect
                          </code>
                        </p>
                        <p style={{ paddingBottom: "15px", fontSize: "18px" }}>
                          Apigee Integrated Portal domains are created using the
                          convention
                          <code>
                            {"{"}PROJECT_ID
                            {"}"}-{"{"}
                            PORTAL_NAME{"}"}
                            .apigee.io
                          </code>
                          , therefore an example app callback URL would look
                          like this:
                          <code>
                            https://drush-argolis-1-bankofanthos.apigee.io/oauth_redirect
                          </code>
                        </p>
                        <h4
                          style={{
                            padding: "10px 0",
                            color: "#1A73E8",
                          }}
                        >
                          <u>
                            Register an app with the API products you wish to
                            grant, plus the Auth-code-product-v1 product.
                          </u>
                        </h4>
                        <ol>
                          <li
                            style={{ paddingBottom: "15px", fontSize: "18px" }}
                          >
                            <p>
                              Navigate to the API endpoint you wish to test in
                              the dev portal, and select the "AUTHORIZE" button
                              at the top right of the screen. An authorization
                              popup will appear.
                            </p>
                          </li>
                          <li
                            style={{ paddingBottom: "15px", fontSize: "18px" }}
                          >
                            <p>
                              Enter the key and secret for the registered App,
                              and select the "AUTHORIZE" button.
                            </p>
                          </li>
                          <li
                            style={{ paddingBottom: "15px", fontSize: "18px" }}
                          >
                            <p>
                              You should see an "Access token acquired" message.
                              Click anywhere to close the authorization popup.
                            </p>
                          </li>
                          <li
                            style={{ paddingBottom: "15px", fontSize: "18px" }}
                          >
                            <p>
                              Enter the required inputs in the "Try this API"
                              panel and select "EXECUTE". You should see the API
                              response appear below.
                            </p>
                          </li>
                        </ol>
                      </ol>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default ApigeeInstructions;
