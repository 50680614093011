/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// MUI components
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import DeleteModal from "../../../components/MDModal";
import { Link as RouterLink } from "react-router-dom";

// Images
import team6 from "../../../assets/images/team-6.png";

import React, { useState, useEffect } from "react";
import { getAllAdmin } from "../../../apis/admin";
import { useMaterialUIController } from "../../../context";
import { deleteAdmin } from "../../../apis/admin";
import { selectVendor, logout } from "../../../redux/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function data(deleteStatusForAdmin, checkIsDataPresent) {
	const vendor = useSelector(selectVendor);
	const [deleteemail, setDeleteEmail] = useState();
	const [open, setOpen] = useState(false);
	const [admin, setAdmins] = useState([]);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const mydispatch = useDispatch();

	const handleConfirmDelete = async (email) => {
		// console.log("confirmed delete", email);
		setOpen(false);
		try {
			let res = await deleteAdmin({ email });
			if (res.status === 200) {
				deleteStatusForAdmin("deleted");
			}
		} catch (error) {
			console.log("error in delete admin", error);
			deleteStatusForAdmin("notDeleted");
			if (error.response.status === 403) {
				console.log("Unauthorised: Logging Out");
				mydispatch(logout());
				localStorage.removeItem("persist:root");
				localStorage.clear();
				window.location.href = "/sign-in";
			}
		}
		// deleteAdmin({ email })
		//   .then((result) => {
		//     if (result.status === 200) {
		//       console.log("Deleted Successfully");
		//       window.location.href = "/sign-in";;
		//     } else {
		//     }
		//   })
		//   .catch((error) => {
		//     console.log(error);
		//   });
	};

	useEffect(() => {
		getAllAdmin()
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					setAdmins(response.data.vendors);
				}
			})
			.catch((error) => {
				// alert(
				//   `Oops, an error occured while loading the page. Please try again after some time.`
				// );
				checkIsDataPresent("false");
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					mydispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			});
	}, []);

	const Author = ({ image, name, email }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDAvatar src={image} name={name} size="md" />
			<MDBox ml={2} lineHeight={1}>
				<MDTypography
					display="block"
					variant="button"
					fontWeight="medium"
				>
					{name}
				</MDTypography>
				{/* <MDTypography variant="caption">{email}</MDTypography> */}
			</MDBox>
		</MDBox>
	);

	const Job = ({ title, description }) => (
		<MDBox lineHeight={1} textAlign="left">
			<MDTypography
				display="block"
				variant="caption"
				color="text"
				fontWeight="medium"
			>
				{title}
			</MDTypography>
			<MDTypography variant="caption">{description}</MDTypography>
		</MDBox>
	);

	return {
		columns: [
			{
				Header: "Admin Name",
				accessor: "author",
				width: "45%",
				align: "left",
			},
			{ Header: "User Type", accessor: "function", align: "left" },
			{ Header: "Email", accessor: "status", align: "center" },
			{ Header: "Update Admin", accessor: "employed", align: "center" },
			{ Header: "Delete Admin", accessor: "action", align: "center" },
		],

		rows: admin.map((admin) => ({
			author: <Author image={team6} name={admin.name} />,
			function: (
				<Job
					title={admin.isAdmin ? "Admin User" : "Normal User"}
					description=""
				/>
			),
			status: (
				<Link>
					<MDTypography
						variant="button"
						fontWeight="regular"
						color={darkMode ? "white" : "dark"}
					>
						{admin.email}
					</MDTypography>
				</Link>
			),
			employed: (
				<MDButton
					component={RouterLink}
					to="/edit-admin"
					state={{ admin }}
					variant="text"
					disabled={!vendor.isAdmin}
					color={darkMode ? "white" : "dark"}
				>
					<Icon>edit</Icon>&nbsp;edit
				</MDButton>
			),
			action: (
				<MDBox mr={1}>
					<MDButton
						variant="text"
						color="error"
						disabled={!vendor.isAdmin}
						onClick={() => {
							setOpen(true);
							setDeleteEmail(admin.email);
						}}
					>
						<Icon>delete</Icon>&nbsp;delete
					</MDButton>
					<DeleteModal
						deleting="admin"
						open={open}
						onClose={() => setOpen(false)}
						onDelete={() => handleConfirmDelete(deleteemail)}
					/>
				</MDBox>
			),
		})),
	};
}
