import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDAvatar from "../../../../components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "../../../../assets/theme/base/breakpoints";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
// Images

import backgroundImage from "../../../../assets/images/bg-profile.jpeg";

import { getDefaultVendor } from "../../../../apis/admin";
import { updateDefaultVendor } from "../../../../apis/admin";
import { useDispatch, useSelector } from "react-redux";
import {
	selectVendor,
	setDataStudioUrl,
	logout,
} from "../../../../redux/userSlice";
import { useForm } from "react-hook-form";

//regex code
import { RegexCode } from "../../../../config/validationRegex";
import MDSnackbar from "../../../../components/MDSnackbar";
import getDateForSnackbar from "../../../../helperFunctions/getDate";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function Header() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [vendorName, setVendorName] = useState("");
	const [vendorOrgname, setVendorOrgname] = useState("");
	const [vendorEmail, setVendorEmail] = useState("");
	const [vendorPhno, setVendorPhno] = useState("");
	const [vendorLogo, setVendorLogo] = useState("");
	const [vendorFreemiumDomain, setVendorFreemiumDomain] = useState("");
	const [vendorEnterpriseDomain, setVendorEnterpriseDomain] = useState("");
	const [landingpageUrl, setLandingPageUrl] = useState("");
	const [costpageUrl, setcostPageUrl] = useState("");
	const [usagepageUrl, setusagePageUrl] = useState("");

	const [freemiumTrigger, setfreemiumTrigger] = useState("");
	const [freemiumProjectloc, setfreemiumProjectloc] = useState("");
	const [freemiumReponame, setfreemiumReponame] = useState("");
	const [freemiumBranchname, setfreemiumBranchname] = useState("");

	const [enterpriseTrigger, setenterpriseTrigger] = useState("");
	const [enterpriseProjectloc, setenterpriseProjectloc] = useState("");
	const [enterpriseReponame, setenterpriseReponame] = useState("");
	const [enterpriseBranchname, setenterpriseBranchname] = useState("");

	const [freemiumDeleteTrigger, setfreemiumDeleteTrigger] = useState("");
	const [freemiumDeleteProjectloc, setfreemiumDeleteProjectloc] =
		useState("");
	const [freemiumDeleteReponame, setfreemiumDeleteReponame] = useState("");
	const [freemiumDeleteBranchname, setfreemiumDeleteBranchname] =
		useState("");

	const [enterpriseDeleteTrigger, setenterpriseDeleteTrigger] = useState("");
	const [enterpriseDeleteProjectloc, setenterpriseDeleteProjectloc] =
		useState("");
	const [enterpriseDeleteReponame, setenterpriseDeleteReponame] =
		useState("");
	const [enterpriseDeleteBranchname, setenterpriseDeleteBranchname] =
		useState("");

	const [errorSB, setErrorSB] = useState(false);
	const closeErrorSB = () => setErrorSB(false);

	// const getDate = () => {
	//   var d = new Date(); // for now
	//   d.getHours(); // => 9
	//   d.getMinutes(); // =>  30
	//   return d.getHours + ":" + d.getMinutes;
	// };

	const user = useSelector(selectUser);
	const dispatch = useDispatch();

	const vendor = useSelector(selectVendor);

	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [tabValue, setTabValue] = useState(0);
	useEffect(() => {
		getDefaultVendor()
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data.vendors[0]);
					setVendorName(response.data.vendors[0].defaultVendorName);
					setVendorEmail(response.data.vendors[0].defaultVendorEmail);
					setVendorOrgname(response.data.vendors[0].orgName);
					setVendorPhno(response.data.vendors[0].phoneNo);
					setVendorLogo(response.data.vendors[0].logoUrl);
					setVendorFreemiumDomain(
						response.data.vendors[0].freemiumSubdomain
					);
					setVendorEnterpriseDomain(
						response.data.vendors[0].enterpriseSubdomain
					);
					setLandingPageUrl(
						response.data.vendors[0].landingPageDataStudioUrl
					);
					setcostPageUrl(response.data.vendors[0].costDataStudioUrl);
					setusagePageUrl(
						response.data.vendors[0].usageDataStudioUrl
					);

					setfreemiumTrigger(
						response.data.vendors[0].freemiumCloudBuildTrigger
					);
					setfreemiumProjectloc(
						response.data.vendors[0]
							.freemiumCloudBuildProjectLocation
					);
					setfreemiumReponame(
						response.data.vendors[0].freemiumCloudBuildRepoName
					);
					setfreemiumBranchname(
						response.data.vendors[0].freemiumCloudBuildBranchName
					);

					setfreemiumDeleteTrigger(
						response.data.vendors[0].deleteFreemiumCloudBuildTrigger
					);
					setfreemiumDeleteProjectloc(
						response.data.vendors[0]
							.deleteFreemiumCloudBuildProjectLocation
					);
					setfreemiumDeleteReponame(
						response.data.vendors[0]
							.deleteFreemiumCloudBuildRepoName
					);
					setfreemiumDeleteBranchname(
						response.data.vendors[0]
							.deleteFreemiumCloudBuildBranchName
					);

					setenterpriseTrigger(
						response.data.vendors[0].enterpriseCloudBuildTrigger
					);
					setenterpriseProjectloc(
						response.data.vendors[0]
							.enterpriseCloudBuildProjectLocation
					);
					setenterpriseReponame(
						response.data.vendors[0].enterpriseCloudBuildRepoName
					);
					setenterpriseBranchname(
						response.data.vendors[0].enterpriseCloudBuildBranchName
					);

					setenterpriseDeleteTrigger(
						response.data.vendors[0]
							.deleteEnterpriseCloudBuildTrigger
					);
					setenterpriseDeleteProjectloc(
						response.data.vendors[0]
							.deleteEnterpriseCloudBuildProjectLocation
					);
					setenterpriseDeleteReponame(
						response.data.vendors[0]
							.deleteEnterpriseCloudBuildRepoName
					);
					setenterpriseDeleteBranchname(
						response.data.vendors[0]
							.deleteEnterpriseCloudBuildBranchName
					);
				}
			})
			.catch((error) => {
				// alert(
				//   `Oops, an error occured while loading the page. Please try again after some time. ${error}`
				// );
				setErrorSB(true);
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";;
				}
			});
	}, []);

	useEffect(() => {
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		/** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
		window.addEventListener("resize", handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () =>
			window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => setTabValue(newValue);

	return (
		<>
			<MDSnackbar
				color="error"
				icon="warning"
				title="SaaS Admin Portal"
				content={"Error while loading the page"}
				dateTime={`at ${getDateForSnackbar()}`}
				open={errorSB}
				onClose={closeErrorSB}
				close={closeErrorSB}

				// bgWhite
			/>
			<MDBox position="relative" mb={5}>
				<MDBox
					display="flex"
					alignItems="center"
					position="relative"
					minHeight="18.75rem"
					borderRadius="xl"
					sx={{
						backgroundImage: ({
							functions: { rgba, linearGradient },
							palette: { gradients },
						}) =>
							`${linearGradient(
								rgba(gradients.info.main, 0.6),
								rgba(gradients.info.state, 0.6)
							)}, url(${backgroundImage})`,
						backgroundSize: "cover",
						backgroundPosition: "50%",
						overflow: "hidden",
					}}
				/>
				<Card
					sx={{
						position: "relative",
						mt: -8,
						mx: 3,
						py: 2,
						px: 2,
					}}
				>
					<Grid container spacing={3} alignItems="center">
						<Grid item>
							<MDAvatar
								src={burceMars}
								alt="profile-image"
								size="xl"
								shadow="sm"
							/>
						</Grid>
						<Grid item>
							<MDBox height="100%" mt={0.5} lineHeight={1}>
								<MDTypography variant="h5" fontWeight="medium">
									Richard Davis
								</MDTypography>
								<MDTypography
									variant="button"
									color="text"
									fontWeight="regular"
								>
									CEO / Co-Founder
								</MDTypography>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
							<AppBar position="static">
								<Tabs
									orientation={tabsOrientation}
									value={tabValue}
									onChange={handleSetTabValue}
								>
									<Tab
										label="App"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												home
											</Icon>
										}
									/>
									<Tab
										label="Message"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												email
											</Icon>
										}
									/>
									<Tab
										label="Freemium Settings"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												settings
											</Icon>
										}
									/>
									<Tab
										label="Enterprise Settings"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												settings
											</Icon>
										}
									/>
								</Tabs>
							</AppBar>
						</Grid>
					</Grid>
					<TabPanel key={tabValue} value={tabValue} index={0}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox
								component="form"
								role="form"
								onSubmit={handleSubmit(onSubmit)}
							>
								<Grid container spacing={2}>
									<Grid item md={5} lg={7}>
										{/* <MDBox mb={2}> */}
										<TextField
											type="text"
											label="Name"
											variant="standard"
											fullWidth
											value={vendorName}
											onChange={(e) =>
												setVendorName(e.target.value)
											}
											{...register("vendorName", {
												required: true,
												pattern: RegexCode.orgNameRegex,
											})}
											error={Boolean(errors.vendorName)}
											helperText={
												errors.vendorName
													? "Invalid vendor name"
													: ""
											}
										/>

										{/* </MDBox> */}
									</Grid>
									<Grid item md={5} lg={5}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Org Name"
												variant="standard"
												fullWidth
												id="accessValue"
												value={vendorOrgname}
												onChange={(e) =>
													setVendorOrgname(
														e.target.value
													)
												}
												{...register("orgName", {
													required: true,
													// pattern: RegexCode.orgNameRegex,
												})}
												error={Boolean(errors.orgName)}
												helperText={
													errors.orgName
														? "Invalid organization name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={7}>
										<MDBox mb={2}>
											<MDInput
												type="email"
												label="Email"
												variant="standard"
												fullWidth
												id="vendorEmail"
												value={vendorEmail}
												onChange={(e) =>
													setVendorEmail(
														e.target.value
													)
												}
												{...register("vendorEmail", {
													required: true,
													pattern: RegexCode.email,
												})}
												error={Boolean(
													errors.vendorEmail
												)}
												helperText={
													errors.vendorEmail
														? "Invalid vendor email"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={5}>
										<MDBox mb={2}>
											<MDInput
												type="number"
												label="Phone Number"
												variant="standard"
												fullWidth
												id="vendorPhoneNumber"
												value={vendorPhno}
												onChange={(e) =>
													setVendorPhno(
														e.target.value
													)
												}
												{...register(
													"vendorPhoneNumber",
													{
														required: true,
														maxLength: 10,
														// pattern:numbersRegex,
													}
												)}
												error={Boolean(
													errors.vendorPhoneNumber
												)}
												helperText={
													errors.vendorPhoneNumber
														? "Invalid phone number"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={10}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Organisation Logo URL"
												variant="standard"
												fullWidth
												value={vendorLogo}
												onChange={(e) =>
													setVendorLogo(
														e.target.value
													)
												}
												{...register("orgLogoUrl", {
													required: true,
													pattern: RegexCode.orgLogo,
												})}
												error={Boolean(
													errors.orgLogoUrl
												)}
												helperText={
													errors.orgLogoUrl
														? "Invalid logo URL"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={10}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Freemium Sub-Domain"
												variant="standard"
												fullWidth
												value={vendorFreemiumDomain}
												onChange={(e) =>
													setVendorFreemiumDomain(
														e.target.value
													)
												}
												{...register(
													"vendorFreemiumSubDomain",
													{
														required: true,
														// pattern: domainRegex,
													}
												)}
												error={Boolean(
													errors.vendorFreemiumSubDomain
												)}
												helperText={
													errors.vendorFreemiumSubDomain
														? "Invalid freemium sub-domain"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={10}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Sub-Domain"
												variant="standard"
												fullWidth
												value={vendorEnterpriseDomain}
												onChange={(e) =>
													setVendorEnterpriseDomain(
														e.target.value
													)
												}
												{...register(
													"vendorEnterpriseSubDomain",
													{
														required: true,
														// pattern:,
													}
												)}
												error={Boolean(
													errors.vendorEnterpriseSubDomain
												)}
												helperText={
													errors.vendorEnterpriseSubDomain
														? "Invalid enterprise sub-domain"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									{/* </Grid> */}
									{/* <Grid container spacing={3}> */}
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox component="form" role="form">
								<Grid container spacing={3}>
									<Grid item md={4} lg={10}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Landing Page Loocker Studio URL"
												variant="standard"
												fullWidth
												value={landingpageUrl}
												onChange={(e) =>
													setLandingPageUrl(
														e.target.value
													)
												}
												{...register("landingPageUrl", {
													required: true,
													pattern: RegexCode.orgLogo,
												})}
												error={Boolean(
													errors.landingPageUrl
												)}
												helperText={
													errors.landingPageUrl
														? "Invalid photo URL"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={10}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Cost Looker Studion URL"
												variant="standard"
												fullWidth
												value={costpageUrl}
												onChange={(e) =>
													setcostPageUrl(
														e.target.value
													)
												}
												{...register("costPageUrl", {
													required: true,
													pattern: RegexCode.orgLogo,
												})}
												error={Boolean(
													errors.costPageUrl
												)}
												helperText={
													errors.costPageUrl
														? "Invalid photo URL"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={10}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Usage Looker Studio URL"
												variant="standard"
												fullWidth
												value={usagepageUrl}
												onChange={(e) =>
													setusagePageUrl(
														e.target.value
													)
												}
												{...register("usagePageUrl", {
													required: true,
													pattern: RegexCode.orgLogo,
												})}
												error={Boolean(
													errors.usagePageUrl
												)}
												helperText={
													errors.usagePageUrl
														? "Invalid photo URL"
														: ""
												}
											/>
										</MDBox>
									</Grid>
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox component="form" role="form">
								<MDTypography
									variant="h3"
									mb={4}
									fontWeight="medium"
								>
									Freemium Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Freemium Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={freemiumTrigger}
												onChange={(e) =>
													setfreemiumTrigger(
														e.target.value
													)
												}
												{...register(
													"freemiumTrigger",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumTrigger
												)}
												helperText={
													errors.freemiumTrigger
														? "Invalid cloud build trigger"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Freemium Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={freemiumProjectloc}
												onChange={(e) =>
													setfreemiumProjectloc(
														e.target.value
													)
												}
												{...register(
													"freemiumProjectLoc",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumProjectLoc
												)}
												helperText={
													errors.freemiumProjectLoc
														? "Invalid location"
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Freemium Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={freemiumReponame}
												onChange={(e) =>
													setfreemiumReponame(
														e.target.value
													)
												}
												{...register(
													"freemiumRepoName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumRepoName
												)}
												helperText={
													errors.freemiumRepoName
														? "Invalid repository name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Freemium Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={freemiumBranchname}
												onChange={(e) =>
													setfreemiumBranchname(
														e.target.value
													)
												}
												{...register(
													"freemiumBranchName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumBranchName
												)}
												helperText={
													errors.freemiumBranchName
														? "Invalid branch name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
								</Grid>
								<MDTypography
									variant="h3"
									mb={4}
									mt={4}
									fontWeight="medium"
								>
									Delete Freemium Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={freemiumDeleteTrigger}
												onChange={(e) =>
													setfreemiumDeleteTrigger(
														e.target.value
													)
												}
												{...register(
													"freemiumDeleteTrigger",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumDeleteTrigger
												)}
												helperText={
													errors.freemiumDeleteTrigger
														? "Invalid build trigger"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={freemiumDeleteProjectloc}
												onChange={(e) =>
													setfreemiumDeleteProjectloc(
														e.target.value
													)
												}
												{...register(
													"freemiumDeleteProjectLoc",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumDeleteProjectLoc
												)}
												helperText={
													errors.freemiumDeleteProjectLoc
														? "Invalid project location"
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={freemiumDeleteReponame}
												onChange={(e) =>
													setfreemiumDeleteReponame(
														e.target.value
													)
												}
												{...register(
													"freemiumDeleteRepoName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumDeleteRepoName
												)}
												helperText={
													errors.freemiumDeleteRepoName
														? "Invalid repository name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={freemiumDeleteBranchname}
												onChange={(e) =>
													setfreemiumDeleteBranchname(
														e.target.value
													)
												}
												{...register(
													"freemiumDeleteBranchName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.freemiumDeleteBranchName
												)}
												helperText={
													errors.freemiumDeleteBranchName
														? "Invalid branch name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
					<TabPanel value={tabValue} index={3}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox component="form" role="form">
								<MDTypography
									variant="h3"
									mb={4}
									fontWeight="medium"
								>
									Enterprise Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={enterpriseTrigger}
												onChange={(e) =>
													setenterpriseTrigger(
														e.target.value
													)
												}
												{...register(
													"enterpriseTrigger",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseTrigger
												)}
												helperText={
													errors.enterpriseTrigger
														? "Invalid build trigger"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={enterpriseProjectloc}
												onChange={(e) =>
													setenterpriseProjectloc(
														e.target.value
													)
												}
												{...register(
													"enterpriseProjectLoc",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseProjectLoc
												)}
												helperText={
													errors.enterpriseProjectLoc
														? "Invalid location"
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={enterpriseReponame}
												onChange={(e) =>
													setenterpriseReponame(
														e.target.value
													)
												}
												{...register(
													"enterpriseRepoName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseRepoName
												)}
												helperText={
													errors.enterpriseRepoName
														? "Invalid repository name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={enterpriseBranchname}
												onChange={(e) =>
													setenterpriseBranchname(
														e.target.value
													)
												}
												{...register(
													"enterpriseBranchName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseBranchName
												)}
												helperText={
													errors.enterpriseBranchName
														? "Invalid branch name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
								</Grid>
								<MDTypography
									variant="h3"
									mb={4}
									mt={4}
									fontWeight="medium"
								>
									Delete Enterprise Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={enterpriseDeleteTrigger}
												onChange={(e) =>
													setenterpriseDeleteTrigger(
														e.target.value
													)
												}
												{...register(
													"enterpriseDeleteTrigger",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseDeleteTrigger
												)}
												helperText={
													errors.enterpriseDeleteTrigger
														? "Invalid trigger"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={
													enterpriseDeleteProjectloc
												}
												onChange={(e) =>
													setenterpriseDeleteProjectloc(
														e.target.value
													)
												}
												{...register(
													"enterpriseDeleteProjectLoc",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseDeleteProjectLoc
												)}
												helperText={
													errors.enterpriseDeleteProjectLoc
														? "Invalid location"
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={enterpriseDeleteReponame}
												onChange={(e) =>
													setenterpriseDeleteReponame(
														e.target.value
													)
												}
												{...register(
													"enterpriseDeleteRepoName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseDeleteRepoName
												)}
												helperText={
													errors.enterpriseDeleteRepoName
														? "Invalid repository name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={
													enterpriseDeleteBranchname
												}
												onChange={(e) =>
													setenterpriseDeleteBranchname(
														e.target.value
													)
												}
												{...register(
													"enterpriseDeleteBranchName",
													{
														required: true,
														// pattern: gcpRegex,
													}
												)}
												error={Boolean(
													errors.enterpriseDeleteBranchName
												)}
												helperText={
													errors.enterpriseDeleteBranchName
														? "Invalid branch name"
														: ""
												}
											/>
										</MDBox>
									</Grid>
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
				</Card>
			</MDBox>
		</>
	);
}

// Setting default props for the Header
Header.defaultProps = {
	children: "",
};

// Typechecking props for the Header
Header.propTypes = {
	children: PropTypes.node,
};

export default Header;
