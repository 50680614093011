import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { Grid, InputLabel, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { addAdmin } from "../functions/adminFormFunctions";
import { RegexCode } from "../../config/validationRegex";
import MDSnackbar from "../../components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectTenant, logout } from "../../redux/userSlice";
import MDDrop from "../../components/MDDropdown";
import MDSelect from "../../components/MDSelect";
import { addTenantUsers } from "../../apis/tenant";

function AddTenantUser() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onChange" });

	const [access, setAccess] = useState(false);
	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const tenant = useSelector(selectTenant);

	const handleChange = (event) => {
		// console.log(event.target.value);
		setAccess(event.target.value);
	};

	const closeSuccessSB = () => {
		setSuccessSB(false);
		navigate("/tenant-users");
	};
	const closeErrorSB = () => setErrorSB(false);

	const submitStatus = ["IN_PROGRESS", "ERROR", "COMPLETED", "FORBIDDEN"];

	const onSubmit = async (data) => {
		try {
		let subdomain = data.domain.indexOf(".");
		subdomain = data.domain.slice(0, subdomain);
		data.subdomain = subdomain;
		data.isAdmin = access;
		// console.log(data);
		const res = await addTenantUsers(data);
		// console.log(res);
		if (res.status === 200) {
			// console.log("done");
			setSuccessSB(true);
			navigate("/tenant-users");
		} else if (res.status === 500) {
			setErrorSB(true);
			if (res.status === 403) {
				console.log("Unauthorised: Logging Out");
				dispatch(logout());
				localStorage.removeItem("persist:root");
				localStorage.clear();
				window.location.href = "/sign-in";
			}
		}else{

			setErrorMsg(res.response.data.message);
			setErrorSB(true);
		}
	} catch (error) {
		console.log("Error:", error.response.data);
		// Handle the error condition
		setErrorMsg(error.response.data.message);
		setErrorSB(true);
	  }

	};

	return (
		<>
			{successSB ? (
				<MDSnackbar
					color="success"
					icon="check"
					title="SaaS Admin Portal"
					content={`New ${
						access === true ? `admin` : `normal`
					} user added`}
					// dateTime="11 mins ago"
					open={successSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}

					// bgWhite
				/>
			) : (
				<MDSnackbar
					color="error"
					icon="warning"
					title="SaaS Admin Portal"
					content={errorMsg==''
					? `An error occured while adding ${
						access === true ? `admin` : `normal`
					} user` : `${errorMsg}` }
					// dateTime="11 mins ago"
					open={errorSB}
					onClose={closeErrorSB}
					close={closeErrorSB}

					// bgWhite
				/>
			)}

			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox
						variant="gradient"
						bgColor="info"
						borderRadius="lg"
						coloredShadow="success"
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign="center"
					>
						<MDTypography
							variant="h4"
							fontWeight="medium"
							color="white"
							mt={1}
						>
							Add New User
						</MDTypography>
						<MDTypography
							display="block"
							variant="button"
							color="white"
							my={1}
						>
							Enter the following information to register a New
							Tenant User
						</MDTypography>
					</MDBox>
					<MDBox pt={4} pb={3} px={3}>
						<MDBox
							component="form"
							role="form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Grid container spacing={2}>
								<Grid item md={12} lg={12}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Name"
											variant="standard"
											fullWidth
											id="vendorName"
											{...register("name", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: RegexCode.orgName,
													message:
														"Special Characters are not allowed",
												},
												maxLength: {
													value: 20,
													message:
														"Maximum 20 allowed characters",
												},
											})}
											error={Boolean(errors.name)}
											helperText={
												errors.name
													? errors.name.message
													: ""
											}
										/>
									</MDBox>
								</Grid>

								<Grid item md={12} lg={12}>
									<MDBox mb={2}>
										<MDInput
											type="email"
											label="E-mail"
											variant="standard"
											fullWidth
											id="tenantUserEmail"
											{...register("email", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: RegexCode.email,
													message:
														"Enter a valid email",
												},
											})}
											error={Boolean(errors.email)}
											helperText={
												errors.email
													? errors.email.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
								<Grid item md={12} lg={12}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Domain"
											variant="standard"
											fullWidth
											id="domainName"
											InputProps={{
												readOnly: true,
											}}
											value={tenant.domain}
											{...register("domain", {
												required: {
													value: true,
													message: "Required",
												},
											})}
											error={Boolean(errors.domain)}
											helperText={
												errors.email
													? errors.email.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
								<Grid item md={12} lg={12}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Organization Name"
											variant="standard"
											fullWidth
											id="orgName"
											{...register("orgName", {
												required: {
													value: true,
													message: "Required",
												},
											})}
											error={Boolean(errors.orgName)}
											helperText={
												errors.orgName
													? errors.orgName.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
								{/* <Grid item md={12} lg={12}>
                  <MDDrop
                    label="Name"
                    variant="standard"
                    fullWidth
                    id="tenantId"
                    sx={{ m: 1.1, minWidth: 120 }}
                  >
                    <InputLabel>Access</InputLabel>
                    <MDSelect
                      variant="standard"
                      fullWidth
                      id="adminAccess"
                      value={access}
                      label="Access"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Admin User</MenuItem>
                      <MenuItem value={false}>Normal User</MenuItem>
                    </MDSelect>
                  </MDDrop>
                </Grid> */}
							</Grid>

							<MDBox mt={4} mb={1}>
								<MDButton
									variant="gradient"
									color="info"
									fullWidth
									onClick={handleSubmit(onSubmit)}
								>
									Add User
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
}

export default AddTenantUser;
