import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";

import Breadcrumbs from "../../../examples/Breadcrumbs";
import NotificationItem from "../../../examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarIconButton,
	navbarMobileMenu,
} from "../../../examples/Navbars/DashboardNavbar/styles";

import {
	useMaterialUIController,
	setTransparentNavbar,
	setMiniSidenav,
	setOpenConfigurator,
} from "../../../context";

import { useDispatch } from "react-redux";
import { selectUser } from "../../../redux/userSlice";
import { useSelector } from "react-redux";
import { logout, selectTenant } from "../../../redux/userSlice";
import axios from "axios";

//Collapse Apis
import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

function DashboardNavbar({ absolute, light, isMini }) {
	const user = useSelector(selectUser);
	const tenant = useSelector(selectTenant);
	const [navbarType, setNavbarType] = useState();
	const navigate = useNavigate();
	const [controller, dispatch] = useMaterialUIController();
	const {
		miniSidenav,
		transparentNavbar,
		fixedNavbar,
		openConfigurator,
		darkMode,
	} = controller;
	const [openMenu, setOpenMenu] = useState(false);
	const route = useLocation().pathname.split("/").slice(1);
	const [state, setState] = useState(true);
	const mydispatch = useDispatch();

	//openSetting
	const [openSetting, setOpenSetting] = React.useState(true);
	const handleSettingClick = () => {
		setOpenSetting(!openSetting);
	};

	useEffect(() => {
		// Setting the navbar type
		if (fixedNavbar) {
			setNavbarType("sticky");
		} else {
			setNavbarType("static");
		}

		// A function that sets the transparent state of the navbar.
		function handleTransparentNavbar() {
			setTransparentNavbar(
				dispatch,
				(fixedNavbar && window.scrollY === 0) || !fixedNavbar
			);
		}

		/** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
		window.addEventListener("scroll", handleTransparentNavbar);

		// Call the handleTransparentNavbar function to set the state with the initial value.
		handleTransparentNavbar();

		// Remove event listener on cleanup
		return () =>
			window.removeEventListener("scroll", handleTransparentNavbar);
	}, [dispatch, fixedNavbar]);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () =>
		setOpenConfigurator(dispatch, !openConfigurator);
	const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);
	const logoutUser = (e) => {
		localStorage.removeItem("persist:root");
		axios
			.post("/api/users/signout", {}, { withCredentials: true })
			.then(async (response) => {
				if (response.status === 200) {
					// console.log("success");
					// console.log(response);
					// auth.signOut().catch((error) => {
					//     console.log(error);
					// });
					mydispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	// Render the notifications menu
	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={{ mt: 2 }}
		>
			<NotificationItem
				// component={Link}
				// to={"/sign-in"}
				icon={<Icon>logout</Icon>}
				title="logout"
				onClick={(e) => logoutUser(e)}
			/>

			{/* <NotificationItem
				icon={<Icon>shopping_cart</Icon>}
				title="Payment successfully completed"
			/> */}
		</Menu>
	);

	// Styles for the navbar icons
	const iconsStyle = ({
		palette: { dark, white, text },
		functions: { rgba },
	}) => ({
		color: () => {
			let colorValue = light || darkMode ? white.main : dark.main;

			if (transparentNavbar && !light) {
				colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
			}

			return colorValue;
		},
	});

	return (
		<AppBar
			position={absolute ? "absolute" : navbarType}
			color="inherit"
			sx={(theme) =>
				navbar(theme, { transparentNavbar, absolute, light, darkMode })
			}
		>
			<Toolbar sx={(theme) => navbarContainer(theme)}>
				<MDBox
					color="inherit"
					mb={{ xs: 1, md: 0 }}
					sx={(theme) => navbarRow(theme, { isMini })}
				>
					<Breadcrumbs
						icon="home"
						title={route[route.length - 1]}
						route={route}
						light={light}
					/>
				</MDBox>
				{isMini ? null : (
					<MDBox sx={(theme) => navbarRow(theme, { isMini })}>
						{/* <MDBox pr={1}>
							<MDInput label="Search here" />
						</MDBox> */}

						<MDBox color={light ? "white" : "inherit"}>
							<IconButton
								sx={navbarIconButton}
								onClick={handleOpenMenu}
								size="large"
								disableRipple
							>
								<Icon fontSize="large" sx={iconsStyle}>
									account_circle
								</Icon>
								<MDTypography variant="h6" fontWeight="medium">
									{user.name}
								</MDTypography>
							</IconButton>

							{renderMenu()}

							<IconButton
								size="small"
								disableRipple
								color="inherit"
								sx={navbarMobileMenu}
								onClick={handleMiniSidenav}
							>
								<Icon sx={iconsStyle} fontSize="medium">
									{miniSidenav ? "menu_open" : "menu"}
								</Icon>
							</IconButton>
							{tenant?.domain && (
								<Tooltip title="Open App" placement="bottom">
									<IconButton
										size="small"
										disableRipple
										color="inherit"
										sx={navbarIconButton}
									>
										<a
											href={"https://" + tenant?.domain}
											target="_blank"
										>
											<Icon sx={iconsStyle}>
												exit_to_app
											</Icon>
										</a>
									</IconButton>
								</Tooltip>
							)}

							{/* <IconButton
								size="small"
								disableRipple
								color="inherit"
								sx={navbarIconButton}
								aria-controls="notification-menu"
								aria-haspopup="true"
								variant="contained"
								onClick={handleOpenMenu}
							>
								<Icon sx={iconsStyle}>notifications</Icon>
							</IconButton> */}
							{/* {renderMenu()} */}
						</MDBox>
					</MDBox>
				)}
			</Toolbar>
		</AppBar>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
};

export default DashboardNavbar;
