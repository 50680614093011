/** 
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Dashboard from "./layouts/dashboard";
import Tables from "./layouts/tables";
import Tenant from "./layouts/vm_tenant";
import Admin from "./layouts/admin";
import Billing from "./layouts/billing";
import Notifications from "./layouts/notifications";
import Profile from "./layouts/profile";
import SignIn from "./layouts/vm_authentication/sign-in";
import Error from "./layouts/error/404/Errors";
import TenantOnboardingStatus from "./layouts/tenantOnboarding";
import SelfOnboarding from "./forms/selfOnboarding";
import AddNewTenant from "./layouts/vm_tenant/addNewTenant";
import AddNewVmTenant from "./layouts/vm_tenant/addNewTenant";
import AddNewVmTenantForm from "./vmForms/addTenant";
import AddNewAdmin from "./layouts/admin/addNewAdmin";
import { Navigate } from "react-router-dom";
import EditTenant from "./layouts/vm_tenant/editTenant";
import EditVmTenant from "./layouts/vm_tenant/editTenant";
import EditTenantForm from "./forms/editTenant";
import EditVmTenantForm from "./vmForms/editTenant";
import EditIapProvider from "./layouts/identityProvider/editIdentityProvider";
import CostPage from "./layouts/dashboards/costPage";
import ExtraLinksPage from "./layouts/dashboards/additionalDashboardsLinkPage";
// import LogMonPage from "./layouts/dashboards/logmonPage";
import LandingPage from "./layouts/dashboards/landingPgae";
import UsagePage from "./layouts/dashboards/usagePage";
import VendorSettings from "./layouts/vm_setting";
import AddIdentityProvider from "./forms/addIdentityProviders/index";
import IdentityProviders from "./layouts/identityProvider";

// @mui icons
import Icon from "@mui/material/Icon";

import { RequireAuth, AuthExists } from "./RequireAuth";
import EditAdmin from "./layouts/admin/editAdmin";
import ResetPassword from "./layouts/authentication/reset-password";

export const vmVendorAdminRoutes = [
	{
		route: "/",
		key: "home-page",
		component: <Navigate to="/tenants" />,
		// component: <SignIn />,
	},
	{
		type: "title",
		title: "Dashboards",
	},
	{
		type: "collapse",
		name: "Cost Summary",
		key: "cost-summary",
		icon: <Icon fontSize="small">assignment</Icon>,
		route: "/landing-dashboard",
		component: (
			<RequireAuth>
				<LandingPage />
			</RequireAuth>
		),
	},
	// {
	// 	type: "collapse",
	// 	name: "Cost Breakdown and Trend",
	// 	key: "cost-dashboard",
	// 	icon: <Icon fontSize="small">equalizer</Icon>,
	// 	route: "/cost-dashboard",
	// 	component: (
	// 		<RequireAuth>
	// 			<CostPage />
	// 		</RequireAuth>
	// 	),
	// },
	// {
	// 	type: "collapse",
	// 	name: "Consumption Trend",
	// 	key: "consumption-dashboard",
	// 	icon: <Icon fontSize="small">data_usage</Icon>,
	// 	route: "/consumption-dashboard",
	// 	component: (
	// 		<RequireAuth>
	// 			<UsagePage />
	// 		</RequireAuth>
	// 	),
	// },
	{
		type: "divider",
	},
	// {
	//  type: "collapse",
	//  name: "Monitoring Dashboard",
	//  key: "monitoring-dashboard",
	//  icon: <Icon fontSize="small">assignment</Icon>,
	//  route: "/monitoring-dashboard",
	//  component: (
	//      <RequireAuth>
	//          <MonitoringPage />
	//      </RequireAuth>
	//  ),
	// }
	{
		type: "title",
		title: "User Management",
	},
	{
		type: "collapse",
		name: "Tenant Management",
		key: "tenants",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/tenants",
		component: (
			<RequireAuth>
				{" "}
				<Tenant />
			</RequireAuth>
		),
	},
	{
		route: "/new-tenant",
		key: "new-tenant",
		component: (
			<RequireAuth>
				<AddNewVmTenant/>
			</RequireAuth>
		),
	},
	{
		route: "/edit-admin",
		key: "edit-admin",
		component: (
			<RequireAuth>
				<EditAdmin />
			</RequireAuth>
		),
	},
	{
		route: "/edit-tenant",
		key: "edit-tenant",
		component: (
			<RequireAuth>
				<EditVmTenantForm />
			</RequireAuth>
		),
	},
	{
		type: "collapse",
		name: "Admin Management",
		key: "admins",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/admins",
		component: (
			<RequireAuth>
				{" "}
				<Admin />
			</RequireAuth>
		),
	},
	{
		route: "/new-admin",
		key: "new-admin",
		component: (
			<RequireAuth>
				<AddNewAdmin />
			</RequireAuth>
		),
	},
	{
		type: "divider",
	},
	{
		type: "collapse",
		name: "Quick Links",
		key: "quick-links",
		icon: <Icon fontSize="small">home</Icon>,
		route: "/quick-links",
		component: (
			<RequireAuth>
				<ExtraLinksPage />
			</RequireAuth>
		),
	},
	{
		type: "collapse",
		name: "Vendor Settings",
		key: "settings",
		icon: <Icon fontSize="small">settings</Icon>,
		route: "/settings",
		component: (
			<RequireAuth>
				{" "}
				<VendorSettings />
			</RequireAuth>
		),
	},
	{
		route: "/tenant-onboard-status",
		key: "onboard-status",
		component: (
			<RequireAuth>
				{" "}
				<TenantOnboardingStatus />
			</RequireAuth>
		),
	},
	// {
	//  type: "collapse",
	//  name: "Tables",
	//  key: "tables",
	//  icon: <Icon fontSize="small">table_view</Icon>,
	//  route: "/tables",
	//  component: <Tables />,
	// },
	// {
	//  type: "collapse",
	//  name: "Billing",
	//  key: "billing",
	//  icon: <Icon fontSize="small">receipt_long</Icon>,
	//  route: "/billing",
	//  component: <Billing />,
	// },
	// {
	//  type: "collapse",
	//  name: "RTL",
	//  key: "rtl",
	//  icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
	//  route: "/rtl",
	//  component: <RTL />,
	// },
	// {
	//   type: "collapse",
	//   name: "Notifications",
	//   key: "notifications",
	//   icon: <Icon fontSize="small">notifications</Icon>,
	//   route: "/notifications",
	//   component: <Notifications />,
	// },
	// {
	//  type: "collapse",
	//  name: "Profile",
	//  key: "profile",
	//  icon: <Icon fontSize="small">person</Icon>,
	//  route: "/profile",
	//  component: <Profile />,
	// },
	{
		icon: <Icon fontSize="small">login</Icon>,
		route: "/sign-in",
		key: "sign-in",
		// component: <SignIn />,
		component: (
			<AuthExists>
				<SignIn />
			</AuthExists>
		),
	},
	{
		icon: <Icon fontSize="small">AccessAlarm</Icon>,
		route: "/reset-password",
		key: "reset-password",
		component: <ResetPassword />,
	},
	{
		icon: <Icon fontSize="small">Self-Onboarding</Icon>,
		route: "/self-onboarding-form",
		key: "self-onboarding-form",
		component: (
			<RequireAuth>
				<SelfOnboarding />
			</RequireAuth>
		),
	},
	// {
	//   type: "collapse",
	//   name: "Set Your Identity Provider",
	//   key: "add-identity-provider",
	//   icon: <Icon fontSize="small">badge</Icon>,
	//   route: "/add-identity-provider",
	//   component: (
	//     <RequireAuth>
	//       {" "}
	//       <AddIdentityProvider />
	//     </RequireAuth>
	//   ),
	// },
	// {
	//   type: "collapse",
	//   name: "Identity Providers",
	//   key: "identity-providers",
	//   icon: <Icon fontSize="small">person</Icon>,
	//   route: "/identity-providers",
	//   component: (
	//     <RequireAuth>
	//       {" "}
	//       <IdentityProviders />
	//     </RequireAuth>
	//   ),
	// },
	{
		route: "*",
		key: "404",
		component: <Error />,
	},
];

export const vmVendorUserRoutes = [
	{
		route: "/",
		key: "home-page",
		component: (
			<RequireAuth>
				<Navigate to="/tenants" />
			</RequireAuth>
		),
	},
	{
		type: "title",
		title: "Dashboards",
	},
	{
		type: "collapse",
		name: "Cost Summary",
		key: "cost-summary",
		icon: <Icon fontSize="small">assignment</Icon>,
		route: "/landing-dashboard",
		component: (
			<RequireAuth>
				<LandingPage />
			</RequireAuth>
		),
	},
	{
		type: "collapse",
		name: "Cost Breakdown and Trend",
		key: "cost-dashboard",
		icon: <Icon fontSize="small">equalizer</Icon>,
		route: "/cost-dashboard",
		component: (
			<RequireAuth>
				<CostPage />
			</RequireAuth>
		),
	},
	{
		type: "collapse",
		name: "Consumption Trend",
		key: "consumption-dashboard",
		icon: <Icon fontSize="small">data_usage</Icon>,
		route: "/usage-dashboard",
		component: (
			<RequireAuth>
				<UsagePage />
			</RequireAuth>
		),
	},
	{
		type: "divider",
	},
	{
		type: "title",
		title: "User Management",
	},
	{
		type: "collapse",
		name: "Tenant Management",
		key: "tenants",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/tenants",
		component: (
			<RequireAuth>
				{" "}
				<Tenant />
			</RequireAuth>
		),
	},
	{
		type: "collapse",
		name: "Admin Management",
		key: "admins",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/admins",
		component: (
			<RequireAuth>
				{" "}
				<Admin />
			</RequireAuth>
		),
	},
	{
		type: "divider",
	},
	{
		type: "collapse",
		name: "Quick Links",
		key: "quick-links",
		icon: <Icon fontSize="small">home</Icon>,
		route: "/quick-links",
		component: (
			<RequireAuth>
				<ExtraLinksPage />
			</RequireAuth>
		),
	},
	{
		type: "collapse",
		name: "Vendor Settings",
		key: "settings",
		icon: <Icon fontSize="small">settings</Icon>,
		route: "/settings",
		component: (
			<RequireAuth>
				{" "}
				<VendorSettings />
			</RequireAuth>
		),
	},
	{
		route: "/tenant-onboard-status",
		key: "onboard-status",
		component: (
			<RequireAuth>
				{" "}
				<TenantOnboardingStatus />
			</RequireAuth>
		),
	},
	{
		icon: <Icon fontSize="small">login</Icon>,
		route: "/sign-in",
		key: "sign-in",
		// component: <SignIn />,
		component: (
			<AuthExists>
				<SignIn />
			</AuthExists>
		),
	},
	{
		icon: <Icon fontSize="small">AccessAlarm</Icon>,
		route: "/reset-password",
		key: "reset-password",
		component: <ResetPassword />,
	},
	{
		icon: <Icon fontSize="small">Self-Onboarding</Icon>,
		route: "/self-onboarding-form",
		key: "self-onboarding-form",
		component: (
			<RequireAuth>
				{" "}
				<SelfOnboarding />
			</RequireAuth>
		),
	},
	{
		route: "*",
		key: "404",
		component: <Error />,
	},
];
