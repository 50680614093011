// import { addVmTenant } from "../../../apis/tenant";
import { addTenant as addTenantAPI, addVmTenant } from "../../../apis/tenant";
import { updateTenant as updateTenantAPI } from "../../../apis/tenant";
import { updateVmTenant as updateVmTenantAPI } from "../../../apis/tenant";
import { addTenantUsers as addTenantUserAPI } from "../../../apis/tenant";

export const addTenant = async (data) => {
	const diskSize = data.diskSize;
	const diskType = data.diskType;
	const tenantId = data.tenantId;
	const orgName = data.orgName;
	const tier = data.tierval;
	const adminEmail = data.adminEmail;
	const orgLogo = data.orgLogo;
	const domain = data.domain;

	const port = data.portNumber || null;
	const region = data.region || null;
	const gcpProjectId = data.projectId || null;
	const bqDataSetId = data.bqDatasetId || null;
	const zone = data.zone || null;
	const emailVerified = data.emailVerified;

	const machineType = data.machineType || null;
	const minCount = parseInt(data.minCount) || null;
	const maxCount = parseInt(data.maxCount) || null;
	const subnetIp = data.subnetIp || null;
	const addDisk = data.addDisk || null; 

	const dataToSend = {
		diskSize: parseInt(diskSize),
		diskType:diskType,
		orgName,
		subdomain: tenantId,
		adminEmail,
		tier,
		logoUrl: orgLogo,
		domain,
		gcpRegion: region,
		projectId: gcpProjectId,
		bqDatasetId: bqDataSetId,
		zone,
		port: parseInt(port),
		emailVerified,
		machineType,
		minCount,
		maxCount,
		subnetIp,
		addDisk
		};

	try {		
		const res = await addVmTenant(dataToSend);
		return res;
	} catch (error) {
		console.log("error in add tenant", error);
		return error.response;
	}
};

export const updateTenant = async (data) => {
	const tenantId = data.tenantId;
	const orgName = data.orgName;
	const tier = data.tier;
	const adminEmail = data.adminEmail;
	const orgLogo = data.orgLogo;
	const domain = data.domain;
	const region = data.region || null;
	const gcpProjectId = data.gcpProjectId || null;
	const bqDataSetId = data.bqDataSetId || null;
	const machineType = data.machineType || null;
	const zone = data.zone || null;
	const minCount = parseInt(data.minCount) || null;
	const maxCount = parseInt(data.maxCount) || null;
	const nodeCount = parseInt(data.nodeCount) || null;
	const podIp = data.podIp || null;
	const masterIp = data.masterIp || null;
	const subnetIp = data.subnetIp || null;
	const svcIp = data.svcIp || null;

	const dataToSend = {
		orgName,
		subdomain: tenantId,
		adminEmail,
		tier: tier,
		logoUrl: orgLogo,
		domain,
		gcpRegion: region,
		gcpProjectId,
		bqDataSetId,
		machineType,
		zone,
		minCount,
		maxCount,
		nodeCount,
		podIp,
		masterIp,
		subnetIp,
		svcIp,
	};

	// console.log("data in edit tenant", dataToSend);

	// let isValidated = validateTenantData(dataToSend)
	try {
		const res = await updateVmTenantAPI(dataToSend);
		// console.log("res in edit tenant", res);
		return res.status;
	} catch (error) {
		console.log("error in edit tenant", error);
		return error.response.status;
	}
};

export const addTenantUser = async (data) => {
	const userName = data.userName;
	const userEmail = data.userEmail;
	const domain = data.domain;

	const dataToSend = {
		userName,
		userEmail,
		domain,
	};

	// console.log(dataToSend);

	try {
		const res = await addTenantUserAPI(dataToSend);
		return res.status;
	} catch (error) {
		console.error(error);
		return error.response.status;
	}
};
