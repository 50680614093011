import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "./redux/userSlice";

export const RequireAuth = ({ children }) => {
	const user = useSelector(selectUser);
	if (!user) {
		return <Navigate to="/sign-in" />;
	}

	return <>{children}</>;
};

export const AuthExists = ({ children }) => {
	const user = useSelector(selectUser);
	if (user) {
		return <Navigate to="/" />;
	}

	return <>{children}</>;
};
