import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "store",
    initialState: {
        user: null,
        vendor : {
            isAdmin: false
        },
        tenant: null,
       
    },
    reducers: {
        logout: (state, action) => {
            state = {
                user: null,
                vendor : null,
                tenant: null
            };
            localStorage.clear();
        },
        setDataStudioUrl: (state, action) => {
            state.vendor.landingPageDataStudioUrl = action.payload.landingPageDataStudioUrl;
            state.user.usageDataStudioUrl = action.payload.usageDataStudioUrl;
            state.user.costDataStudioUrl = action.payload.costDataStudioUrl;
        },
        setTierSubdomain: (state, action) => {
            state.vendor.freemiumSubdomain = action?.payload?.freemiumSubdomain;
            state.vendor.enterpriseSubdomain = action?.payload?.enterpriseSubdomain;
        },
        setUserData:(state,action) =>{
            state.user = action.payload
        },
        setVendorData:(state,action) =>{
            state.vendor = action.payload
        },
        setTenantData:(state,action) =>{
            state.tenant = action.payload
        }
    },
});

export const {
	login,
	logout,
	setDataStudioUrl,
	setTierSubdomain,
	setTenantData,
	setVendorData,
	setUserData,
} = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectTenant = (state) => state.user.tenant;
export const selectVendor = (state) => state.user.vendor;

export default userSlice.reducer;
