import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import EditIapDetails from "../../../forms/editIAP";

function EditIapProvider(props) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <EditIapDetails />
    </DashboardLayout>
  );
}

export default EditIapProvider;