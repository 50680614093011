import Error from "./layouts/error/404/Errors";
import LandingPage from "./layouts/dashboards/landingPgae";
import CostPage from "./layouts/dashboards/costPage";
import { Navigate } from "react-router-dom";
import { RequireAuth, AuthExists } from "./RequireAuth";
import SignIn from "./layouts/authentication/sign-in";
import UsagePage from "./layouts/dashboards/usagePage";
// @mui icons
import Icon from "@mui/material/Icon";

import AddIdentityProvider from "./forms/addIdentityProviders";
import IdentityProviders from "./layouts/identityProvider";
import EditIapProvider from "./layouts/identityProvider/editIdentityProvider";
import EditIapProviderSAML from "./layouts/identityProvider/editIdentityProvider/samlEdit";

import TenantUser from "./layouts/tenantUser";
import AddTenantUser from "./layouts/tenantUser/addTenantUser";
import ApigeeInstructions from "./layouts/apigee";

export const tenantRoutes = [
	process.env.REACT_APP_APIGEE !== "true" && {
		route: "/",
		component: <Navigate to="/tenant-users" />,
	},
	process.env.REACT_APP_APIGEE === "true" && {
		route: "/",
		component: <Navigate to="/cost-dashboard" />,
	},
	{
		icon: <Icon fontSize="small">login</Icon>,
		route: "/sign-in",
		key: "sign-in",
		// component: <SignIn />,
		component: (
			<AuthExists>
				<SignIn />
			</AuthExists>
		),
	},
	{
		type: "title",
		title: "Dashboards",
	},
	{
		type: "collapse",
		name: "Cost Breakdown and Trend",
		key: "cost-dashboard",
		icon: <Icon fontSize="small">equalizer</Icon>,
		route: "/cost-dashboard",
		component: (
			<RequireAuth>
				<CostPage />
			</RequireAuth>
		),
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		route: "/add-tenantuser",
		component: (
			<RequireAuth>
				<AddTenantUser />
			</RequireAuth>
		),
	},

	{
		type: "collapse",
		name: "Consumption Trend",
		key: "consumption-dashboard",
		icon: <Icon fontSize="small">data_usage</Icon>,
		route: "/consumption-dashboard",
		component: (
			<RequireAuth>
				<UsagePage />
			</RequireAuth>
		),
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		type: "divider",
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		type: "title",
		title: "Management",
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		type: "collapse",
		name: "User Management",
		key: "tenant-users",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/tenant-users",
		component: (
			<RequireAuth>
				<TenantUser />
			</RequireAuth>
		),
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		route: "/edit-identity-providers",
		key: "edit-identity-providers",
		component: (
			<RequireAuth>
				<EditIapProvider />
			</RequireAuth>
		),
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		route: "/edit-identity-providers-saml",
		key: "edit-identity-providers-saml",
		component: (
			<RequireAuth>
				<EditIapProviderSAML />
			</RequireAuth>
		),
	},

	// {
	//   type: "collapse",
	//   name: "Tenant Setting",
	//   key: "tenant-setting",
	//   icon: <Icon fontSize="small">settings</Icon>,
	//   route: "/tenant-setting",
	//   component: (
	//     <RequireAuth>
	//       {" "}
	//       <TenantSetting />
	//     </RequireAuth>
	//   ),
	// },
	process.env.REACT_APP_APIGEE !== "true" && {
		type: "collapse",
		name: "Identity Providers",
		key: "identity-providers",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/identity-providers",
		component: (
			<RequireAuth>
				{" "}
				<IdentityProviders />
			</RequireAuth>
		),
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		icon: <Icon fontSize="small">badge</Icon>,
		route: "/add-identity-provider",
		component: (
			<RequireAuth>
				{" "}
				<AddIdentityProvider />
			</RequireAuth>
		),
	},
	process.env.REACT_APP_APIGEE === "true" && {
		type: "divider",
	},
	process.env.REACT_APP_APIGEE === "true" && {
		type: "title",
		title: "Reference",
	},
	process.env.REACT_APP_APIGEE === "true" && {
		type: "collapse",
		name: "Apigee Instructions",
		key: "apigee-guide",
		icon: <Icon fontSize="small">info</Icon>,
		route: "/apigee-guide",
		component: (
			<RequireAuth>
				{" "}
				<ApigeeInstructions />
			</RequireAuth>
		),
	},
	{
		route: "*",
		component: <Error />,
	},
];

export const FreemiumTenantRoutes = [
	process.env.REACT_APP_APIGEE !== "true" && {
		route: "/",
		component: <Navigate to="/tenant-users" />,
	},
	process.env.REACT_APP_APIGEE === "true" && {
		route: "/",
		component: <Navigate to="/cost-summary" />,
	},
	{
		icon: <Icon fontSize="small">login</Icon>,
		route: "/sign-in",
		key: "sign-in",
		// component: <SignIn />,
		component: (
			<AuthExists>
				<SignIn />
			</AuthExists>
		),
	},
	process.env.REACT_APP_TENENT_ENV != "VM" && {
		type: "collapse",
		name: "Consumption Trend",
		key: "consumption-dashboard",
		icon: <Icon fontSize="small">data_usage</Icon>,
		route: "/consumption-dashboard",
		component: (
			<RequireAuth>
				<UsagePage />
			</RequireAuth>
		),
	},

	process.env.REACT_APP_APIGEE !== "true" && {
		route: "/add-tenantuser",
		component: (
			<RequireAuth>
				<AddTenantUser />
			</RequireAuth>
		),
	},

	process.env.REACT_APP_TENENT_ENV == "VM" &&
	{
		type: "collapse",
		name: "Cost Summary",
		key: "cost-summary",
		icon: <Icon fontSize="small">equalizer</Icon>,
		route: "/cost-summary",
		component: (
			<RequireAuth>
				<LandingPage/>
			</RequireAuth>
		),
	},

	process.env.REACT_APP_TENENT_ENV != "VM" &&{
		type: "collapse",
		name: "Cost Breakdown and Trend",
		key: "cost-dashboard",
		icon: <Icon fontSize="small">equalizer</Icon>,
		route: "/cost-dashboard",
		component: (
			<RequireAuth>
				<CostPage />
			</RequireAuth>
		),
	},
	process.env.REACT_APP_APIGEE !== "true" && {
		type: "collapse",
		name: "User Management",
		key: "tenant-users",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/tenant-users",
		component: (
			<RequireAuth>
				<TenantUser />
			</RequireAuth>
		),
	},

	// {
	//   type: "collapse",
	//   name: "Tenant Setting",
	//   key: "tenant-setting",
	//   icon: <Icon fontSize="small">settings</Icon>,
	//   route: "/tenant-setting",
	//   component: (
	//     <RequireAuth>
	//       {" "}
	//       <TenantSetting />
	//     </RequireAuth>
	//   ),
	// },
	// {
	//   type: "collapse",
	//   name: "Identity Providers",
	//   key: "identity-providers",
	//   icon: <Icon fontSize="small">person</Icon>,
	//   route: "/identity-providers",
	//   component: (
	//     <RequireAuth>
	//       {" "}
	//       <IdentityProviders />
	//     </RequireAuth>
	//   ),
	// },
	process.env.REACT_APP_APIGEE === "true" && {
		type: "collapse",
		name: "Apigee Instructions",
		key: "apigee-guide",
		icon: <Icon fontSize="small">info</Icon>,
		route: "/apigee-guide",
		component: (
			<RequireAuth>
				{" "}
				<ApigeeInstructions />
			</RequireAuth>
		),
	},
	{
		route: "*",
		component: <Error />,
	},
];
