import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

import AddNewAdminForm from "../../../forms/addAdmin";

function AddNewAdmin() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddNewAdminForm />
    </DashboardLayout>
  );
}

export default AddNewAdmin;
