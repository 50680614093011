// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { addTenant } from "../../apis/tenant/index";
import { Tenant } from "../../schemas";
import { useForm } from "react-hook-form";
import MDSelect from "../../components/MDSelect";
import MDDrop from "../../components/MDDropdown";
import { useEffect, useState } from "react";
import { editOrAddTenant } from "../functions/tenantFormFunctions/index";
import { RegexCode } from "../../config/validationRegex";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useLocation } from "react-router-dom";
// import { updateTenant } from "../../apis/tenant/index";
import MDSnackbar from "../../components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import { updateTenant } from "../functions/tenantFormFunctions/index";
import { regions, zones } from "../../config/gcpZoneAndRegions";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";

function EditVmTenantForm(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let { state } = useLocation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onChange" });

	const [tier, setTier] = useState(state.tenant.tier); //#
	const [status, setStatus] = useState("")
	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);

	const [mySuccessSB, setMySuccessSB] = useState(false);

	const [errorWhileAddingTenant, setErrorWhileAddingTenant] = useState("");
	const handleChange = (event) => {
		setTier(event.target.value);
	};

	const [region, setRegion] = useState(state.tenant.gcpRegion);
	const [zone, setZone] = useState(state.tenant.zone);
	const updateZone = (event) => {
		setZone(event.target.value);
	};

	const updateRegion = (event) => {
		setRegion(event.target.value);
	};

	// const [subnetSize, setSubnetSize] = useState(state.tenant.subnetSize);

	// const updateTenantdetails = () => {
	//   const tenantId = document.querySelector("#tenantId").value;
	//   const orgName = document.querySelector("#orgName").value;
	//   const tier = document.querySelector("#tier")?.value;
	//   const adminEmail = document.querySelector("#adminEmail").value;
	//   const orgLogo = document.querySelector("#orgLogo").value;
	//   const domain = document.querySelector("#domain").value;

	//   const region = document.querySelector("#region")?.value || null;
	//   const gcpProjectId = document.querySelector("#gcpProjectId")?.value || null;
	//   const bqDataSetId = document.querySelector("#bqDataSetId")?.value || null;
	//   const machineType = document.querySelector("#machineType")?.value || null;
	//   const zone = document.querySelector("#zone")?.value || null;
	//   const minCount = document.querySelector("#minCount")?.valueAsNumber || null;
	//   const maxCount = document.querySelector("#maxCount")?.valueAsNumber || null;
	//   const nodeCount =
	//     document.querySelector("#nodeCount")?.valueAsNumber || null;
	//   const podRange = document.querySelector("#podRange")?.value || null;
	//   const masterIp = document.querySelector("#masterIp")?.value || null;
	//   const subnetIp = document.querySelector("#subnetIp")?.value || null;
	//   const svcRange = document.querySelector("#svcRange")?.value || null;

	//   const dataToSend = {
	//     orgName,
	//     subdomain: tenantId,
	//     adminEmail,
	//     tier: tier,
	//     logoUrl: orgLogo,
	//     domain,
	//     gcpRegion: region,
	//     gcpProjectId,
	//     bqDataSetId,
	//     machineType,
	//     zone,
	//     minCount,
	//     maxCount,
	//     nodeCount,
	//     podRange,
	//     masterIp,
	//     subnetIp,
	//     svcRange,
	//   };

	//   console.log(dataToSend);

	//   // let isValidated = validateTenantData(dataToSend)

	//   updateTenant(dataToSend)
	//     .then((result) => {
	//       if (result.status === 200) {
	//         console.log("Data Updated Successfully");
	//       }
	//     })
	//     .catch((error) => {
	//       console.log(error);
	//     });
	// };

	const closeSuccessSB = () => {
		setSuccessSB(false);
		navigate("/tenants");
	};
	const closeErrorSB = () => setErrorSB(false);

	const onSubmit = async (data) => {
		const tenantId = document.querySelector("#tenantId").value;
		const orgName = document.querySelector("#orgName").value;
		// const tier = document.querySelector("#tier")?.value;
		const adminEmail = document.querySelector("#adminEmail").value;
		const orgLogo = document.querySelector("#orgLogo").value;
		const domain = document.querySelector("#domain").value;

		let subdomain = domain.indexOf(".");
		subdomain = domain.slice(0, subdomain);
		data.subdomain = subdomain;

		data.tenantId = tenantId;
		data.orgName = orgName;
		data.tier = "vm";
		data.adminEmail = adminEmail;
		data.orgLogo = orgLogo;
		data.domain = domain;
		data.region = region;
		data.zone = zone;

		setStatus("")
		const status = await updateTenant(data);
		setStatus(status)
	};

	useEffect(() => {
		if(status === ""){}
		else if(status === 200) {
			setSuccessSB(true);
		} 
		else if(status === 400) {
			setErrorWhileAddingTenant("Please provide valid organization name !!" );
			setErrorSB(true);
			setStatus("")
		}
		else if (status === 403) {
			console.log("Unauthorised: Logging Out");
			dispatch(logout());
			localStorage.removeItem("persist:root");
			localStorage.clear();
			window.location.href = "/sign-in";
			}
		else {
			setErrorWhileAddingTenant(!status?.response?.data?.message ? "No Response, Please Try After Sometime" : status.response.data.message);
			setErrorSB(true);
			setStatus("")
		}
	},[status]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{mySuccessSB && (
				<MDSnackbar
					icon="notifications"
					title="SaaS Admin Portal"
					content={`Updating details of VM user is in progress`}
					// dateTime="11 mins ago"
					open={mySuccessSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}

					// bgWhite
				/>
			)}
			{successSB ? (
				<MDSnackbar
					color="success"
					icon="check"
					title="SaaS Admin Portal"
					content={`Updated details of VM user`}
					// dateTime="11 mins ago"
					open={successSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}

					// bgWhite
				/>
			) : (
				<MDSnackbar
					color="error"
					icon="warning"
					title="SaaS Admin Portal"
					// content={`An error occured while adding ${tier === "freemium" ? `freemium`:`enterprise`} user, ${errorWhileAddingTenant}`}
					content={errorWhileAddingTenant}
					// dateTime="11 mins ago"
					open={errorSB}
					onClose={closeErrorSB}
					close={closeErrorSB}

					// bgWhite
				/>
			)}
			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox
						variant="gradient"
						bgColor="info"
						borderRadius="lg"
						coloredShadow="success"
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign="center"
					>
						<MDTypography
							variant="h4"
							fontWeight="medium"
							color="white"
							mt={1}
						>
							VM Edit Tenant
						</MDTypography>
						<MDTypography
							display="block"
							variant="button"
							color="white"
							my={1}
						>
							Enter the following information to Edit Tenant
							Details
						</MDTypography>
					</MDBox>
					<MDBox pt={4} pb={3} px={3}>
						<MDBox
							component="form"
							role="form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Grid container spacing={2}>
								<Grid item md={5} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Tenant ID"
											variant="standard"
											fullWidth
											id="tenantId"
											value={state.tenant.subdomain}
											InputProps={{
												readOnly: true,
											}}
										/>
									</MDBox>
								</Grid>
								<Grid item md={5} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Organization Name"
											variant="standard"
											fullWidth
											id="orgName"
											defaultValue={state.tenant.orgName}
											{...register("orgName", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: RegexCode.orgName,
													message:
														"Empty Space and Special Characters are not allowed",
												},
												maxLength: {
													value: 20,
													message:
														"Maximum 20 allowed characters",
												},
											})}
											error={Boolean(errors.orgName)}
											helperText={
												errors.orgName
													? errors.orgName.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item md={5} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="email"
											label="Admin E-mail"
											variant="standard"
											fullWidth
											id="adminEmail"
											value={state.tenant.adminEmail}
											InputProps={{
												readOnly: true,
											}}
										/>
									</MDBox>
								</Grid>
								<Grid item md={5} lg={6}>
								<MDBox mb={2}>
									<MDInput
										type="text"
										label="Domain"
										variant="standard"
										fullWidth
										id="domain"
										value={state.tenant.domain}
										InputProps={{
											readOnly: true,
										}}
									/>
								</MDBox>
							</Grid>
								{/* <Grid item md={5} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Tier"
											variant="standard"
											fullWidth
											id="tier"
											value={state.tenant.tier}
											InputProps={{
												readOnly: true,
											}}
										/>
									</MDBox>
								</Grid>*/}
							</Grid> 

							<Grid item md={4} lg={12}>
								<MDBox mb={2}>
									<MDInput
										type="text"
										label="Organization Logo"
										variant="standard"
										fullWidth
										id="orgLogo"
										defaultValue={state.tenant.logoUrl}
										{...register("orgLogo", {
											required: true,
											pattern: RegexCode.orgLogo,
										})}
										error={Boolean(errors.orgLogo)}
										helperText={
											errors.orgLogo
												? "Invalid Organization Logo"
												: ""
										}
									/>
								</MDBox>
							</Grid>

							{/* #check if needed below, what about enterpries*/}

							{state.tenant.tier == "enterprise" && (
								<>
									<Grid container spacing={2}>
										<Grid item md={5} lg={6}>
											{/* <MDBox mb={2}>
												<MDInput
													type="text"
													label="Region"
													variant="standard"
													fullWidth
													id="region"
													defaultValue={
														state.tenant.gcpRegion
													}
													InputProps={{
														readOnly: true,
													}}
												/>
											</MDBox> */}
											<MDBox mb={2}>
												<MDDrop
													label="Name"
													variant="standard"
													fullWidth
													id="region"
													sx={{
														mt: 1.1,
														minWidth: 120,
													}}
												>
													<InputLabel>
														Region
													</InputLabel>
													<Select
														// variant= "standard"
														// fullWidth
														id="region"
														value={region}
														label="region"
														onChange={updateRegion}
														MenuProps={{
															PaperProps: {
																style: {
																	maxHeight:
																		"300px",
																	width: "auto",
																},
															},
														}}
														// {...register("tier",{required: true})}
														// // error={Boolean(errors.tier)}
													>
														{regions.locations.map(
															(
																location,
																index
															) => (
																<MenuItem
																	key={index}
																	value={
																		location
																	}
																>
																	{location}
																</MenuItem>
															)
														)}
													</Select>
												</MDDrop>
											</MDBox>
										</Grid>

										<Grid item md={5} lg={6}>
											{/* <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Zone"
                          variant="standard"
                          fullWidth
                          defaultValue={state.tenant.zone}
                          id="zone"
                          {...register("zone", {
                            required: true,
                            // pattern: RegexCode.gcpRegex,
                          })}
                          error={Boolean(errors.zone)}
                          helperText={errors.zone ? "Invalid Zone" : ""}
                        />
                      </MDBox> */}
											<MDBox mb={2}>
												<MDDrop
													label="Name"
													variant="standard"
													fullWidth
													id="zone"
													sx={{
														mt: 1.1,
														minWidth: 120,
													}}
												>
													<InputLabel>
														Zones
													</InputLabel>
													<Select
														// variant= "standard"
														// fullWidth
														id="zone"
														value={zone}
														label="zone"
														onChange={updateZone}
														MenuProps={{
															PaperProps: {
																style: {
																	maxHeight:
																		"300px",
																	width: "auto",
																},
															},
														}}
														// {...register("tier",{required: true})}
														// // error={Boolean(errors.tier)}
													>
														{zones.locations.map(
															(
																location,
																index
															) => (
																<MenuItem
																	key={index}
																	value={
																		location
																	}
																>
																	{location}
																</MenuItem>
															)
														)}
													</Select>
												</MDDrop>
											</MDBox>
										</Grid>

										<Grid item md={5} lg={6}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="GCP Project ID"
													variant="standard"
													fullWidth
													readOnly
													defaultValue={
														state.tenant.projectId
													}
													id="gcpProjectId"
													InputProps={{
														readOnly: true,
													}}
												/>
											</MDBox>
										</Grid>
										<Grid item md={5} lg={6}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="BigQuery Dataset ID"
													variant="standard"
													fullWidth
													id="bqDataSetId"
													readOnly
													value={
														state.tenant.bqDatasetId
													}
													InputProps={{
														readOnly: true,
													}}
												/>
											</MDBox>
										</Grid>

										{/* <Grid item md={5} lg={6}>
                      <MDBox mb={2}>
                        <MDDrop
                          label="Name"
                          variant="standard"
                          fullWidth
                          id="subnetSizeDropdown"
                          sx={{
                            m: 1.1,
                            minWidth: 120,
                          }}
                        >
                          <InputLabel>Subnet Size</InputLabel>
                          <Select
                            // variant= "standard"
                            // fullWidth
                            id="subnetSize"
                            label="Subnet Size"
                            value={subnetSize}
                            onChange={(e) => setSubnetSize(e.target.value)}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: "300px",
                                  width: "auto",
                                },
                              },
                            }}
                            // {...register("tier",{required: true})}
                            // // error={Boolean(errors.tier)}
                          >
                            <MenuItem value={"small"}>Small</MenuItem>
                            <MenuItem value={"medium"}>Medium</MenuItem>
                            <MenuItem value={"large"}>Large</MenuItem>
                          </Select>
                        </MDDrop>
                      </MDBox>
                    </Grid> */}
										{/* <Grid item md={5} lg={5}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Machine Type"
													variant="standard"
													fullWidth
													id="machineType"
													defaultValue={
														state.tenant.machineType
													}
													{...register(
														"machineType",
														{
															required: true,
															// pattern: RegexCode.gcpRegex,
														}
													)}
													error={Boolean(
														errors.machineType
													)}
													helperText={
														errors.machineType
															? "Invalid Machine type"
															: ""
													}
												/>
											</MDBox>
										</Grid>

										<Grid item md={5} lg={4}>
											<MDBox mb={2}>
												<MDInput
													type="number"
													label="Min Count"
													variant="standard"
													fullWidth
													id="minCount"
													defaultValue={
														state.tenant.minCount
													}
													{...register("minCount", {
														required: true,
														// pattern: RegexCode.numbersRegex,
													})}
													error={Boolean(
														errors.minCount
													)}
													helperText={
														errors.minCount
															? "Invalid Min count"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item md={5} lg={4}>
											<MDBox mb={2}>
												<MDInput
													type="number"
													label="Max Count"
													variant="standard"
													fullWidth
													id="maxCount"
													defaultValue={
														state.tenant.maxCount
													}
													{...register("maxCount", {
														required: true,
														// pattern: RegexCode.numbersRegex,
													})}
													error={Boolean(
														errors.maxCount
													)}
													helperText={
														errors.maxCount
															? "Invalid Max count"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item md={5} lg={4}>
											<MDBox mb={2}>
												<MDInput
													type="number"
													label="Node Count"
													variant="standard"
													fullWidth
													id="nodeCount"
													defaultValue={
														state.tenant.nodeCount
													}
													{...register("nodeCount", {
														required: true,
														// pattern: RegexCode.numbersRegex,
													})}
													error={Boolean(
														errors.nodeCount
													)}
													helperText={
														errors.nodeCount
															? "Invalid Node count"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item md={5} lg={4}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Pod Range"
													variant="standard"
													fullWidth
													id="podRange"
													defaultValue={
														state.tenant.podRange
													}
													{...register("podRange", {
														required: true,
														// pattern: RegexCode.numbersRegex,
													})}
													error={Boolean(
														errors.podRange
													)}
													helperText={
														errors.podRange
															? "Invalid Pod Range"
															: ""
													}
												/>
											</MDBox>
										</Grid>

										<Grid item md={5} lg={4}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Master IP"
													variant="standard"
													fullWidth
													id="masterIp"
													defaultValue={
														state.tenant.masterIp
													}
													{...register("masterIp", {
														required: true,
														// pattern: RegexCode.ipRegex,
													})}
													error={Boolean(
														errors.masterIp
													)}
													helperText={
														errors.masterIp
															? "Invalid Master IP"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item md={5} lg={7}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Subnet IP"
													variant="standard"
													fullWidth
													id="subnetIp"
													defaultValue={
														state.tenant.subnetIp
													}
													{...register("subnetIp", {
														required: true,
														// pattern: RegexCode.ipRegex,
													})}
													error={Boolean(
														errors.subnetIp
													)}
													helperText={
														errors.subnetIp
															? "Invalid Subnet IP"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item md={5} lg={5}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="SVC Range"
													variant="standard"
													fullWidth
													id="svcRange"
													defaultValue={
														state.tenant.svcRange
													}
													{...register("svcRange", {
														required: true,
														// pattern: RegexCode.svcRegex,
													})}
													error={Boolean(
														errors.svcRange
													)}
													helperText={
														errors.svcRange
															? "Invalid SVC Range"
															: ""
													}
												/>
											</MDBox>
										</Grid> */}
									</Grid>
								</>
							)}
							<MDBox mt={4} mb={1}>
								<MDButton
									variant="gradient"
									color="info"
									fullWidth
									// onClick={onboardTenant}
									onClick={handleSubmit(onSubmit)}
								>
									Update Tenant
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
				{/* </CoverLayoutForForms> */}
			</MDBox>
		</DashboardLayout>
	);
}

export default EditVmTenantForm;
