import colors from "../../base/colors";
import typography from "../../base/typography";

import pxToRem from "../../functions/pxToRem";

const { white } = colors;
const { size, fontWeightBold } = typography;

const formControlLabel = {
	styleOverrides: {
		root: {
			display: "block",
			minHeight: pxToRem(24),
			marginBottom: pxToRem(2),
		},

		label: {
			display: "inline-block",
			fontSize: size.sm,
			fontWeight: fontWeightBold,
			color: white.main,
			lineHeight: 1,
			transform: `translateY(${pxToRem(1)})`,
			marginLeft: pxToRem(4),

			"&.Mui-disabled": {
				color: white.main,
			},
		},
	},
};

export default formControlLabel;
