// MUI components
import * as React from "react";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { Grid, InputLabel, MenuItem } from "@mui/material";
import MDDrop from "../../components/MDDropdown";
import MDSelect from "../../components/MDSelect";
import { Typography, Stepper, Step, StepLabel } from "@mui/material";
import MDSnackbar from "../../components/MDSnackbar";
// Axios Api
import { addOidcProvider } from "../../apis/tenant";
import { addSamlProvider } from "../../apis/tenant";
import { addGoogleProvider } from "../../apis/tenant";

//redux
import { selectTenant, logout } from "../../redux/userSlice";
import { useSelector, useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { useState } from "react";
import { addAdmin } from "../functions/adminFormFunctions";
import { RegexCode } from "../../config/validationRegex";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { Icon, IconButton, Tooltip, tooltipClasses } from "@mui/material";

const steps = ["Identity Provider", "Required Details", "Confirm it"];

function AddIdentityProvider() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({ mode: "onChange" });
	const dispatch = useDispatch();
	
	const navigate = useNavigate();
	const user = useSelector(selectTenant);
	// console.log(user);
	// common variables
	const [activeStep, setActiveStep] = useState(0);
	const [formData, setFormData] = useState({});
	const [access, setAccess] = useState("saml");
	const [displayName, setDisplayName] = useState("");
	const [enabled, setEnabled] = useState(true);
	const [providerId, setProviderId] = useState("");

	// oidc variables
	const [clientId, setClientId] = useState("");
	const [issuer, setIssuer] = useState("");
	const [responseType, setResponseType] = useState({ idToken: true });
	const [idToken, setIdToken] = useState(true);

	//saml variables
	const [entityId, setEntityId] = useState("");
	const [ssoUrl, setSsoUrl] = useState("");
	const [x509Certificates, setX509Certificates] = useState([]);
	const [rpEntityId, setRpEntityId] = useState("");

	const [successSB, setSuccessSB] = useState(false);
	const [infoSuccessSB, setInfoSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	let projectName = process.env.REACT_APP_PROJECT_ID;
	const [callbackURL, setCallbackURL] = useState(
		`https://${user.callbackUrl}/__/auth/handler`
	);

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "#f5f5f9",
			color: "rgba(0, 0, 0, 0.87)",
			maxWidth: 520,
			fontSize: theme.typography.pxToRem(12),
			border: "1px solid #dadde9",
		},
	}));

	const handleNext = (test) => {
		test.preventDefault();
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleGoogleNext = (test) => {
		test.preventDefault();
		setActiveStep((prevActiveStep) => prevActiveStep + 2);
	};

	const handleBack = (test) => {
		test.preventDefault();
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleGoogleBack = () => {
		test.preventDefault();
		setActiveStep((prevActiveStep) => prevActiveStep - 2);
	};

	const handleChange1 = (event) => {
		event.preventDefault();
		setAccess(event.target.value);
		resetState();
	};

	const resetState = () => {
		reset({
            displayName:"",
			providerId:"",
			entityId:"",
			ssoUrl:"",
			x509Certificates:"",
			rpEntityId:"",
			clientId:"",
			issuer:""
    })
		// setDisplayName("");
		// setProviderId("");
		// setEntityId("");
		// setSsoUrl("");
		// setX509Certificates([]);
		// setRpEntityId("");
		// setClientId("");
		// setIssuer("");
	}

	const closeErrorSB = () => setErrorSB(false);
	const closeSuccessSB = () => {
		setSuccessSB(false);
		navigate("/identity-providers");
	};
	const submitData = (data) => {
		console.log(data)
		if (access == "saml") {
			const dataToSend = {
				subdomain: user.subdomain,
				type: access,
				displayName: data.displayName,
				enabled,
				providerId: "saml." + providerId,
				idpEntityId: entityId,
				ssoURL: ssoUrl,
				rpEntityId: data.rpEntityId,
				callbackURL,
				x509Certificates,
			};
			// console.log(dataToSend);
			addSamlProvider(dataToSend)
				.then((result) => {
					if (result.status === 200) {
						// console.log("added Successfully");
						navigate("/identity-providers");
					}
				})
				.catch((error) => {
					console.log(error);
					if (error.response.status === 403) {
						console.log("Unauthorised: Logging Out");
						dispatch(logout());
						localStorage.removeItem("persist:root");
						localStorage.clear();
						window.location.href = "/sign-in";
					} else {
						setErrorMsg(error.response.data.message);
						setErrorSB(true);
					}
				});

			// console.log(dataToSend);
		} else if (access == "oidc") {
			const dataToSend = {
				subdomain: user.subdomain,
				type: access,
				displayName,
				enabled,
				providerId: "oidc." + providerId,
				clientId,
				issuer,
				responseType,
			};

			addOidcProvider(dataToSend)
				.then((result) => {
					if (result.status === 200) {
						// console.log("added Successfully");
						navigate("/identity-providers");
					}
				})
				.catch((error) => {
					console.log(error);
					if (error.response.status === 403) {
						console.log("Unauthorised: Logging Out");
						dispatch(logout());
						localStorage.removeItem("persist:root");
						localStorage.clear();
						window.location.href = "/sign-in";
					} else {
						setErrorMsg(error.response.data.message);
						setErrorSB(true);
					}
				});

			// console.log(dataToSend);
		} else {
			const dataToSend = {
				subdomain: user.subdomain,
				enabled: true,
			};
			setInfoSuccessSB(true);
			addGoogleProvider(dataToSend)
				.then((result) => {
					if (result.status === 200) {
						setSuccessSB(true);
						console.log("added Successfully");
					}
				})
				.catch((error) => {
					console.log(error);
					if (error.response.status === 403) {
						console.log("Unauthorised: Logging Out");
						dispatch(logout());
						localStorage.removeItem("persist:root");
						localStorage.clear();
						window.location.href = "/sign-in";
					} else {
						setErrorMsg(error.response.data.message);
						setErrorSB(true);
					}
				});
			// console.log(dataToSend);
		}
	};

// React.useEffect(()=>{console.log(displayName)},[displayName])

	return (
		<DashboardLayout>
			{infoSuccessSB && (
				<MDSnackbar
					icon="notifications"
					title="SaaS Admin Portal"
					content={`Updating Google Idp details is in progress`}
					// dateTime="11 mins ago"
					open={infoSuccessSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}

				// bgWhite
				/>
			)}
			{successSB ? (
				<MDSnackbar
					color="success"
					icon="check"
					title="SaaS Admin Portal"
					content={`Edited details of idp successfully`}
					// dateTime="11 mins ago"
					open={successSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}

				// bgWhite
				/>
			) : (
				<MDSnackbar
					color="error"
					icon="warning"
					title="SaaS Admin Portal"
					content={
						errorMsg == ""
							? `An error occured while editing idp details`
							: `${errorMsg}`
					}
					// dateTime="11 mins ago"
					open={errorSB}
					onClose={closeErrorSB}
					close={closeErrorSB}
				// bgWhite
				/>
			)}
			<DashboardNavbar />

			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox pt={4} pb={3} px={3}>
						<MDBox
							component="form"
							role="form"
							onSubmit={handleSubmit(submitData)}
						>
							<Grid container spacing={2}>
								<Grid item md={12} lg={12} sm={12}>
									<MDBox
										variant="gradient"
										bgColor="info"
										borderRadius="lg"
										coloredShadow="success"
										mx={2}
										mt={-7}
										p={3}
										mb={1}
										textAlign="center"
									>
										<MDTypography
											variant="h4"
											fontWeight="medium"
											color="white"
											mb={1}
										>
											Add Identity Provider Details
										</MDTypography>

										<Stepper activeStep={activeStep}>
											{steps.map((label) => (
												<Step key={label}>
													<StepLabel>
														{label}
													</StepLabel>
												</Step>
											))}
										</Stepper>
									</MDBox>
								</Grid>
								<Grid item md={12} lg={12}>
									{activeStep === 0 && (
										<MDBox mb={2}>
											<MDDrop
												label="Name"
												variant="standard"
												fullWidth
												id="tenantId"
												sx={{ m: 1.1, minWidth: 120 }}
											>
												<InputLabel>Access</InputLabel>
												<MDSelect
													variant="standard"
													fullWidth
													id="adminAccess"
													value={access}
													label="Access"
													onChange={handleChange1}
												>
													<MenuItem value={"saml"}>
														SAML
													</MenuItem>
													<MenuItem value={"oidc"}>
														OIDC
													</MenuItem>
												</MDSelect>
											</MDDrop>
										</MDBox>
									)}
									{activeStep === 1 && access == "saml" && (
										<Grid container spacing={3}>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={displayName}
														type="text"
														label="Display name"
														variant="standard"
														fullWidth
														id="displayName"
														{...register("displayName", {
															required: {
																value: true,
																message: "Required",
															},
															onChange:(e)=>setDisplayName(e.target.value)
														})
													}
														error={Boolean(errors.displayName)}
														helperText={
															errors.displayName
																? errors.displayName.message
																: ""
														}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={providerId}
														type="text"
														label="Provider ID"
														variant="standard"
														fullWidth
														id="providerId"
														{...register("providerId", {
															required: {
																value: true,
																message: "Required",
															},
															onChange:(e)=>setProviderId(e.target.value)
														})}
														error={Boolean(errors.providerId)}
														helperText={
															errors.providerId
																? errors.providerId.message
																: ""
														}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={entityId}
														type="text"
														label="IDP Entity ID"
														variant="standard"
														fullWidth
														id="entityId"

														{...register("entityId", {
															required: {
																value: true,
																message: "Required",
															},
															onChange:(e)=>setEntityId(e.target.value)
														})}
														error={Boolean(errors.entityId)}
														helperText={
															errors.entityId
																? errors.entityId.message
																: ""
														}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={ssoUrl}
														type="text"
														label="SSO URL"
														variant="standard"
														fullWidth
														id="ssoUrl"
														{...register("ssoUrl", {
															required: {
																value: true,
																message: "Required",
															},
															onChange:(e)=>setSsoUrl(e.target.value)
														})}
														error={Boolean(errors.ssoUrl)}
														helperText={
															errors.ssoUrl
																? errors.ssoUrl.message
																: ""
														}
														// onChange={(e) =>
														// 	setSsoUrl(
														// 		e.target.value
														// 	)
														// }
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={rpEntityId}
														type="text"
														label="RP Entity ID"
														variant="standard"
														fullWidth
														id="rpEntityId"
														{...register("rpEntityId", {
															required: {
																value: true,
																message: "Required",
															},
															onChange:(e)=>setRpEntityId(e.target.value)
														})}
														error={Boolean(errors.rpEntityId)}
														helperText={
															errors.rpEntityId
																? errors.rpEntityId.message
																: ""
														}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<Grid container>
													<Grid item xs={11}>
														<MDBox mb={2}>
															<MDInput
																value={callbackURL}
																type="text"
																label="Callback URL"
																variant="standard"
																fullWidth
																id="callbackUrl"
																readOnly
															// onChange={(e) => setCallbackURL(e.target.value)}
															/>
														</MDBox>
													</Grid>
													<Grid item xs={1}>
														<HtmlTooltip
															style={{
																position:
																	"relative",
																left: "50%",
																top: "50%",
																textAlign:
																	"left",
															}}
															placement="top"
															title={
																<React.Fragment>
																	<p>
																		Copy
																		this and
																		add this
																		as
																		redirect
																		URI in
																		your
																		provider's
																		configurations
																	</p>
																</React.Fragment>
															}
														>
															<Icon
																sx={{
																	cursor: "pointer",
																}}
																fontSize="small"
															>
																help
															</Icon>
														</HtmlTooltip>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} sm={12}>
												<MDBox mb={2}>
													<MDInput
														// value={x509Certificates}
														type="text"
														multiline
														rows={5}
														label="X.509 certificates"
														variant="standard"
														fullWidth
														id="x0509certificates"
														{...register("x0509certificates", {
															required: {
																value: true,
																message: "Required",
															},
															pattern: {
																value: RegexCode.x509Regex,
																message: "Please enter a valid certificate, Must start with '-----BEGIN CERTIFICATE-----' and end with '-----END CERTIFICATE-----",
															},
															
															onChange:(e)=>setX509Certificates([e.target.value])
														})}
														error={Boolean(errors.x0509certificates)}
														helperText={
															errors.x0509certificates
																? errors.x0509certificates.message
																: ""
														}
													/>
												</MDBox>
											</Grid>
										</Grid>
									)}
									{activeStep === 1 && access == "oidc" && (
										<Grid container spacing={3}>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={displayName}
														type="text"
														label="Display name"
														variant="standard"
														fullWidth
														id="displayName"
														{...register(
															"displayName",
															{
																required: {
																	value: true,
																	message: "Required",
																},
																onChange:(e)=>setDisplayName(e.target.value)
															}
														)}
														error={Boolean(
															errors.displayName
														)}
														helperText={
															errors.displayName
																? errors.displayName.message
																: ""
														}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={providerId}
														type="text"
														label="Provider ID"
														variant="standard"
														fullWidth
														id="providerId"
														{...register("providerId", {
															onChange:(e)=>setProviderId(e.target.value),
															required: {
																value: true,
																message: "Required",
															},
															pattern: {value:RegexCode.alphabetsAndNumbersRegex,
															message:"Enter valid provider id"},
														})}
														error={Boolean(errors.providerId)}
														helperText={
															errors.providerId ? errors.providerId.message: ""
														}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={clientId}
														type="text"
														label="Client ID"
														variant="standard"
														fullWidth
														id="clientId"
														{...register("clientId", {
															onChange: (e)=>setClientId(e.target.value),
															required: {
																value: true,
																message: "Required",
															},
															pattern: RegexCode.alphabetsAndNumbersRegex,
														})}
														error={Boolean(errors.clientId)}
														helperText={
															errors.clientId ? errors.clientId.message : ""
														}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} lg={6}>
												<MDBox mb={2}>
													<MDInput
														// value={issuer}
														type="text"
														label="Issuer"
														variant="standard"
														fullWidth
														id="issuer"
														
														{...register("issuer", {
															onChange: (e)=>setIssuer(e.target.value),
															required: {
																value: true,
																message: "Required",
															}
														})}
														error={Boolean(errors.issuer)}
														helperText={
															errors.issuer ? errors.issuer.message : ""
														}
													/>
												</MDBox>
											</Grid>
										</Grid>
									)}

									{activeStep === 2 && access === "saml" && (
										<Grid container spacing={2}>
											<Grid
												item
												xs={12}
												sm={12}
												lg={12}
												style={{ textAlign: "center" }}
											>
												<Typography
													variant="h6"
													textAlign="center"
												>
													Following are the details
													provided:{" "}
												</Typography>
												<br />
												<Grid container>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"right",
															paddingRight: "5px",
														}}
													>
														<Typography variant="body1">
															<strong>
																Identity
																Provider:
															</strong>
														</Typography>
													</Grid>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"left",
															paddingLeft: "5px",
														}}
													>
														<Typography variant="body1">
															{access}
														</Typography>
													</Grid>
												</Grid>
												<Grid container>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"right",
															paddingRight: "5px",
														}}
													>
														<Typography variant="body1">
															<strong>
																Display Name:
															</strong>
														</Typography>
													</Grid>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"left",
															paddingLeft: "5px",
														}}
													>
														<Typography variant="body1">
															{displayName}
														</Typography>
													</Grid>
												</Grid>
												<Grid container>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"right",
															paddingRight: "5px",
														}}
													>
														<Typography variant="body1">
															<strong>
																Provider ID:
															</strong>
														</Typography>
													</Grid>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"left",
															paddingLeft: "5px",
														}}
													>
														<Typography variant="body1">
															{providerId}
														</Typography>
													</Grid>
												</Grid>

												{/* <Typography variant="body1">
                          Identity Provider: {access}
                        </Typography>
                        <Typography variant="body1">
                          Display Name: {displayName}
                        </Typography>
                        <Typography variant="body1">
                          Provider ID: {providerId}
                        </Typography> */}

												<br />
												<Typography
													variant="h6"
													textAlign="center"
												>
													Press the back button if you
													want to change it.
												</Typography>
											</Grid>
										</Grid>
									)}

									{activeStep === 2 && access === "oidc" && (
										<Grid container spacing={2}>
											<Grid
												item
												xs={12}
												sm={12}
												lg={12}
												style={{ textAlign: "center" }}
											>
												<Typography
													variant="h6"
													textAlign="center"
												>
													Following are the details
													provided:{" "}
												</Typography>
												<br />
												<Grid container>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"right",
															paddingRight: "5px",
														}}
													>
														<Typography variant="body1">
															<strong>
																Identity
																Provider:
															</strong>
														</Typography>
													</Grid>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"left",
															paddingLeft: "5px",
														}}
													>
														<Typography variant="body1">
															{access}
														</Typography>
													</Grid>
												</Grid>
												<Grid container>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"right",
															paddingRight: "5px",
														}}
													>
														<Typography variant="body1">
															<strong>
																Display Name:
															</strong>
														</Typography>
													</Grid>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"left",
															paddingLeft: "5px",
														}}
													>
														<Typography variant="body1">
															{displayName}
														</Typography>
													</Grid>
												</Grid>
												<Grid container>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"right",
															paddingRight: "5px",
														}}
													>
														<Typography variant="body1">
															<strong>
																Provider ID:
															</strong>
														</Typography>
													</Grid>
													<Grid
														item
														lg={6}
														md={6}
														lg={6}
														style={{
															display: "flex",
															justifyContent:
																"left",
															paddingLeft: "5px",
														}}
													>
														<Typography variant="body1">
															{providerId}
														</Typography>
													</Grid>
												</Grid>

												{/* <Typography variant="body1">
                          Identity Provider: {access}
                        </Typography>
                        <Typography variant="body1">
                          Display Name: {displayName}
                        </Typography>
                        <Typography variant="body1">
                          Provider ID: {providerId}
                        </Typography> */}

												<br />
												<Typography
													variant="h6"
													textAlign="center"
												>
													Press the back button if you
													want to change it.
												</Typography>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>

							<MDBox mt={4} mb={1} textAlign="center" sx={{ marginLeft: -10 }}>
								{activeStep > 0 && access == "Google" && (
									<MDButton
										variant="contained"
										color="secondary"
										onClick={handleGoogleBack}
									>
										Back
									</MDButton>
								)}
								{activeStep > 0 && access != "Google" && (
									<MDButton
										variant="contained"
										color="secondary"
										onClick={handleBack}
										sx={{ marginLeft: 8 }}
									>
										Back
									</MDButton>
								)}

								{activeStep === steps.length - 1 && (
									<MDButton
										variant="gradient"
										color="info"
										// onClick={submitData}
										sx={{ marginLeft: 6 }}
										type="submit"
									>
										Submit
											
									</MDButton>
								)}
								{activeStep <= 1 && access == "Google" && (
									<MDButton
										variant="gradient"
										color="info"
										sx={{ marginLeft: 6 }}
										onClick={handleGoogleNext}
									>
										{activeStep === steps.length - 1
											? "Submit"
											: "Next"}
									</MDButton>
								)}
								{activeStep <= 1 && access != "Google" && (
									<MDButton
										variant="gradient"
										color="info"
										sx={{ marginLeft: 6 }}
										onClick={handleNext}
									>
										{activeStep === steps.length - 1
											? "Submit"
											: "Next"}
									</MDButton>
								)}
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</DashboardLayout>
	);
}

export default AddIdentityProvider;
