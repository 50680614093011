import * as React from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import AddNewVmTenantForm from "../../../vmForms/addTenant";

function AddNewVmTenant() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddNewVmTenantForm />
    </DashboardLayout>
  );
}

export default AddNewVmTenant;
