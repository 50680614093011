/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { Link as RouterLink, Navigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import MDBadge from "../../../components/MDBadge";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import DeleteModal from "../../../components/MDModal";
import React, { useState, useEffect } from "react";
import { deleteVmTenant, getAllTenant } from "../../../apis/tenant";
import { deleteTenant } from "../../../apis/tenant";
import { useMaterialUIController } from "../../../context";
import MDSnackbar from "../../../components/MDSnackbar";
import { selectVendor, logout } from "../../../redux/userSlice";
import { useSelector, useDispatch } from "react-redux";

export default function data(deleteStatusForTenant, checkIsDataPresent) {
	const dispatch = useDispatch();
	const vendor = useSelector(selectVendor);
	// console.log("vendor details",vendor);
	const [deleteemail, setDeleteEmail] = useState();
	const [open, setOpen] = useState(false);
	const [tenants, setTenants] = useState([]);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const handleConfirmDelete = async (tenantObject) => {
		setOpen(false);
		try {
			deleteStatusForTenant("inprogress");
			let res = await deleteVmTenant({ tenantObject });
			console.log(res.status)
			if (res.status === 200) {
				deleteStatusForTenant("deleted");
			} else {
				deleteStatusForTenant("notDeleted");
			}
		} catch (error) {
			console.log("error in delete tenant", error);
			// setErrorSB(true);
			deleteStatusForTenant("notDeleted");
			if (error.response.status === 403) {
				console.log("Unauthorised: Logging Out");
				dispatch(logout());
				localStorage.removeItem("persist:root");
				localStorage.clear();
				window.location.href = "/sign-in";
			}
		}
		// deleteTenant({ tenantObject })
		//   .then((result) => {
		//     if (result.status === 200) {
		//       console.log("Deleted Successfully");
		//       window.location.href = "/sign-in";;
		//     }
		//   })
		//   .catch((error) => {
		//     console.log(error);
		//   });
	};

	useEffect(() => {
		getAllTenant()
			.then((response) => {
				if (response.status === 200) {
					setTenants(response.data.tenants);
				}
			})
			.catch((error) => {
				checkIsDataPresent("false");
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			});
	}, []);

	const Author = ({ image, name, email }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDAvatar src={image} name={name} size="md" />
			<MDBox ml={2} lineHeight={1}>
				<MDTypography
					display="block"
					variant="button"
					fontWeight="medium"
				>
					{name}
				</MDTypography>
				<MDTypography variant="caption">{email}</MDTypography>
			</MDBox>
		</MDBox>
	);

	const Job = ({ title, description }) => (
		<MDBox lineHeight={1} textAlign="left">
			<MDTypography
				display="block"
				variant="caption"
				color="text"
				fontWeight="medium"
			>
				{title}
			</MDTypography>
			<MDTypography variant="caption">{description}</MDTypography>
		</MDBox>
	);

	// conditionally rendering update option in different environments 
	return process.env.REACT_APP_TENENT_ENV != "VM"
	? {
		columns: [
			{
				Header: "Organization Name",
				accessor: "author",
				width: "25%",
				align: "left",
			},
			// { Header: "Tier", accessor: "function", align: "left" },
			{ Header: "Domain", accessor: "status", align: "center" },
			{ Header: "Status", accessor: "tenantStatus", align: "center" },
			{ Header: "Update Tenant", accessor: "employed", align: "center" },
			{ Header: "Delete Tenant", accessor: "action", align: "center" },
		],

		rows: tenants.map((tenant) => ({
			author: (
				<Author
					image={tenant.logoUrl}
					name={tenant.orgName}
					email={tenant.adminEmail}
				/>
			),
			// function: <Job title={tenant.tier} description="" />,
			status: (
				<Link href={`https://${tenant.domain}`} target="_blank">
					<MDTypography
						variant="button"
						fontWeight="regular"
						color={darkMode ? "white" : "dark"}
					>
						{tenant.domain}
					</MDTypography>
				</Link>
			),
			tenantStatus: (
				<MDButton
					component={RouterLink}
					to={{
						pathname: "/tenant-onboard-status",
						search: `?subdomain=${tenant.subdomain}`,
					}}
					variant="text"
					color={darkMode ? "white" : "dark"}
				>
					<Icon>launch</Icon>&nbsp;
				</MDButton>
			),
			employed: (
				<MDButton
					component={RouterLink}
					to="/edit-tenant"
					state={{ tenant }}
					variant="text"
					color={darkMode ? "white" : "dark"}
					// disabled={!vendor.isAdmin}
					disabled={tenant.tier === "enterprise" || (tenant.tier === "vm" && !vendor.isAdmin)}
				>
					<Icon>edit</Icon>&nbsp;
				</MDButton>
			),
			action: (
				<>
					<MDBox mr={1}>
						<MDButton
							variant="text"
							color="error"
							disabled={!vendor.isAdmin}
							onClick={() => {
								setOpen(true);
								setDeleteEmail(tenant);
							}}
						>
							<Icon>delete</Icon>&nbsp;
						</MDButton>
						<DeleteModal
							deleting="tenant"
							open={open}
							onClose={() => setOpen(false)}
							onDelete={() => handleConfirmDelete(deleteemail)}
						/>
					</MDBox>
				</>
			),
		})),
	}
	: {
		columns: [
			{
				Header: "Organization Name",
				accessor: "author",
				width: "25%",
				align: "left",
			},
			{ Header: "Domain", accessor: "status", align: "center" },
			{ Header: "Status", accessor: "tenantStatus", align: "center" },
			{ Header: "Delete Tenant", accessor: "action", align: "center" },
		],

		rows: tenants.map((tenant) => ({
			author: (
				<Author
					image={tenant.logoUrl}
					name={tenant.orgName}
					email={tenant.adminEmail}
				/>
			),
			// function: <Job title={tenant.tier} description="" />,
			status: (
				<Link href={`https://${tenant.domain}`} target="_blank">
					<MDTypography
						variant="button"
						fontWeight="regular"
						color={darkMode ? "white" : "dark"}
					>
						{tenant.domain}
					</MDTypography>
				</Link>
			),
			tenantStatus: (
				<MDButton
					component={RouterLink}
					to={{
						pathname: "/tenant-onboard-status",
						search: `?subdomain=${tenant.subdomain}`,
					}}
					variant="text"
					color={darkMode ? "white" : "dark"}
				>
					<Icon>launch</Icon>&nbsp;
				</MDButton>
			),
			action: (
				<>
					<MDBox mr={1}>
						<MDButton
							variant="text"
							color="error"
							disabled={!vendor.isAdmin}
							onClick={() => {
								setOpen(true);
								setDeleteEmail(tenant);
							}}
						>
							<Icon>delete</Icon>&nbsp;
						</MDButton>
						<DeleteModal
							deleting="tenant"
							open={open}
							onClose={() => setOpen(false)}
							onDelete={() => handleConfirmDelete(deleteemail)}
						/>
					</MDBox>
				</>
			),
		})),
	}
}
