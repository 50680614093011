import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import MDBox from '../../../components/MDBox';
import Card from "@mui/material/Card";
import MDTypography from '../../../components/MDTypography';
import {Routes, Route, useNavigate} from 'react-router-dom';


export default function Error() {
    const navigate = useNavigate();

    const home = () =>{
        navigate('/cost-summary');
    }
    
    const handleGoBack = () => {
        navigate(-1);
        
    };

  return (
    <DashboardLayout>
        
        <MDBox pt={20} pb={3}>
            
                <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
                >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                404 Page Not Found
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                    here was a problem on our end. Please try again later.
                </MDTypography>
                        
                    <Button
                    
                    style={{
                        height: "50px",
                        width: "150px",
                        fontSize: "16px",
                        borderRadius: "5px",
                        color:'white'
                      
                    }}
                    
                    onClick={handleGoBack}
                >
                    &larr; Go Back
                </Button>
                
                </MDBox>
            
        </MDBox>
           
            
    </DashboardLayout>
  );
}