import * as React from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import AddNewTenantForm from "../../../forms/addTenant";

function AddNewTenant() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddNewTenantForm />
    </DashboardLayout>
  );
}

export default AddNewTenant;
