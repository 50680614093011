import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import EditIapDetailsSAML from "../../../../forms/editIAP/editSAMLform.js";

function EditIapProviderSAML(props) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <EditIapDetailsSAML />
    </DashboardLayout>
  );
}

export default EditIapProviderSAML;