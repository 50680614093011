import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

// Internal components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDAvatar from "../../../../components/MDAvatar";
import breakpoints from "../../../../assets/theme/base/breakpoints";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";

// Images
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";

// Redux components
import { useSelector } from "react-redux";
import { selectUser, selectVendor } from "../../../../redux/userSlice";
import { setDataStudioUrl, logout } from "../../../../redux/userSlice";
import { useDispatch } from "react-redux";

// Axios APIs
import { getDefaultVendor, getDefaultVmVendorDetail, updateDefaultVmVendor } from "../../../../apis/admin";
import { updateDefaultVendor } from "../../../../apis/admin";

import { useForm } from "react-hook-form";
import getDateForSnackbar from "../../../../helperFunctions/getDate";

// TabPanel component
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</Box>
	);
}

function Dashboard() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	// variable declarations
	const [vendorName, setVendorName] = useState("");
	const [vendorOrgname, setVendorOrgname] = useState("");
	const [vendorEmail, setVendorEmail] = useState("");
	const [vendorPhno, setVendorPhno] = useState("");
	const [vendorLogo, setVendorLogo] = useState("");
	const [vendorFreemiumDomain, setVendorVmDomain] = useState("");
	const [landingpageUrl, setLandingPageUrl] = useState("");
	const [costpageUrl, setcostPageUrl] = useState("");
	const [usagepageUrl, setusagePageUrl] = useState("");

	const [cloudBuildTrigger, setVmTrigger] = useState("");
	const [cloudBuildProjectloc, setVmProjectloc] = useState("");
	const [cloudBuildReponame, setVmReponame] = useState("");
	const [cloudBuildBranchname, setVmBranchname] = useState("");

	const [cloudBuildDeleteTrigger, setVmDeleteTrigger] = useState("");
	const [cloudBuildDeleteProjectloc, setVmDeleteProjectloc] = useState("");
	const [cloudBuildDeleteReponame, setVmDeleteReponame] = useState("");
	const [cloudBuildDeleteBranchname, setVmDeleteBranchname] = useState("");

	// const [cloudBuildUpdateTrigger, setVmUpdateTrigger] = useState("");
	// const [cloudBuildUpdateProjectloc, setVmUpdateProjectloc] = useState("");
	// const [cloudBuildUpdateReponame, setVmUpdateReponame] = useState("");
	// const [cloudBuildUpdateBranchname, setVmUpdateBranchname] = useState("");

	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const vendor = useSelector(selectVendor);

	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [tabValue, setTabValue] = useState(0);
	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	// list Vendor Settings
	useEffect(() => {
		getDefaultVmVendorDetail()
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data.vendors[0]);
					setVendorName(response.data.vendors[0].defaultVendorName);
					setVendorEmail(response.data.vendors[0].defaultVendorEmail);
					setVendorOrgname(response.data.vendors[0].orgName);
					setVendorPhno(response.data.vendors[0].phoneNo);
					setVendorLogo(response.data.vendors[0].logoUrl);
					setLandingPageUrl(response.data.vendors[0].landingPageDataStudioUrl);
					setcostPageUrl(response.data.vendors[0].costDataStudioUrl);
					setusagePageUrl(response.data.vendors[0].usageDataStudioUrl);
					setVendorVmDomain(response.data.vendors[0].vmSubdomain);
					setVmTrigger(response.data.vendors[0].vmCloudBuildTrigger);//
					setVmProjectloc(response.data.vendors[0].vmCloudBuildProjectLocation);
					setVmReponame(response.data.vendors[0].vmCloudBuildRepoName);
					setVmBranchname(response.data.vendors[0].vmCloudBuildBranchName);
					setVmDeleteTrigger(response.data.vendors[0].deleteVmCloudBuildTrigger);
					setVmDeleteProjectloc(response.data.vendors[0].deleteVmCloudBuildProjectLocation);
					setVmDeleteReponame(response.data.vendors[0].deleteVmCloudBuildRepoName);
					setVmDeleteBranchname(response.data.vendors[0].deleteVmCloudBuildBranchName);
					// setVmUpdateTrigger(response.data.vendors[0].updateVmCloudBuildTrigger);
					// setVmUpdateProjectloc(response.data.vendors[0].updateVmCloudBuildProjectLocation);
					// setVmUpdateReponame(response.data.vendors[0].updateVmCloudBuildRepoName);
					// setVmUpdateBranchname(response.data.vendors[0].updateVmCloudBuildBranchName);
				}
			})
			.catch((error) => {
				// alert(
				//   `Oops, an error occured while loading the page. Please try again after some time. ${error}`
				// );
				setErrorMessage(
					"Error while loading page, please try again later"
				);
				setErrorSB(true);
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");

					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			});
	}, []);

	useEffect(() => {
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		/** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
		window.addEventListener("resize", handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () =>
			window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => setTabValue(newValue);

	const updateDefaultVendorData = () => {
		const dataToSend = {
			name: vendorName,
			email: vendorEmail,
			orgName: vendorOrgname,
			phoneNo: vendorPhno,
			logoUrl: vendorLogo,
			landingPageDataStudioUrl: landingpageUrl,
			costDataStudioUrl: costpageUrl,
			usageDataStudioUrl: usagepageUrl,
			vmSubdomain: vendorFreemiumDomain,
			vmCloudBuildTrigger: cloudBuildTrigger,
			vmCloudBuildProjectLocation: cloudBuildProjectloc,
			vmCloudBuildRepoName: cloudBuildReponame,
			vmCloudBuildBranchName: cloudBuildBranchname,
			deleteVmCloudBuildTrigger: cloudBuildDeleteTrigger,
			deleteVmCloudBuildProjectLocation: cloudBuildDeleteProjectloc,
			deleteVmCloudBuildRepoName: cloudBuildDeleteReponame,
			deleteVmCloudBuildBranchName: cloudBuildDeleteBranchname,
			// updateVmCloudBuildTrigger: cloudBuildUpdateTrigger,
			// updateVmCloudBuildProjectLocation: cloudBuildUpdateProjectloc,
			// updateVmCloudBuildRepoName: cloudBuildUpdateReponame, //?
			// updateVmCloudBuildBranchName: cloudBuildUpdateBranchname //?
		};

		// console.log("data to send", dataToSend);

		// let isValidated = validateTenantData(dataToSend)

		updateDefaultVmVendor(dataToSend)
			.then((result) => {
				if (result.status === 200) {
					setErrorSB(false);
					setSuccessSB(true);
					dispatch(
						setDataStudioUrl({
							costDataStudioUrl: costpageUrl,
							usageDataStudioUrl: usagepageUrl,
							landingPageDataStudioUrl: landingpageUrl,
						})
					);
				}
			})
			.catch((error) => {
				console.log(error.response)
				setErrorMessage(error.response.data.message);
				setSuccessSB(false);
				setErrorSB(true);
				console.log(error);
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			});
	};

	const onSubmit = (data) => {
		// console.log("onsubmit", data);
		updateDefaultVendorData();
	};

	const closeSuccessSB = () => {
		setErrorSB(false);
		setSuccessSB(false);
		// navigate("/tenants");
	};
	const closeErrorSB = () => {
		setSuccessSB(false);
		setErrorSB(false);
	};

	return (
		<>
			<MDBox position="relative" mb={5}>
				{/* {successSB ? ( */}
				<MDSnackbar
					color="success"
					icon="check"
					title="SaaS Admin Portal"
					content={"Updated details successfully"}
					dateTime={`at ${getDateForSnackbar()}`}
					open={successSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}

					// bgWhite
				/>
				{/* ) : ( */}
				<MDSnackbar
					color="error"
					icon="warning"
					title="SaaS Admin Portal"
					content={errorMessage}
					dateTime={`at ${getDateForSnackbar()}`}
					open={errorSB}
					onClose={closeErrorSB}
					close={closeErrorSB}

					// bgWhite
				/>
				{/* )} */}
				<MDBox
					display="flex"
					alignItems="center"
					position="relative"
					minHeight="18.75rem"
					borderRadius="xl"
					sx={{
						backgroundImage: ({
							functions: { rgba, linearGradient },
							palette: { gradients },
						}) =>
							`${linearGradient(
								rgba(gradients.info.main, 0.6),
								rgba(gradients.info.state, 0.6)
							)}, url(${backgroundImage})`,
						backgroundSize: "cover",
						backgroundPosition: "50%",
						overflow: "hidden",
					}}
				/>
				<Card
					sx={{
						position: "relative",
						mt: -8,
						mx: 3,
						py: 2,
						px: 2,
					}}
				>
					<Grid container spacing={3} alignItems="center">
						<Grid item>
							<MDAvatar
								src={vendorLogo}
								alt="profile-image"
								size="xl"
								shadow="sm"
							/>
						</Grid>
						<Grid item>
							<MDBox height="100%" mt={0.5} lineHeight={1}>
								<MDTypography variant="h5" fontWeight="medium">
									{user?.name}
								</MDTypography>
								<MDTypography
									variant="button"
									color="text"
									fontWeight="regular"
								>
									{user?.email}
								</MDTypography>
							</MDBox>
						</Grid>
						<Grid item xs={19} md={12} lg={12} sx={{ ml: "auto" }}>
							<AppBar position="static">
								<Tabs
									orientation={tabsOrientation}
									value={tabValue}
									onChange={handleSetTabValue}
								>
									<Tab
										label="Vendor Settings"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												home
											</Icon>
										}
									/>
									<Tab
										label="Looker Settings"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												settings
											</Icon>
										}
									/>
									<Tab
										label="Cloud Build Settings"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												settings
											</Icon>
										}
									/>
									{/* <Tab
										label="Enterprise Settings"
										icon={
											<Icon
												fontSize="small"
												sx={{ mt: -0.25 }}
											>
												settings
											</Icon>
										}
									/> */}
								</Tabs>
							</AppBar>
						</Grid>
					</Grid>
					<TabPanel key={tabValue} value={tabValue} index={0}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox
								component="form"
								role="form"
								onSubmit={handleSubmit(onSubmit)}
							>
								<Grid container spacing={2}>
									<Grid item md={5} lg={7}>
										{/* <MDBox mb={2}> */}
										<TextField
											type="text"
											label="Name"
											variant="standard"
											fullWidth
											value={vendorName}
											onChange={(e) =>
												setVendorName(e.target.value)
											}
											// {...register("vendorName", {
											//   required: true,
											//   maxLength: 100,
											// })}
											// error={Boolean(errors.vendorName)}
											// helperText={errors.vendorName ? "Invalid vendor name" : ""}
										/>

										{/* </MDBox> */}
									</Grid>
									<Grid item md={5} lg={5}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Org Name"
												variant="standard"
												fullWidth
												id="accessValue"
												value={vendorOrgname}
												onChange={(e) =>
													setVendorOrgname(
														e.target.value
													)
												}
												// {...register("orgName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.orgName)}
												// helperText={errors.orgName ? "Invalid organization name" : ""}
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={7}>
										<MDBox mb={2}>
											<MDInput
												type="email"
												label="Email"
												variant="standard"
												fullWidth
												id="vendorEmail"
												value={vendorEmail}
												onChange={(e) =>
													setVendorEmail(
														e.target.value
													)
												}
												// {...register("vendorEmail", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.vendorEmail)}
												// helperText={
												//   errors.vendorEmail ? "Invalid vendor email" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={5}>
										<MDBox mb={2}>
											<MDInput
												type="number"
												label="Phone Number"
												variant="standard"
												fullWidth
												id="vendorPhoneNumber"
												value={vendorPhno}
												onChange={(e) =>
													setVendorPhno(
														e.target.value
													)
												}
												// {...register("vendorPhoneNumber", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.vendorPhoneNumber)}
												// helperText={
												//   errors.vendorPhoneNumber ? "Invalid phone number" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={12}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Organisation Logo URL"
												variant="standard"
												fullWidth
												value={vendorLogo}
												onChange={(e) =>
													setVendorLogo(
														e.target.value
													)
												}
												// {...register("orgLogoUrl", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.orgLogoUrl)}
												// helperText={errors.orgLogoUrl ? "Invalid logo URL" : ""}
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={12}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Sub-Domain"
												variant="standard"
												fullWidth
												value={vendorFreemiumDomain}
												onChange={(e) =>
													setVendorVmDomain(
														e.target.value
													)
												}
												// {...register("vendorFreemiumSubDomain", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.vendorFreemiumSubDomain)}
												// helperText={
												//   errors.vendorFreemiumSubDomain
												//     ? "Invalid freemium sub-domain"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
									{/* <Grid item md={4} lg={12}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Sub-Domain"
												variant="standard"
												fullWidth
												value={vendorEnterpriseDomain}
												onChange={(e) =>
													setVendorEnterpriseDomain(
														e.target.value
													)
												}
												// {...register("vendorEnterpriseSubDomain", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.vendorEnterpriseSubDomain)}
												// helperText={
												//   errors.vendorEnterpriseSubDomain
												//     ? "Invalid enterprise sub-domain"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid> */}
									{/* </Grid> */}
									{/* <Grid container spacing={3}> */}
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={!vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox component="form" role="form">
								<Grid container spacing={3}>
									{/* <Grid item md={4} lg={12}>
										<MDBox mb={2}> */}
									<Grid item md={12} lg={12}>
										<MDBox mb={12}>
											<MDInput
												type="text"
												label="Landing Page Loocker Studio URL"
												variant="standard"
												fullWidth
												value={landingpageUrl}
												onChange={(e) =>
													setLandingPageUrl(
														e.target.value
													)
												}
												// {...register("landingPageUrl", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.landingPageUrl)}
												// helperText={
												//   errors.landingPageUrl ? "Invalid photo URL" : ""
												// }
											/>
										</MDBox>
									</Grid>
									{/* <Grid item md={4} lg={12}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Cost Looker Studion URL"
												variant="standard"
												fullWidth
												value={costpageUrl}
												onChange={(e) =>
													setcostPageUrl(
														e.target.value
													)
												}
												// {...register("costPageUrl", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.costPageUrl)}
												// helperText={
												//   errors.costPageUrl ? "Invalid photo URL" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={4} lg={12}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Usage Looker Studio URL"
												variant="standard"
												fullWidth
												value={usagepageUrl}
												onChange={(e) =>
													setusagePageUrl(
														e.target.value
													)
												}
												// {...register("usagePageUrl", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.usagePageUrl)}
												// helperText={
												//   errors.usagePageUrl ? "Invalid photo URL" : ""
												// }
											/>
										</MDBox>
									</Grid> */}
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={!vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox component="form" role="form">
								<MDTypography
									variant="h3"
									mb={4}
									fontWeight="medium"
								>
									Create Cloud Build Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Create Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={cloudBuildTrigger}
												onChange={(e) =>
													setVmTrigger(
														e.target.value
													)
												}
												// {...register("freemiumTrigger", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumTrigger)}
												// helperText={
												//   errors.freemiumTrigger ? "Invalid cloud build trigger" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Create Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={cloudBuildProjectloc}
												onChange={(e) =>
													setVmProjectloc(
														e.target.value
													)
												}
												// {...register("freemiumProjectLoc", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumProjectLoc)}
												// helperText={
												//   errors.freemiumProjectLoc ? "Invalid location" : ""
												// }
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Create Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={cloudBuildReponame}
												onChange={(e) =>
													setVmReponame(
														e.target.value
													)
												}
												// {...register("freemiumRepoName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumRepoName)}
												// helperText={
												//   errors.freemiumRepoName ? "Invalid repository name" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Create Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={cloudBuildBranchname}
												onChange={(e) =>
													setVmBranchname(
														e.target.value
													)
												}
												// {...register("freemiumBranchName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumBranchName)}
												// helperText={
												//   errors.freemiumBranchName ? "Invalid branch name" : ""
												// }
											/>
										</MDBox>
									</Grid>
								</Grid>
								<MDTypography
									variant="h3"
									mb={4}
									mt={4}
									fontWeight="medium"
								>
									Delete Cloud Build Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={cloudBuildDeleteTrigger}
												onChange={(e) =>
													setVmDeleteTrigger(
														e.target.value
													)
												}
												// {...register("freemiumDeleteTrigger", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteTrigger)}
												// helperText={
												//   errors.freemiumDeleteTrigger ? "Invalid build trigger" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={cloudBuildDeleteProjectloc}
												onChange={(e) =>
													setVmDeleteProjectloc(
														e.target.value
													)
												}
												// {...register("freemiumDeleteProjectLoc", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteProjectLoc)}
												// helperText={
												//   errors.freemiumDeleteProjectLoc
												//     ? "Invalid project location"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={cloudBuildDeleteReponame}
												onChange={(e) =>
													setVmDeleteReponame(
														e.target.value
													)
												}
												// {...register("freemiumDeleteRepoName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteRepoName)}
												// helperText={
												//   errors.freemiumDeleteRepoName
												//     ? "Invalid repository name"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={cloudBuildDeleteBranchname}
												onChange={(e) =>
													setVmDeleteBranchname(
														e.target.value
													)
												}
												// {...register("freemiumDeleteBranchName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteBranchName)}
												// helperText={
												//   errors.freemiumDeleteBranchName
												//     ? "Invalid branch name"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
								</Grid>
								
								{/* <MDTypography
									variant="h3"
									mb={4}
									mt={4}
									fontWeight="medium"
								>
									Update Cloud Build Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Update Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={cloudBuildUpdateTrigger}
												onChange={(e) =>
													setVmUpdateTrigger(
														e.target.value
													)
												}
												// {...register("freemiumDeleteTrigger", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteTrigger)}
												// helperText={
												//   errors.freemiumDeleteTrigger ? "Invalid build trigger" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Update Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={cloudBuildUpdateProjectloc}
												onChange={(e) =>
													setVmUpdateProjectloc(
														e.target.value
													)
												}
												// {...register("freemiumDeleteProjectLoc", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteProjectLoc)}
												// helperText={
												//   errors.freemiumDeleteProjectLoc
												//     ? "Invalid project location"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Update Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={cloudBuildUpdateReponame}
												onChange={(e) =>
													setVmUpdateReponame(
														e.target.value
													)
												}
												// {...register("freemiumDeleteRepoName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteRepoName)}
												// helperText={
												//   errors.freemiumDeleteRepoName
												//     ? "Invalid repository name"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Update Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={cloudBuildUpdateBranchname}
												onChange={(e) =>
													setVmUpdateBranchname(
														e.target.value
													)
												}
												// {...register("freemiumDeleteBranchName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.freemiumDeleteBranchName)}
												// helperText={
												//   errors.freemiumDeleteBranchName
												//     ? "Invalid branch name"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
								</Grid> */}

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={!vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
					{/* <TabPanel value={tabValue} index={3}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox component="form" role="form">
								<MDTypography
									variant="h3"
									mb={4}
									fontWeight="medium"
								>
									Enterprise Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={enterpriseTrigger}
												onChange={(e) =>
													setenterpriseTrigger(
														e.target.value
													)
												}
												// {...register("enterpriseTrigger", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseTrigger)}
												// helperText={
												//   errors.enterpriseTrigger ? "Invalid build trigger" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={enterpriseProjectloc}
												onChange={(e) =>
													setenterpriseProjectloc(
														e.target.value
													)
												}
												// {...register("enterpriseProjectLoc", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseProjectLoc)}
												// helperText={
												//   errors.enterpriseProjectLoc ? "Invalid location" : ""
												// }
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={enterpriseReponame}
												onChange={(e) =>
													setenterpriseReponame(
														e.target.value
													)
												}
												// {...register("enterpriseRepoName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseRepoName)}
												// helperText={
												//   errors.enterpriseRepoName ? "Invalid repository name" : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Enterprise Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={enterpriseBranchname}
												onChange={(e) =>
													setenterpriseBranchname(
														e.target.value
													)
												}
												// {...register("enterpriseBranchName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseBranchName)}
												// helperText={
												//   errors.enterpriseBranchName ? "Invalid branch name" : ""
												// }
											/>
										</MDBox>
									</Grid>
								</Grid>
								<MDTypography
									variant="h3"
									mb={4}
									mt={4}
									fontWeight="medium"
								>
									Delete Enterprise Configurations
								</MDTypography>
								<Grid container spacing={5}>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Trigger"
												variant="standard"
												fullWidth
												value={enterpriseDeleteTrigger}
												onChange={(e) =>
													setenterpriseDeleteTrigger(
														e.target.value
													)
												}
												// {...register("enterpriseDeleteTrigger", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseDeleteTrigger)}
												// helperText={
												//   errors.enterpriseDeleteTrigger
												//     ? "Invalid trigger"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Project Location"
												variant="standard"
												fullWidth
												value={
													enterpriseDeleteProjectloc
												}
												onChange={(e) =>
													setenterpriseDeleteProjectloc(
														e.target.value
													)
												}
												// {...register("enterpriseDeleteProjectLoc", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseDeleteProjectLoc)}
												// helperText={
												//   errors.enterpriseDeleteProjectLoc
												//     ? "Invalid location"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>

									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Repo Name"
												variant="standard"
												fullWidth
												value={enterpriseDeleteReponame}
												onChange={(e) =>
													setenterpriseDeleteReponame(
														e.target.value
													)
												}
												// {...register("enterpriseDeleteRepoName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseDeleteRepoName)}
												// helperText={
												//   errors.enterpriseDeleteRepoName
												//     ? "Invalid repository name"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
									<Grid item md={5} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Delete Cloud Build Branch Name"
												variant="standard"
												fullWidth
												value={
													enterpriseDeleteBranchname
												}
												onChange={(e) =>
													setenterpriseDeleteBranchname(
														e.target.value
													)
												}
												// {...register("enterpriseDeleteBranchName", {
												//   required: true,
												//   maxLength: 100,
												// })}
												// error={Boolean(errors.enterpriseDeleteBranchName)}
												// helperText={
												//   errors.enterpriseDeleteBranchName
												//     ? "Invalid branch name"
												//     : ""
												// }
											/>
										</MDBox>
									</Grid>
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										//onClick={updateDefaultVendorData};
										onClick={handleSubmit(onSubmit)}
										disabled={!vendor.isAdmin}
									>
										Update Vendor Settings
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel> */}
				</Card>
			</MDBox>
		</>
	);
}

// Setting default props for the Header
Dashboard.defaultProps = {
	children: "",
};

// Typechecking props for the Header
Dashboard.propTypes = {
	children: PropTypes.node,
};

export default Dashboard;
