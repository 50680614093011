import Card from "@mui/material/Card";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MDDrop from "../../components/MDDropdown";
import MDSelect from "../../components/MDSelect";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RegexCode } from "../../config/validationRegex";
import { editAdmin } from "../functions/adminFormFunctions/index";
import MDSnackbar from "../../components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";

function EditAdminForm(props) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(false);
  // let isDisabled = false;

  let { state } = useLocation();
  // console.log("admin details",state.admin);

  const [access, setAccess] = useState(state.admin.isAdmin);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  // const [access, setAccess] = useState();

  const handleChange = (event) => {
    // console.log("event data", event.target.value);
    setAccess(event.target.value);
  };

  const handleButtonChange = (error) => {
    // console.log("changing", error);
    if (error) {
      // console.log("entering if");
      // setIsDisabled(true);
      // isDisabled = true;
    } else {
      // isDisabled = false;
      // setIsDisabled(false);
    }
  };

  const closeSuccessSB = () => {
    setSuccessSB(false);
    navigate("/admins");
  };
  const closeErrorSB = () => setErrorSB(false);

  const onSubmit = async (data) => {
    data["access"] = access;
    data.adminEmail = state.admin.email;
    // console.log("onsubmit edit admin data", data);
    const { status, message } = await editAdmin(data);
    // console.log("status edit admin", status);
    if (status === 200) {
      setSuccessSB(true);
    } else if (status === 500) {
		setErrorMsg(message);
      setErrorSB(true);
    } else if (status === 403) {
      console.log("Unauthorised: Logging Out");
      dispatch(logout());
      localStorage.removeItem("persist:root");
      localStorage.clear();
      window.location.href = "/sign-in";
    } else {
		setErrorMsg(message);
      setErrorSB(true);
    }
  };
  // console.log("Access", access);

  return (
    <>
      {successSB ? (
        <MDSnackbar
          color="success"
          icon="check"
          title="SaaS Admin Portal"
          content={`Edited details for ${
            access === true ? `admin` : `normal`
          } user successfully`}
          // dateTime="11 mins ago"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}

          // bgWhite
        />
      ) : (
        <MDSnackbar
          color="error"
          icon="warning"
          title="SaaS Admin Portal"
          content={
            errorMsg == ""
              ? `An error occured while editing ${
                  access === true ? `admin` : `normal`
                } user`
              : `${errorMsg}`
          }
          // dateTime="11 mins ago"
          open={errorSB}
          onClose={closeErrorSB}
          close={closeErrorSB}
          // bgWhite
        />
      )}
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Edit Admin
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter the following information to update Admin details
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                      id="adminName"
                      defaultValue={state.admin.name}
                      {...register("adminName", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        pattern: {
                          value: !RegexCode.orgNameRegex,
                          message: "Special Characters are not allowed",
                        },
                        maxLength: {
                          value: 20,
                          message: "Maximum 20 allowed characters",
                        },
                      })}
                      error={Boolean(errors.adminName)}
                      helperText={
                        errors.adminName ? errors.adminName.message : ""
                      }
                      // onChange={handleButtonChange(Boolean(errors.adminName))}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <MDBox mb={2}> */}
                  <MDDrop
                    label="Name"
                    variant="standard"
                    fullWidth
                    id="tenantId"
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel>Access</InputLabel>
                    <MDSelect
                      variant="standard"
                      fullWidth
                      id="adminAccess"
                      value={access}
                      label="Access"
                      onChange={handleChange}
                      required
                      sx={{ height: "30px" }}
                    >
                      <MenuItem value={true}>Admin User</MenuItem>
                      <MenuItem value={false}>Normal User</MenuItem>
                    </MDSelect>
                  </MDDrop>
                  {/* </MDBox> */}
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="E-mail"
                      variant="standard"
                      fullWidth
                      id="adminEmail"
                      defaultValue={state.admin.email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Organization name"
                      variant="standard"
                      fullWidth
                      id="adminOrgName"
                      defaultValue={state.admin.orgName}
                      {...register("organizationName", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        pattern: {
                          value: !RegexCode.orgNameRegex,
                          message: "Special Characters are not allowed",
                        },
                        maxLength: {
                          value: 20,
                          message: "Maximum 20 allowed characters",
                        },
                      })}
                      error={Boolean(errors.organizationName)}
                      helperText={
                        errors.organizationName
                          ? errors.organizationName.message
                          : ""
                      }
                    />
                  </MDBox>
                </Grid>
              </Grid>

              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  disabled={isDisabled}
                >
                  update
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
}

export default EditAdminForm;
