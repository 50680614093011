// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDSnackbar from "../../components/MDSnackbar";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import DataTable from "../../examples/Tables/DataTable";
import { Link, useNavigate } from "react-router-dom";

// Data
import authorsTableData from "./data/authorsTableData";

import { useEffect, useState } from "react";
import getDateForSnackbar from "../../helperFunctions/getDate";

function IdentityProviders() {
	const deleteStatusForProvider = (status) => {
		setDeleteStatus(status);
	};
	const checkIsDataPresent = (status) => {
		setIsDataPresent(status);
	};

	const navigate = useNavigate();
	const { columns, rows } = authorsTableData(
		deleteStatusForProvider,
		checkIsDataPresent
	);
	// console.log(columns, rows);
	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState("");
	const [isDataPresent, setIsDataPresent] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	// const [getDate, setGetDate] = useState("");
	const closeSuccessSB = () => setSuccessSB(false);
	const closeErrorSB = () => setErrorSB(false);
	useEffect(() => {
		// let d = new Date();
		// let hour = d.getHours();
		// let minutes = d.getMinutes();
		if (isDataPresent === "false") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setErrorMessage(
				"Error while loading identity-providers,please try later"
			);
			setErrorSB(true);
			setIsDataPresent("");
		}
		if (deleteStatus === "deleted") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setSuccessSB(true);
			setDeleteStatus("");
			window.location.href = "/sign-in";
		} else if (deleteStatus === "notDeleted") {
			// setGetDate(`at ${hour} : ${minutes}`);
			setErrorMessage("Error while deleting identity-provider");
			setErrorSB(true);
			setDeleteStatus("");
		}
	}, [deleteStatus]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{/* {successSB ? ( */}
			<MDSnackbar
				color="success"
				icon="check"
				title="SaaS Admin Portal"
				content={"Deleted admin successfully"}
				dateTime={`at ${getDateForSnackbar()}`}
				open={successSB}
				onClose={closeSuccessSB}
				close={closeSuccessSB}

				// bgWhite
			/>
			{/* ) : ( */}
			<MDSnackbar
				color="error"
				icon="warning"
				title="SaaS Admin Portal"
				content={errorMessage}
				dateTime={`at ${getDateForSnackbar()}`}
				open={errorSB}
				onClose={closeErrorSB}
				close={closeErrorSB}

				// bgWhite
			/>
			{/* )} */}
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								pt={2}
								px={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mx={2}
								mt={-3}
								py={3}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
							>
								<MDTypography variant="h6" color="white">
									Identity Providers
								</MDTypography>
								<MDButton
									variant="gradient"
									color="dark"
									component={Link}
									to={"/add-identity-provider"}
								>
									<Icon sx={{ fontWeight: "bold" }}>add</Icon>
									&nbsp;add new Identity Provider
								</MDButton>
							</MDBox>
							<MDBox pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default IdentityProviders;
