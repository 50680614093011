import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// redux library
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist";

import { MaterialUIControllerProvider } from "./context";

let persistor = persistStore(store)

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<BrowserRouter>
				<MaterialUIControllerProvider>
					<App />
				</MaterialUIControllerProvider>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	
	document.getElementById("root")
);
