// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

// Authentication layout components
// import CoverLayout from "../../authentication/components/CoverLayout";
import CoverLayoutForForms from "../../layouts/authentication/components/CoverLayout copy";

// Images
import bgImage from "../../assets/images/bg-sign-up-cover.jpeg";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
const alphanumericNo = Math.random().toString(36).substr(2, 6);
function SelfOnboarding() {
	const [status, setStatus] = useState("");

	const user = useSelector(selectUser);
	const tenantId = alphanumericNo;

	return (
		<CoverLayoutForForms image={bgImage} coverWidth={6}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="success"
					mx={2}
					mt={-3}
					p={3}
					mb={1}
					textAlign="center"
				>
					<MDTypography
						variant="h4"
						fontWeight="medium"
						color="white"
						mt={1}
					>
						Self-Onboarding
					</MDTypography>
					<MDTypography
						display="block"
						variant="button"
						color="white"
						my={1}
					>
						Enter the following information to register yourself
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={2}>
							<Grid item md={8} lg={8}>
								<MDBox mb={2}>
									<MDInput
										type="text"
										label="Tenant ID"
										variant="standard"
										fullWidth
										defaultValue={tenantId}
									/>
								</MDBox>
							</Grid>
							<Grid item md={4} lg={4}>
								<MDBox mb={2}>
									<MDInput
										type="text"
										label="Tier"
										variant="standard"
										fullWidth
									/>
								</MDBox>
							</Grid>
						</Grid>
						<MDBox mb={2}>
							<MDInput
								type="text"
								label="Organization Name"
								variant="standard"
								fullWidth
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								type="email"
								label="Admin E-mail"
								variant="standard"
								fullWidth
							/>
						</MDBox>

						<MDBox mb={2}>
							<MDInput
								type="text"
								label="Organization Logo"
								variant="standard"
								fullWidth
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								type="text"
								label="Domain"
								variant="standard"
								fullWidth
							/>
						</MDBox>

						{/* <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
              />
            </MDBox> */}
						{/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  ml: -1,
                }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}

						<MDBox mt={4} mb={1}>
							<MDButton variant="gradient" color="info" fullWidth>
								Onboard
							</MDButton>
						</MDBox>

						{/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox> */}
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayoutForForms>
	);
}

export default SelfOnboarding;
