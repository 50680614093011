/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// MUI components
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

import DeleteModal from "../../../components/MDModal";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useMaterialUIController } from "../../../context";

// Images
import team6 from "../../../assets/images/team-6.png";

//axios
import { getIdentityProviders } from "../../../apis/tenant";
import { deleteIdenityProvider } from "../../../apis/tenant";

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectTenant, logout } from "../../../redux/userSlice";

//helping functions
import subStr from "../../../helperFunctions/subString";

export default function data(deleteStatusForProvider, checkIsDataPresent) {
	const user = useSelector(selectTenant);
	const dispatch = useDispatch();
	// const data = [
	//   {
	//     providerId: "oidc.first",
	//     clientId: "NBhTYlFQGla9YT8vRPGfOYP0tnxIBjZy",
	//     issuer: "https://dev-fns8t1tdb1s8dibg.us.auth0.com/",
	//     enabled: false,
	//     displayName: "first",
	//     responseType: { idToken: true },
	//   },
	//   {
	//     providerId: "oidc.second",
	//     clientId: "UaYp5UyzS8wIK4Q7MKJPTFKs5wfe8zYZ",
	//     issuer: "https://dev-fns8t1tdb1s8dibg.us.auth0.com",
	//     enabled: true,
	//     displayName: "second",
	//     responseType: { idToken: true },
	//   },
	//   {
	//     providerId: "Google",
	//     clientId: "Google",
	//     issuer: "Google",
	//     enabled: true,
	//     displayName: "Third",
	//     responseType: { idToken: true },
	//   },
	//   {
	//     subdomain: "yashkasubdomain",
	//     type: true,
	//     displayName: "yash",
	//     enabled: true,
	//     providerId: "saml.yash",
	//     idpEntityId: "yashid",
	//     ssoURL: "0394u73048u3dfb",
	//     rpEntityId:"yashreentity",
	//     callbackURL:"yashcallbacl",
	//     x509Certificates:"xyz",
	//   },
	// ];

	const [deleteemail, setDeleteEmail] = useState();
	const [deleteProvider, setDeleteProvider] = useState();

	const [open, setOpen] = useState(false);
	const [identityProviders, setIdentityProviders] = useState([]);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	const handleConfirmDelete = async (providerId) => {
		console.log("confirmed delete Provider ID:", providerId);

		try {
			let res = await deleteIdenityProvider(user.subdomain, providerId);
			if (res.status === 200) {
				deleteStatusForProvider("deleted");
			}
		} catch (error) {
			console.log("error in delete admin", error);
			deleteStatusForProvider("notDeleted");
			if (error.response.status === 403) {
				console.log("Unauthorised: Logging Out");
				dispatch(logout());
				localStorage.removeItem("persist:root");
				localStorage.clear();
				window.location.href = "/sign-in";
			}
		}
		setOpen(false);
	};

	const getData = async () => {
		const response = await getIdentityProviders(user.subdomain)
			.then((response) => {
				if (response.status === 200) {
					// console.log(response.data);
					setIdentityProviders(response.data.providerConfigs);
				}
			})
			.catch((error) => {
				console.log(error);
				// alert(
				//   `Oops, an error occured while loading the page. Please try again after some time.`
				// );
				checkIsDataPresent("false");
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
			});
	};

	useEffect(() => {
		const fetchData = async () => {
			await getData();
		};
		fetchData();
	}, []);

	const Job = ({ title, description }) => (
		<MDBox lineHeight={1} textAlign="left">
			<MDTypography
				display="block"
				variant="caption"
				color="text"
				fontWeight="medium"
			>
				{title}
			</MDTypography>
			<MDTypography variant="caption">{description}</MDTypography>
		</MDBox>
	);

	return {
		columns: [
			{
				Header: "Display Name",
				accessor: "author",

				align: "left",
			},
			{ Header: "Provider Id", accessor: "function", align: "left" },
			{ Header: "enabled", accessor: "status", align: "center" },
			{
				Header: "Update Provider",
				accessor: "employed",
				align: "center",
			},
			{ Header: "Delete Provider", accessor: "action", align: "center" },
		],

		rows: identityProviders.map((data) => ({
			author: (
				<MDTypography
					variant="button"
					fontWeight="regular"
					color={darkMode ? "white" : "dark"}
				>
					{data.displayName}
				</MDTypography>
			),
			function: <Job title={data.providerId} description="" />,
			status: (
				<Link href={data.enabled} target="_blank">
					<MDTypography
						variant="button"
						fontWeight="regular"
						color={darkMode ? "white" : "dark"}
					>
						{data.enabled ? "True" : "False"}
					</MDTypography>
				</Link>
			),
			employed: (
				<MDButton
					component={RouterLink}
					to={
						subStr(data.providerId, ".", "a") === "saml"
							? "/edit-identity-providers-saml"
							: "/edit-identity-providers"
					}
					state={{ data }}
					variant="text"
					color={darkMode ? "white" : "dark"}
				>
					<Icon>edit</Icon>&nbsp;edit
				</MDButton>
			),
			action: (
				<MDBox mr={1}>
					<MDButton
						variant="text"
						color="error"
						onClick={() => {
							setOpen(true);
							setDeleteProvider(data.providerId);
						}}
					>
						<Icon>delete</Icon>&nbsp;delete
					</MDButton>
					<DeleteModal
						deleting="identity-provider"
						open={open}
						onClose={() => setOpen(false)}
						onDelete={() => handleConfirmDelete(deleteProvider)}
					/>
				</MDBox>
			),
		})),
	};
}
