// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "../../../../components/MDBox";
// import MDTypography from "components/MDTypography";

import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import PageLayout from "../../../../examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "../../../../layouts/authentication/components/Footer";

function CoverLayoutForForms({ coverHeight, image, children, coverWidth }) {
  return (
    <PageLayout>
      {/* <DefaultNavbar
				action={{
					type: "external",
					route: "https://creative-tim.com/product/material-dashboard-react",
					label: "free download",
				}}
				transparent
				light
			/> */}
      <MDBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={11} sm={9} md={8} lg={3} xl={coverWidth}>
          <MDBox
            mt={{ xs: -20, lg: -27 }}
            px={1}
            width="calc(100% - 2rem)"
            mx="auto"
          >
            {children}
          </MDBox>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </PageLayout>
  );
}

// Setting default props for the CoverLayout
CoverLayoutForForms.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the CoverLayout
CoverLayoutForForms.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayoutForForms;
