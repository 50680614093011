import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import MDBox from "./components/MDBox";

import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";

import themeDark from "./assets/theme-dark";
import themeDarkRTL from "./assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// import tenantRoutes from "./tenantRoutes";
import { tenantRoutes, FreemiumTenantRoutes } from "./tenantRoutes";
import { vendorAdminRoutes, vendorUserRoutes } from "./routes";
import { vmVendorUserRoutes, vmVendorAdminRoutes } from "./vmRoutes";
import { useSelector } from "react-redux";
import { selectUser } from "../www/redux/userSlice";
import { selectTenant } from "../www/redux/userSlice";
import { selectVendor } from "../www/redux/userSlice";

import {
	useMaterialUIController,
	setMiniSidenav,
	setOpenConfigurator,
} from "./context";

// Images
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logo-ct-dark.png";

export default function App() {
	const user = useSelector(selectUser);
	const tenant = useSelector(selectTenant);
	const vendor = useSelector(selectVendor);

	const [controller, dispatch] = useMaterialUIController();
	const {
		miniSidenav,
		direction,
		layout,
		openConfigurator,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);

	const { pathname } = useLocation();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Change the openConfigurator state
	const handleConfiguratorOpen = () =>
		setOpenConfigurator(dispatch, !openConfigurator);

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				return (
					<Route
						exact
						path={route.route}
						element={route.component}
						key={route.key}
					/>
				);
			}

			return null;
		});

	const configsButton = (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.25rem"
			height="3.25rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={99}
			color="dark"
			sx={{ cursor: "pointer" }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize="small" color="inherit">
				settings
			</Icon>
		</MDBox>
	);

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			{layout === "dashboard" && (
				<>
					<Sidenav
						color={sidenavColor}
						// brand={
						// 	(transparentSidenav && !darkMode) || whiteSidenav
						// 		? brandDark
						// 		: brandWhite
						// }
						brandName={
							process.env.REACT_APP_TENENT_ENV == "VM"
								? "SaaS Admin Portal"
								: user?.userType === "tenant"
								? "SaaS Tenant Portal"
								: "SaaS Admin Portal"
						}
						routes={
							process.env.REACT_APP_TENENT_ENV == "VM"
								? user?.userType === "tenant"
									? tenant?.tier === "enterprise"
										? tenantRoutes
										: FreemiumTenantRoutes
									: vendor?.isAdmin === true
									? vmVendorAdminRoutes
									: vmVendorUserRoutes
								: user?.userType === "tenant"
								? tenant?.tier === "enterprise"
									? tenantRoutes
									: FreemiumTenantRoutes
								: vendor?.isAdmin === true
								? vendorAdminRoutes
								: vendorUserRoutes
						}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
					{/* <Configurator /> */}
					{/* {configsButton} */}
				</>
			)}
			{layout === "vr" && <Configurator />}
			<Routes>
				{getRoutes(
					process.env.REACT_APP_TENENT_ENV == "VM"
						? user?.userType === "tenant"
							? tenant?.tier === "enterprise"
								? tenantRoutes
								: FreemiumTenantRoutes
							: vendor?.isAdmin === true
							? vmVendorAdminRoutes
							: vmVendorUserRoutes
						: user?.userType === "tenant"
						? tenant?.tier === "enterprise"
							? tenantRoutes
							: FreemiumTenantRoutes
						: vendor?.isAdmin === true
						? vendorAdminRoutes
						: vendorUserRoutes
				)}
			</Routes>
		</ThemeProvider>
	);
}
