/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// MUI components
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import DeleteModal from "../../../components/MDModal";
import { Link as RouterLink } from "react-router-dom";

// Images
import team6 from "../../../assets/images/team-6.png";

import React, { useState, useEffect } from "react";
import { getAllAdmin } from "../../../apis/admin";
import { useMaterialUIController } from "../../../context";
import { deleteAdmin } from "../../../apis/admin";
import { useSelector, useDispatch } from "react-redux";
import { selectTenant, logout } from "../../../redux/userSlice";
import {
  getCurrentNodeCount,
  listTenantUser,
  updateCurrentNodeCount,
} from "../../../apis/tenant";
import { deleteTenantUser } from "../../../apis/tenant";
import UpdateModal from "../../../components/MDModal/updateModal";

export default function data(
  deleteStatusForAdmin,
  checkIsDataPresent,
  updateStatus
) {
  const [deleteemail, setDeleteEmail] = useState();
  const [updateemail, setupdateeEmail] = useState();
  const [subdomain, setSubDomain] = useState();
  const [open, setOpen] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [tenantUser, setTenantUser] = useState([]);
  const [controller] = useMaterialUIController();
  const [nodeCount, setNodeCount] = useState(0);
  const [prevNodeCount, setPrevNodeCount] = useState(0);

  const { darkMode } = controller;
  const dispatch = useDispatch();
  const tenant = useSelector(selectTenant);

  const handleConfirmDelete = async (email) => {
    // console.log("confirmed delete", email);
    try {
      const payload = {
        email: email,
        subdomain: tenant.subdomain,
      };
      let res = await deleteTenantUser(payload);
      if (res.status === 200) {
        deleteStatusForAdmin("deleted");
      }
    } catch (error) {
      console.log("error in delete Tenant User", error);
      deleteStatusForAdmin("notDeleted");
      if (error.response.status === 403) {
        console.log("Unauthorised: Logging Out");
        dispatch(logout());
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = "/sign-in";
      }
    }
    // deleteAdmin({ email })
    //   .then((result) => {
    //     if (result.status === 200) {
    //       console.log("Deleted Successfully");
    //       window.location.href = "/sign-in";;
    //     } else {
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    setOpen(false);
  };

  const handleConfirmUpdate = async (email) => {
    try {
      const payload = {
        email: email,
        subdomain: tenant.subdomain,
        nodeCount: nodeCount,
      };
      let res = await updateCurrentNodeCount(payload);
      if (res.status === 200) {
        updateStatus("updated");
      }
    } catch (error) {
      console.log("error in updating settings for Tenant User", error);
      updateStatus("notUpdated");
      if (error.response.status === 403) {
        console.log("Unauthorised: Logging Out");
        dispatch(logout());
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = "/sign-in";
      } else {
        setTimeout(() => {
          updateStatus("notUpdated");
          window.location.href = "/sign-in";
        }, 1200);
      }
    }
    setOpenUpdateModal(false);
  };

  useEffect(() => {
    listTenantUser(tenant.subdomain)
      .then((response) => {
        if (response.status === 200) {
          setTenantUser(response.data.tenantUsers);
        }
      })
      .catch((error) => {
        checkIsDataPresent("false");
        if (error.response.status === 403) {
          console.log("Unauthorised: Logging Out");
          dispatch(logout());
          localStorage.removeItem("persist:root");
          localStorage.clear();
          window.location.href = "/sign-in";
        }
      });
  }, []);

  useEffect(() => {
    const payload = {
      email: updateemail,
      subdomain: subdomain,
    };

    openUpdateModal
      ? getCurrentNodeCount(payload)
          .then((res) => {
            setNodeCount(res.data.count);
            setPrevNodeCount(res.data.count);
          })
          .catch((error) => {
            console.log(error);
          })
      : null;
  }, [openUpdateModal]);

  // useEffect(()=>{},[nodeCount])

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="md" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  if(process.env.REACT_APP_TENENT_ENV!=="VM")
  return {
    columns: [
      {
        Header: "Subdomain Name",
        accessor: "author",
        width: "45%",
        align: "left",
      },
      { Header: "User Type", accessor: "function", align: "left" },
      { Header: "Email", accessor: "status", align: "left" },
      // { Header: "Update Admin", accessor: "employed", align: "center" },
      { Header: "Update GKE Settings", accessor: "update", align: "center" },
      { Header: "Delete User", accessor: "action", align: "center" },
    ],

    rows: tenantUser.map((tenantUser) => ({
      author: <Author image={team6} name={tenantUser.name} />,
      function: (
        <Job
          title={tenantUser.isAdmin ? "Admin User" : "Normal User"}
          description=""
        />
      ),
      status: (
        <Link href={tenantUser.email} target="_blank">
          <MDTypography
            variant="button"
            fontWeight="regular"
            color={darkMode ? "white" : "dark"}
          >
            {tenantUser.email}
          </MDTypography>
        </Link>
      ),
      employed: (
        <MDButton
          component={RouterLink}
          to="/edit-admin"
          state={{ tenantUser }}
          variant="text"
          color={darkMode ? "white" : "dark"}
        >
          <Icon>edit</Icon>&nbsp;edit
        </MDButton>
      ),
      action: (
        <MDBox mr={1}>
          <MDButton
            variant="text"
            color="error"
            onClick={() => {
              setOpen(true);
              setDeleteEmail(tenantUser.email);
            }}
          >
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
          <DeleteModal
            deleting="tenant-user"
            open={open}
            onClose={() => setOpen(false)}
            onDelete={() => handleConfirmDelete(deleteemail)}
          />
        </MDBox>
      ),
      update: (
        <MDBox mr={1}>
          <MDButton
            variant="text"
            color="error"
            onClick={() => {
              setOpenUpdateModal(true);
              setSubDomain(tenantUser.subdomain);
              setupdateeEmail(tenantUser.email);
            }}
          >
            <Icon>settings</Icon>&nbsp;Update
          </MDButton>
          <UpdateModal
            updating="tenant-user"
            open={openUpdateModal}
            onClose={() => {
              setOpenUpdateModal(false);
            }}
            onUpdate={() => handleConfirmUpdate(updateemail)}
            tenant={tenantUser}
            nodeCount={nodeCount}
            setNodeCount={setNodeCount}
            prevNodeCount={prevNodeCount}
          />
        </MDBox>
      ),
    })),
  };

  else return {
    columns: [
      {
        Header: "Subdomain Name",
        accessor: "author",
        width: "45%",
        align: "left",
      },
      { Header: "User Type", accessor: "function", align: "left" },
      { Header: "Email", accessor: "status", align: "left" },
      // { Header: "Update Admin", accessor: "employed", align: "center" },
      { Header: "Delete User", accessor: "action", align: "center" },
    ],

    rows: tenantUser.map((tenantUser) => ({
      author: <Author image={team6} name={tenantUser.name} />,
      function: (
        <Job
          title={tenantUser.isAdmin ? "Admin User" : "Normal User"}
          description=""
        />
      ),
      status: (
        <Link href={tenantUser.email} target="_blank">
          <MDTypography
            variant="button"
            fontWeight="regular"
            color={darkMode ? "white" : "dark"}
          >
            {tenantUser.email}
          </MDTypography>
        </Link>
      ),
      employed: (
        <MDButton
          component={RouterLink}
          to="/edit-admin"
          state={{ tenantUser }}
          variant="text"
          color={darkMode ? "white" : "dark"}
        >
          <Icon>edit</Icon>&nbsp;edit
        </MDButton>
      ),
      action: (
        <MDBox mr={1}>
          <MDButton
            variant="text"
            color="error"
            onClick={() => {
              setOpen(true);
              setDeleteEmail(tenantUser.email);
            }}
          >
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
          <DeleteModal
            deleting="tenant-user"
            open={open}
            onClose={() => setOpen(false)}
            onDelete={() => handleConfirmDelete(deleteemail)}
          />
        </MDBox>
      )
    })),
  };

}
