// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

// import styled from "@emotion/styled";
import { styled } from "@mui/material/styles";

import validator from "validator";

// Images
import bgImage from "../../assets/images/bg-sign-up-cover.jpeg";
import {
	Grid,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Tooltip,
	tooltipClasses,
	Typography,
} from "@mui/material";

// import { addTenant } from "../../apis/tenant/index";
import { Tenant } from "../../schemas";
import { useForm } from "react-hook-form";
import { useState } from "react";
import MDDrop from "../../components/MDDropdown";

import { useSelector, useDispatch } from "react-redux";
import { selectVendor, logout } from "../../redux/userSlice";
import { RegexCode } from "../../config/validationRegex";
import { addTenant } from "../functions/tenantFormFunctions/index";
import MDSnackbar from "../../components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import {
	regions as regionTest,
	zones as zoneTest,
} from "../../config/gcpZoneAndRegions";
import { getGPUcount, getGPUTypes, getRegions } from "../../apis/tenant";
import { getZones } from "../../apis/tenant";
import { useEffect } from "react";

const alphanumericNo = Math.random().toString(36).substr(2, 6);

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 520,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}));

function AddNewTenantForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm({ mode: "onChange" });

	// const [subnetSize, setSubnetSize] = useState("");
	const [tier, setTier] = useState("freemium");
	const [regions, setRegions] = useState([]);
	const [zones, setZones] = useState([]);
	const [region, setRegion] = useState("");
	const [zone, setZone] = useState("");
	const [machineType, setMachineType]=useState(null)
	const freemiumProjectId = process.env.REACT_APP_FREEMIUM_PROJECT_ID;
	const vendor = useSelector(selectVendor);
	const freemiumSubdomain = vendor?.freemiumSubdomain;
	const enterpriseSubdomain = vendor?.enterpriseSubdomain;
	const [mysubDomain, setSubDomain] = useState("");
	const [projectId, setProjectId] = useState("");
	const [bqDatasetId, setbqDatasetId] = useState("");
	const [domain, setDomain] = useState("");
	const [infoSB, setInfoSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isGPUEnabled, setIsGPUEnabled]= useState(false)
	const [gpuList, setGpuList] = useState([])
	const [gpuType, setGpuType] = useState("")
	const [gpuCountList, setCountGpuList] = useState([])
	const [gpuCount, setGpuCount] = useState(null)

	const handleChange = (event) => {
		setTier(event.target.value);

		//setting the domain on change of tier along with tenantid input
		if (mysubDomain !== "") {
			if (event.target.value === "freemium") {
				setDomain(mysubDomain + "." + freemiumSubdomain);
			} else {
				setDomain(mysubDomain + "." + enterpriseSubdomain);
				setProjectId(mysubDomain + "-" + alphanumericNo);
			}
			setbqDatasetId(mysubDomain + "_saas_dsid");
		} else {
			setProjectId("");
			setbqDatasetId("");
			setDomain("");
		}
	};

	const updateZone = (event) => {
		setZone(event.target.value);
	};

	const updateRegion = (event) => {
		setRegion(event.target.value);
	};

	const autofillBasedOnSubdomain = (e) => {
		var text = e.target.value;
		setSubDomain(text);
		if (text.length !== 0) {
			if (tier === "freemium") {
				setDomain(text + "." + freemiumSubdomain);
			} else {
				setDomain(text + "." + enterpriseSubdomain);
			}
			if (tier === "enterprise") {
				setProjectId(text + "-" + alphanumericNo);
			}
			setbqDatasetId(text + "_saas_dsid");
		} else {
			setProjectId("");
			setbqDatasetId("");
			setDomain("");
		}
	};

	useEffect(() => {
		// setRegions(regionTest);
		getRegions()
		.then((response) => {
			if (response.status === 200) {
				// console.log("regions", response.data);
				setRegions(response.data);
			}
		})
		.catch((error) => {
			// alert(`Oops, an error occured while fetching regions`);
			if (error.response.status === 403) {
				console.log("Unauthorised: Logging Out");
				dispatch(logout());
				localStorage.removeItem("persist:root");
				localStorage.clear();
				window.location.href = "/sign-in";
			}
			console.log(error);
		});
	}, []);

	useEffect(() => {
		getZones(region)
			.then((response) => {
				if (response.status === 200) {
					// console.log("zones", response.data);
					setZones(response.data);
				}
			})
			.catch((error) => {
				// alert(`Oops, an error occured while fetching zones`);
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
				console.log(error);
			});
	}, [region]);

	useEffect(()=>{},[gpuList,gpuCount])

	const filteredZone = () => {
		return (
			<>
				<Select
					// variant= "standard"
					// fullWidth
					id="zone"
					value={zone}
					label="zone"
					onChange={updateZone}
					MenuProps={{
						PaperProps: {
							style: {
								maxHeight: "300px",
								width: "auto",
							},
						},
					}}
					sx={{ height: "30px" }}

					// {...register("tier",{required: true})}
					// error={Boolean(errors.tier)}
				>
					{zones.locations ? (
						zones.locations.map(
							(location, index) =>
								location.includes(region) && (
									<MenuItem key={index} value={location}>
										{location}
									</MenuItem>
								)
						)
					) : (
						<MenuItem>
							Failed to load zones, please refresh the page.
						</MenuItem>
					)}
				</Select>
			</>
		);
	};

	const getGPUTypesForMachineType = async(machineTypes) => {
		isGPUEnabled
		? getGPUTypes(machineTypes)
		.then(res=>{
			if(res.data.gpuList.length===0)
			{	
				setErrorSB(true);
				setErrorMessage(`Machine ${machineType} has no support for GPU `);
			}
			setGpuList(res.data.gpuList)
		})
		.catch(error=>{
			setErrorSB(true);
			setErrorMessage("Failed To Fetch GPU Type");
		})
		: null

	}

	const getGPUCount = async(gpuType, machineType) => {
		gpuType?
		getGPUcount(gpuType, machineType)
		.then(response=>{
			if(response.data.gpuCount.length==0)
			{
				setErrorSB(true);
				setErrorMessage("Failed To GPU Count");
			}
			setCountGpuList(response.data.gpuCount)
		})
		.catch(error=>{
			setErrorSB(true);
			setErrorMessage("Failed To Fetch GPU Count");
		})
		:null	
	}

	const clearGUPStates= ()=> {
		setCountGpuList([])
		setGpuList([])
		setGpuType('')
		setGpuCount(0)
	}

// useEffect(()=>{
// 	clearGUPStates()
// 	const debounce = setTimeout(()=>{
// 		if (machineType)
// 		getGPUTypesForMachineType(machineType)
// 	},1000);
// 	return () => clearTimeout(debounce)
// },[machineType])


useEffect(()=>{
	if(isGPUEnabled && machineType)
	{
		getGPUTypesForMachineType(machineType)
	}
	if(!isGPUEnabled) {
		clearGUPStates()
	}
},[isGPUEnabled])

	const closeInfoSB = () => {
		setInfoSB(false);
		navigate("/tenant-onboard-status?subdomain=" + data.tenantId);
	};
	const closeErrorSB = () => setErrorSB(false);

	const onSubmit = async (data) => {
		data.tenantId = mysubDomain;
		data.tierval = tier;
		data.region = region;
		data.zone = zone;
		data.domain = domain;
		data.isGPUEnabled = isGPUEnabled;
		data.gpuType =  isGPUEnabled ? gpuType : null;
		data.gpuCount = isGPUEnabled ? gpuCount : 0;
		// data.subnetSize = subnetSize;
		data.bqDatasetId = bqDatasetId;
		if (data.tierval === "freemium") {
			data.projectId = freemiumProjectId;
		} else {
			data.projectId = projectId;
		}
		data.emailVerified = false;
		// console.log("data", data);

		const res = await addTenant(data);
		if (res.status == 200) {
			setInfoSB(true);
			navigate("/tenant-onboard-status?subdomain=" + data.tenantId);
		} else {
			setErrorSB(true);
			setErrorMessage(res.data.message);
		}

		// onboardTenant();
	};

	return (
		<>
			<MDSnackbar
				icon="notifications"
				title="SaaS Admin Portal"
				content="You'll be redirected to tenant onboarding status page"
				// dateTime="11 mins ago"
				open={infoSB}
				onClose={closeInfoSB}
				close={closeInfoSB}
			/>
			<MDSnackbar
				color="error"
				icon="warning"
				title="SaaS Admin Portal"
				content={errorMessage}
				// dateTime="11 mins ago"
				open={errorSB}
				onClose={closeErrorSB}
				close={closeErrorSB}

				// bgWhite
			/>
			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox
						variant="gradient"
						bgColor="info"
						borderRadius="lg"
						coloredShadow="success"
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign="center"
					>
						<MDTypography
							variant="h4"
							fontWeight="medium"
							color="white"
							mt={1}
						>
							Add Tenant
						</MDTypography>
						<MDTypography
							display="block"
							variant="button"
							color="white"
							my={1}
						>
							Add the following information of a Tenant
						</MDTypography>
					</MDBox>
					<MDBox pt={4} pb={3} px={3}>
						<MDBox
							component="form"
							role="form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Tenant ID"
											variant="standard"
											fullWidth
											// onChange={(e) =>
											// 	autofillBasedOnSubdomain(e)
											// }
											{...register("tenantId", {
												required: "Required",
												maxLength: {
													value: 6,
													message:
														"Please enter less than 6 characters",
												},
												pattern: {
													value: RegexCode.tenantIdRegex,
													message:
														"White Space, Capital and Special Characters are not allowed",
												},
												onChange: (e) =>
													autofillBasedOnSubdomain(e),
											})}
											error={
												Boolean(errors.tenantId) ||
												Boolean(errors.capitalTenantId)
											}
											helperText={
												errors.capitalTenantId
													? errors.capitalTenantId
															.message
													: errors.tenantId
													? errors.tenantId.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Organization Name"
											variant="standard"
											fullWidth
											id="orgName"
											{...register("orgName", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: RegexCode.orgName, 
													message:
														"Special Characters are not allowed",
												},
												maxLength: {
													value: 20,
													message:
														"Maximum 20 allowed characters",
												},
											})}
											error={Boolean(errors.orgName)}
											helperText={
												errors.orgName
													? errors.orgName.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
								{/* </Grid> */}
								{/* <Grid container spacing={2}> */}
								<Grid item xs={12} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="email"
											label="Admin E-mail"
											variant="standard"
											fullWidth
											id="adminEmail"
											{...register("adminEmail", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: RegexCode.email,
													message:
														"Enter a valid email",
												},
											})}
											error={Boolean(errors.adminEmail)}
											helperText={
												errors.adminEmail
													? errors.adminEmail.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={6}>
									{/* <MDBox mb={2}> */}
									<MDDrop
										label="Name"
										variant="standard"
										fullWidth
										id="tierDropdown"
										sx={{ minWidth: 120 }}
									>
										<InputLabel>Subscription</InputLabel>
										<Select
											// variant= "standard"
											// fullWidth
											id="tier"
											value={tier}
											label="Subscription"
											onChange={handleChange}
											MenuProps={{
												PaperProps: {
													style: {
														maxHeight: "300px",
														width: "auto",
													},
												},
											}}
											sx={{ height: "30px" }}
											// {...register("tier",{required: true})}
											// // error={Boolean(errors.tier)}
										>
											<MenuItem value={"freemium"}>
												freemium
											</MenuItem>
											<MenuItem value={"enterprise"}>
												enterprise
											</MenuItem>
										</Select>
									</MDDrop>
									{/* </MDBox> */}
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Organization Logo URL"
											variant="standard"
											fullWidth
											id="orgLogo"
											{...register("orgLogo", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: RegexCode.orgLogo,
													message:
														"Enter a valid URL",
												},
											})}
											error={Boolean(errors.orgLogo)}
											helperText={
												errors.orgLogo
													? errors.orgLogo.message
													: ""
											}
										/>
									</MDBox>
								</Grid>

								<Grid item xs={12} lg={6}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Domain"
											variant="standard"
											fullWidth
											id="domain"
											InputProps={{
												readOnly: true,
											}}
											value={domain}
										/>
									</MDBox>
								</Grid>
							</Grid>

							{process.env.REACT_APP_LB_TYPE === "TCP" ? (
								<Grid item xs={12} lg={12}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Port Number"
											variant="standard"
											fullWidth
											id="portNumber"
											{...register("portNumber", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: RegexCode.port,
													message:
														"Enter a valid port number between 1 to 65535, Only numbers allowed",
												},
											})}
											error={Boolean(errors.portNumber)}
											helperText={
												errors.portNumber
													? errors.portNumber.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
							) : null}

							{tier == "enterprise" && (
								<>
									<Grid container spacing={2}>
										<Grid item xs={12} lg={6}>
											<MDBox mb={2}>
												<MDDrop
													label="Name"
													variant="standard"
													fullWidth
													id="region"
													sx={{
														mt: 3,
														minWidth: 120,
													}}
												>
													<InputLabel>
														Region
													</InputLabel>
													<Select
														// variant= "standard"
														// fullWidth
														id="region"
														value={region}
														label="region"
														onChange={updateRegion}
														MenuProps={{
															PaperProps: {
																style: {
																	maxHeight:
																		"300px",
																	width: "auto",
																},
															},
														}}
														sx={{ height: "30px" }}
														// {...register("tier",{required: true})}
														// // error={Boolean(errors.tier)}
													>
														{regions.locations ? (
															regions.locations.map(
																(
																	location,
																	index
																) => (
																	<MenuItem
																		key={
																			index
																		}
																		value={
																			location
																		}
																	>
																		{
																			location
																		}
																	</MenuItem>
																)
															)
														) : (
															<MenuItem>
																Failed to load
																regions, please
																refresh the page
																.
															</MenuItem>
														)}
													</Select>
												</MDDrop>
											</MDBox>
										</Grid>

										<Grid item xs={12} lg={6}>
											<MDBox mb={2}>
												<MDDrop
													label="Name"
													variant="standard"
													fullWidth
													id="zone"
													required
													sx={{
														mt: 3,
														minWidth: 120,
													}}
												>
													<InputLabel>
														Zones
													</InputLabel>

													{region != "" ? (
														filteredZone()
													) : (
														<Select
															id="zone"
															value={zone}
															label="zone"
															onChange={
																updateZone
															}
															MenuProps={{
																PaperProps: {
																	style: {
																		maxHeight:
																			"300px",
																		width: "auto",
																	},
																},
															}}
															sx={{
																height: "30px",
															}}
														>
															<MenuItem>
																Please Select
																Region.
															</MenuItem>
														</Select>
													)}
												</MDDrop>
											</MDBox>
										</Grid>
										{/* <Grid item xs={12} lg={6}>
                      <MDBox mb={2} mt={1}>
                        <MDInput
                          type="text"
                          label="BigQuery Dataset ID"
                          variant="standard"
                          fullWidth
                          id="bqDataSetId"
                          value={bqDatasetId}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </MDBox>
                    </Grid> */}
										{/* <Grid item xs={12} lg={6}>
                      <MDBox mb={2} mt={1}>
                        <MDInput
                          type="text"
                          label="GCP Project ID"
                          variant="standard"
                          fullWidth
                          id="gcpProjectId"
                          value={projectId}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </MDBox>
                    </Grid> */}

										{/* <Grid item xs={12} lg={6}>
											<Grid container>
												<Grid item md={11}>
													<MDBox mb={2}>
														<MDDrop
															label="Name"
															variant="standard"
															fullWidth
															id="subnetSizeDropdown"
															sx={{
																mt: 1.5,
																minWidth: 120,
															}}
														>
															<InputLabel>
																Tier Size
															</InputLabel>

															<Select
																// variant= "standard"
																// fullWidth
																id="subnetSize"
																label="Tier Size"
																value={
																	subnetSize
																}
																onChange={(e) =>
																	setSubnetSize(
																		e.target
																			.value
																	)
																}
																MenuProps={{
																	PaperProps:
																		{
																			style: {
																				paddingLeft:
																					"0px",
																				maxHeight:
																					"300px",
																				width: "auto",
																			},
																		},
																}}
																// {...register("tier",{required: true})}
																// // error={Boolean(errors.tier)}
															>
																<MenuItem
																	value={
																		"small"
																	}
																>
																	Small
																</MenuItem>
																<MenuItem
																	value={
																		"medium"
																	}
																>
																	Medium
																</MenuItem>
																<MenuItem
																	value={
																		"large"
																	}
																>
																	Large
																</MenuItem>
															</Select>
														</MDDrop>
													</MDBox>
												</Grid>
												<Grid item md={1}>
													<HtmlTooltip
														style={{
															position:
																"relative",
															left: "50%",
															top: "50%",
															textAlign: "left",
														}}
														placement="right"
														title={
															<React.Fragment>
																<p>
																	<strong>
																		Small:
																	</strong>{" "}
																	up to 10
																	nodes of
																	e2-standard-4
																	machines
																	will be
																	allocated
																	per tenant.
																</p>
																<p>
																	<strong>
																		Medium:
																	</strong>{" "}
																	up to 15
																	nodes of
																	e2-standard-4
																	machines
																	will be
																	allocated
																	per tenant.
																</p>
																<p>
																	<strong>
																		Large:
																	</strong>{" "}
																	up to upto
																	25 nodes of
																	e2-standard-8
																	machines
																	will be
																	allocated
																	per tenant.
																</p>
															</React.Fragment>
														}
													>
														<Icon
															sx={{
																cursor: "pointer",
															}}
															fontSize="small"
														>
															help
														</Icon>
													</HtmlTooltip>
												</Grid>
											</Grid>
										</Grid> */}

										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Subnet IP"
													variant="standard"
													fullWidth
													placeholder="10.175.1.0/25"
													id="subnetIp"
													{...register("subnetIp", {
														required: true,
														pattern: RegexCode.cidrRegex,
													})}
													error={Boolean(
														errors.subnetIp
													)}
													helperText={
														errors.subnetIp
															? "please enter correct IP (ex: 4.6.0.2/27)"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Service Range"
													variant="standard"
													fullWidth
													id="svcIp"
													placeholder="10.162.0.0/25"
													{...register("svcIp", {
														required: true,
														pattern: RegexCode.cidrRegex,
													})}
													error={Boolean(
														errors.svcIp
													)}
													helperText={
														errors.svcIp
															? "please enter correct IP (ex: 4.6.0.2/27)"
															: ""
													}
												/>
											</MDBox>
										</Grid>

										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Pod Range"
													variant="standard"
													fullWidth
													id="podIp"
													placeholder="10.161.0.0/23"
													{...register("podIp", {
														required: true,
														pattern: RegexCode.cidrRegex,
													})}
													error={Boolean(
														errors.podIp
													)}
													helperText={
														errors.podIp
															? "please enter correct IP (ex: 4.6.0.2/27)"
															: ""
													}
												/>
											</MDBox>
										</Grid>

										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Master IP"
													variant="standard"
													placeholder="10.190.0.0/28"
													fullWidth
													id="masterIp"
													{...register("masterIp", {
														required: true,
														pattern: RegexCode.cidrRegex,
													})}
													error={Boolean(
														errors.masterIp
													)}
													helperText={
														errors.masterIp
															? "please enter correct IP (ex: 4.6.0.2/27)"
															: ""
													}
												/>
											</MDBox>	
										</Grid>

										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													label="Machine Type"
													placeholder="n1-standard-1"
													variant="standard"
													fullWidth
													id="machineType"
													{...register(
														"machineType",
														{
															required: true,
															// pattern: RegexCode.gcpRegex,
															onChange:(e)=>{
																setMachineType(e.target.value)
															}
														},
													)}
													error={Boolean(
														errors.machineType
													)}
													helperText={
														errors.machineType
															? "Invalid Machine type"
															: ""
													}
												/>
											</MDBox>
										</Grid>

										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="number"
													label="Min Count"
													variant="standard"
													fullWidth
													placeholder="5"
													id="minCount"
													{...register("minCount", {
														required: true,
														pattern: RegexCode.numbersRegex,
													})}
													error={Boolean(
														errors.minCount
													)}
													helperText={
														errors.minCount
															? "Invalid Min count"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="number"
													label="Max Count"
													variant="standard"
													fullWidth
													placeholder="10"
													id="maxCount"
													{...register("maxCount", {
														required: true,
														pattern: RegexCode.numbersRegex, 
													})}
													error={Boolean(
														errors.maxCount
													)}
													helperText={
														errors.maxCount
															? "Invalid Max count"
															: ""
													}
												/>
											</MDBox>
										</Grid>
										<Grid item xs={12} lg={3}>
											<MDBox mb={2}>
												<MDInput
													type="number"
													label="Node Count"
													variant="standard"
													placeholder="5"
													fullWidth
													id="nodeCount"
													{...register("nodeCount", {
														required: true,
														pattern: RegexCode.numbersRegex,
													})}
													error={Boolean(
														errors.nodeCount
													)}
													helperText={
														errors.nodeCount
															? "Invalid Node count"
															: ""
													}
												/>
											</MDBox>
										</Grid>

										 {/* #gpu enabled */}
										{/* <Grid item xs={12} lg={12}>
											<MDBox mb={1} xs={12} lg={4} style={{"display":"flex", "align-items": "center", "gap": "15px"}}>
											<InputLabel>GPU Enabled</InputLabel>
											<Switch color="secondary"
											onChange={(e)=>{ setIsGPUEnabled(e.target.checked);
											}}/>
											{(gpuType=="nvidia-tesla-k80" 
											&& ["n1-standard-1", "n1-standard-2", "n1-standard-4", "n1-standard-8", "n1-highmem-2", "n1-highmem-4", "n1-highmem-8", "n1-highcpu-2", "n1-highcpu-4", "n1-highcpu-8"].includes(machineType))
											?<>
													<Grid item xs={1}>
														<HtmlTooltip
															style={{
																position: "relative",
																left: "50%",
																top: "50%",
																textAlign: "left",
															}}
															placement="right"
															title={
																<React.Fragment>
																	<p>
																	Caution: NVIDIA K80 GPUs will reach end of life on May 1, 2024
																	</p>
																</React.Fragment>
															}
															open
														>
															<Icon
																sx={{
																	cursor: "pointer",
																	
																}}
																fontSize="small"
															>
																help
															</Icon>
														</HtmlTooltip>
													</Grid>
													
											</>
											:null
											}
											</MDBox>
										</Grid>

										{isGPUEnabled ?
											<>
												<Grid item xs={12} lg={6} >
													<MDBox mb={2}>
													<MDDrop
														label="Name"
														variant="standard"
														fullWidth
														id="gpuType"
														sx={{ minWidth: 120 }}
													>
														<InputLabel>GPU Type</InputLabel>
														<Select
															// variant= "standard"
															// fullWidth
															id="gpuType"
															value={gpuType}
															label="GPU Type"
															onChange={(e)=>{
																setGpuType(e.target.value)
																getGPUCount(e.target.value,machineType)
																setCountGpuList([])
																setGpuCount([])
															}}
															MenuProps={{
																PaperProps: {
																	style: {
																		maxHeight: "300px",
																		width: "auto",
																	},
																},
															}}
															sx={{ height: "30px" }}
															// {...register("tier",{required: true})}
															// // error={Boolean(errors.tier)}
														>
														{gpuList && gpuList.length>0 ? (
															gpuList.map(
																(
																	gpu,
																	index
																) => (
																	<MenuItem
																		key={
																			index
																		}
																		value={
																			gpu
																		}
																	>
																		{
																			gpu
																		}
																	</MenuItem>
																)
															)
														) : (
															<MenuItem>
															{machineType
															?"Please Enter A Valid Machine Type For GPU." 
															: "Please Enter Machine Type." }
																
															</MenuItem>
														)}
														</Select>
													</MDDrop>
													</MDBox>
												</Grid>

												<Grid item xs={12} lg={6}>
													<MDBox mb={2}>
													<MDDrop
														label="Name"
														variant="standard"
														fullWidth
														id="gpuCount"
														sx={{ minWidth: 120 }}
													>
														<InputLabel>GPU Count</InputLabel>
														<Select
															// variant= "standard"
															// fullWidth
															id="gpuCount"
															value={gpuCount}
															label="GPU Count"
															onChange={(e)=>setGpuCount(e.target.value)}
															MenuProps={{
																PaperProps: {
																	style: {
																		maxHeight: "300px",
																		width: "auto",
																	},
																},
															}}
															sx={{ height: "30px" }}
															// {...register("tier",{required: true})}
															// // error={Boolean(errors.tier)}
														>
														{gpuCountList && gpuCountList.length>0 ? (
															gpuCountList.map(
																(
																	gpuCount,
																	index
																) => (
																	<MenuItem
																		key={
																			index
																		}
																		value={
																			gpuCount
																		}
																	>
																		{
																			gpuCount
																		}
																	</MenuItem>
																)
															)
														) : (
															<MenuItem>
																{ ! machineType || !gpuType ?
																"Please Provide Machine Type And GPU Type." 
																: "Something Is Wrong, Please Check Machine Type And GPU Type."}
															</MenuItem>
														)}
														</Select>
													</MDDrop>
													</MDBox>
												</Grid>
											</>
											: null } */}


										{/*  #gpu enabled */}
										<Grid item xs={12} lg={12}>
											<MDBox mb={1} xs={12} lg={4} style={{"display":"flex", "align-items": "center", "gap": "15px"}}>
											<InputLabel>GPU Enabled</InputLabel>
											<Switch color="secondary"
											onChange={(e)=>{ setIsGPUEnabled(e.target.checked);
											}}/>
											{ isGPUEnabled ? <>
													<Grid item xs={1}>
														<HtmlTooltip
															style={{
																position: "relative",
																left: "50%",
																top: "50%",
																textAlign: "left",
															}}
															placement="right"
															title={
																<React.Fragment>
																	<p>
																	Refer <a target="_blank" href="https://cloud.google.com/compute/docs/gpus/gpu-regions-zones"> Region And Zone </a> Wise
																	&nbsp;<a target="_blank" href="https://cloud.google.com/compute/docs/gpus#nvidia_gpus_for_compute_workloads">GPU Type </a> 
																	And
																	&nbsp;<a target="_blank" href="https://cloud.google.com/workstations/docs/available-gpus">GPU Count </a>
																	</p>
																</React.Fragment>
															}
															open
														>
															<Icon
																sx={{
																	cursor: "pointer",
																	
																}}
																fontSize="small"
															>
																help
															</Icon>
														</HtmlTooltip>
													</Grid>
											</>
											:null}
											</MDBox>
										</Grid>

										{isGPUEnabled ?
											<>
												<Grid item xs={12} lg={6} >
													<MDBox mb={2}>
													<MDInput
													type="text"
													label="GPU Type"
													variant="standard"
													fullWidth
													id="gpuType"
													// onChange={(e)=>{
													// 	setGpuType(e.target.value)
													// 	setGpuCount(0)
													// }}
													{...register("gpuType", {
														required: {
															value: true,
															message: "Required, Refer Link For GPU Type",
														},
														pattern: {
															value: RegexCode.gpuType,
															message:
																"Special Characters are not allowed",
														},
														onChange: (e) => {setGpuType(e.target.value)}
													})}
													error={Boolean(errors.gpuType)}
													helperText={
														errors.gpuType
															? errors.gpuType.message
															: "Please Refer Link For GPU Type"
													}
													/>
													</MDBox>
												</Grid>

												<Grid item xs={12} lg={6}>
													<MDBox mb={2}>
													<MDInput
													type="number"
													variant="standard"
													label="GPU Count"
													fullWidth
													id="gpuCount"
													// onChange={(e)=>setGpuCount(e.target.value)}
													{...register("gpuCount", {
														required: {
															value: true,
															message: "Required, Refer Link For GPU Count",
														},
														pattern: {
															value: RegexCode.numbersRegex,
															message:
																"Only numbers are allowed",
														},
														onChange: (e) => setGpuCount(e.target.value)
													})}
													error={Boolean(errors.gpuCount)}
													helperText={
														errors.gpuCount
															? errors.gpuCount.message
															: "Please Refer Link For GPU Count"
													}
													/>
													</MDBox>
												</Grid>
											</>
											: null }


									</Grid>
								</>
							)}

							<MDBox mt={4} mb={1}>
								<MDButton
									variant="gradient"
									color="info"
									fullWidth
									// onClick={onboardTenant}
									onClick={handleSubmit(onSubmit)}
								>
									Add Tenant
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
}

export default AddNewTenantForm;
