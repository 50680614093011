// @mui material components
import Card from "@mui/material/Card";

import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDSnackbar from "../../../components/MDSnackbar";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";
// Images
import bgImage from "../../../assets/images/bg-reset-cover.jpeg";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { resetPassword } from "../../../apis/tenant";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const navigate = useNavigate();
  const [email, SetEmail] = useState("");
  const auth = getAuth();
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errormsg, setErrormsg] = useState("");

  const closeSuccessSB = () => {
    setSuccessSB(false);
    navigate("/sign-in");

  };
  const closeErrorSB = () => setErrorSB(false);

  const handleSendPasswordResetEmail = (event) => {
    event.preventDefault();
    // dataToSend ={
    //   email:email,
    // };
   
    // resetPassword(dataToSend)
    // .then((result) => {
    //   if (result.status === 200) {
    //     console.log("added Successfully");
    //   }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  


    sendPasswordResetEmail(auth, email)
      .then((result) => {
		setSuccessSB(true);
	  })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/missing-email")
		{
			setErrormsg("Please Enter an email");
		}
		else
		{
			setErrormsg(error.code);
		}
		setErrorSB(true);
      });
  };

  return (
    <BasicLayout image={bgImage}>
		{successSB ? (
				<MDSnackbar
					color="success"
					icon="check"
					title="SaaS Admin Portal"
					content={`Password Reset Email is successfully sent to ${email}`}
					// dateTime="11 mins ago"
					open={successSB}
					onClose={closeSuccessSB}
					close={closeSuccessSB}
					
					// bgWhite
				/>
			) : (
				<MDSnackbar
					color="error"
					icon="warning"
					title="SaaS Admin Portal"
					content={errormsg}
					// dateTime="11 mins ago"
					open={errorSB}
					onClose={closeErrorSB}
					close={closeErrorSB}
					
					// bgWhite
				/>
			)}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => SetEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSendPasswordResetEmail}
              >
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
