import colors from "../../base/colors";

import pxToRem from "../../functions/pxToRem";
import boxShadow from "../../functions/boxShadow";

const { white } = colors;

const stepIcon = {
	styleOverrides: {
		root: {
			background: "#9fc9ff",
			fill: "#9fc9ff",
			stroke: "#9fc9ff",
			strokeWidth: pxToRem(10),
			width: pxToRem(13),
			height: pxToRem(13),
			borderRadius: "50%",
			zIndex: 99,
			transition: "all 200ms linear",

			"&.Mui-active": {
				background: white.main,
				fill: white.main,
				stroke: white.main,
				borderColor: white.main,
				boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
			},

			"&.Mui-completed": {
				background: white.main,
				fill: white.main,
				stroke: white.main,
				borderColor: white.main,
				boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
			},
		},
	},
};

export default stepIcon;
