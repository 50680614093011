


import MDBox from "../../components/MDBox";


import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";



// Overview page components
import Dashboard from "./components/Dashboard";


function Overview() {
	
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox mb={2} />
			<Dashboard />
		</DashboardLayout>
	);
}

export default Overview;
