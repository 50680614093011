export const RegexCode = {
  alphabetsAndNumbersRegex: /^[A-Za-z0-9 ]*$/,
  numbersRegex: /^\d+$/,
  diskSize: /^\b[1-9]\d+\b/,
  format: /^[A-Za-z0-9]+$/,
  orgNameRegex: /[`!@#$%^&*()+=[\]{};':"\\|,.<>/?~]/,
  // orgName:/^[a-zA-Z0-9]+(\s?[a-zA-Z0-9]+)*$/,
  orgName:/^(?![0-9\s])[^!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|]*$/,

  cidrRegex : /^(?:\b(?:[0-9]\d?|1\d{2}|2[0-4]\d|25[0-5])\.){3}(?:[0-9]\d?|1\d{2}|2[0-4]\d|25[0-5])(?:\/(?:\b[0-9]|[0-2]\d|3[0-2]))$/,
  tenantIdRegex: /^[a-z0-9]*$/,
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  orgLogo:
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?$/,
  urlRegex: /^[`A-Za-z0-9!@#$%^&*()+=[\]{};':"\\|,.<>/?~]/,
  x509Regex : /^-----BEGIN CERTIFICATE-----\n(.|\n)*\n-----END CERTIFICATE-----$/,
  // gcpRegex: /^[a-z0-9-]+$/,
  // bigqueryRegex: /^[A-Za-z0-9_]+$/,
  domainRegex: /^[A-Za-z0-9.-_]*$/,
  // ipRegex: /^([0-9]{1,3}\.){3}[0-9]{1,3}$/,
  // svcRegex: /^[0-9-]+$/,
  port: /^(?:[1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-5][0-9][0-9][0-9][0-9]|6[0-5][0-5][0-3][0-5])$/,
  gpuType : /^[0-9a-z-]+$/
};
