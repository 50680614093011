import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { Grid, InputLabel, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import MDDrop from "../../components/MDDropdown";
import MDSelect from "../../components/MDSelect";
import { useState } from "react";
import { addAdmin } from "../functions/adminFormFunctions";
import { RegexCode } from "../../config/validationRegex";
import MDSnackbar from "../../components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectVendor, logout } from "../../redux/userSlice";

function AddNewAdminForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onChange" });

	const [access, setAccess] = useState(false);
	const vendor = useSelector(selectVendor);
	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const handleChange = (event) => {
		// console.log("access",event.target.value);
		setAccess(event.target.value);
	};

	const closeSuccessSB = () => {
		setSuccessSB(false);
		navigate("/admins");
	};
	const closeErrorSB = () => setErrorSB(false);

	const submitStatus = ["IN_PROGRESS", "ERROR", "COMPLETED", "FORBIDDEN"];

	const onSubmit = async (data) => {
		data.access = access;
		// console.log("data coming in",data);
		const {status,message} = await addAdmin(data);
		// console.log(status);
		// console.log("request from add admin", status);
		if (status === 200) {
			setSuccessSB(true);
		} else if (status === 403) {
			console.log("Unauthorised: Logging Out");
			dispatch(logout());
			localStorage.removeItem("persist:root");
			localStorage.clear();
			window.location.href = "/sign-in";
		} else {
			setErrorSB(true);
			setErrorMsg(message)
		}
	};

	return (
		<>
			{/* {successSB ? ( */}
			<MDSnackbar
				color="success"
				icon="check"
				title="SaaS Admin Portal"
				content={`New ${
					access === true ? `admin` : `normal`
				} user added`}
				// dateTime="11 mins ago"
				open={successSB}
				onClose={closeSuccessSB}
				close={closeSuccessSB}

				// bgWhite
			/>
			{/* ) : ( */}
			<MDSnackbar
				color="error"
				icon="warning"
				title="SaaS Admin Portal"
				content={errorMsg==''
				? `An error occured while adding ${access === true ? `admin` : `normal`} user`
				: `${errorMsg}`
				}
				// dateTime="11 mins ago"
				open={errorSB}
				onClose={closeErrorSB}
				close={closeErrorSB}

				// bgWhite
			/>
			{/* )} */}

			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox
						variant="gradient"
						bgColor="info"
						borderRadius="lg"
						coloredShadow="success"
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign="center"
					>
						<MDTypography
							variant="h4"
							fontWeight="medium"
							color="white"
							mt={1}
						>
							Add New Admin
						</MDTypography>
						<MDTypography
							display="block"
							variant="button"
							color="white"
							my={1}
						>
							Enter the following information to register a New
							Admin User
						</MDTypography>
					</MDBox>
					<MDBox pt={4} pb={3} px={3}>
						<MDBox
							component="form"
							role="form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Grid container spacing={2}>
								<Grid item md={8} lg={8}>
									<MDBox mb={2}>
										<MDInput
											type="text"
											label="Name"
											variant="standard"
											fullWidth
											id="vendorName"
											{...register("adminName", {
												required: {
													value: true,
													message: "Required",
												},
												pattern: {
													value: !RegexCode.orgNameRegex,
													message:
														"Special Characters are not allowed",
												},
												maxLength: {
													value: 20,
													message:
														"Maximum 20 allowed characters",
												},
											})}
											error={Boolean(errors.adminName)}
											helperText={
												errors.adminName
													? errors.adminName.message
													: ""
											}
										/>
									</MDBox>
								</Grid>
								<Grid item md={4} lg={4}>
									{/* <MDBox mb={2}> */}
									{/* <MDInput
                      type="text"
                      label="Access"
                      variant="standard"
                      fullWidth
                      id="accessValue"
                    /> */}
									<MDDrop
										label="Name"
										variant="standard"
										fullWidth
										id="tenantId"
										sx={{ minWidth: 120 }}
									>
										<InputLabel>Access</InputLabel>
										<MDSelect
											variant="standard"
											fullWidth
											id="adminAccess"
											value={access}
											label="Access"
											onChange={handleChange}
											sx={{ height: "30px" }}
										>
											<MenuItem value={true}>
												Admin User
											</MenuItem>
											<MenuItem value={false}>
												Normal User
											</MenuItem>
										</MDSelect>
										{/* {errors.access && <p>Please check the admin Name</p>} */}
									</MDDrop>
									{/* </MDBox> */}
								</Grid>
							</Grid>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Organization Name"
									variant="standard"
									fullWidth
									id="orgName"
									{...register("organizationName", {
										required: {
											value: true,
											message: "Required",
										},
										pattern: {
											value: !RegexCode.orgNameRegex,
											message:
												"Special Characters are not allowed",
										},
										maxLength: {
											value: 20,
											message:
												"Maximum 20 allowed characters",
										},
									})}
									error={Boolean(errors.organizationName)}
									helperText={
										errors.organizationName
											? errors.organizationName.message
											: ""
									}
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type="email"
									label="E-mail"
									variant="standard"
									fullWidth
									id="vendorUserEmail"
									{...register("adminEmail", {
										required: {
											value: true,
											message: "Required",
										},
										pattern: {
											value: RegexCode.email,
											message: "Enter a valid email",
										},
									})}
									error={Boolean(errors.adminEmail)}
									helperText={
										errors.adminEmail
											? errors.adminEmail.message
											: ""
									}
								/>
							</MDBox>
							<MDBox mt={4} mb={1}>
								<MDButton
									variant="gradient"
									color="info"
									fullWidth
									onClick={handleSubmit(onSubmit)}
								>
									Add Admin
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
}

export default AddNewAdminForm;
