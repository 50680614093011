// MUI components
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MDDrop from "../../components/MDDropdown";
import MDSelect from "../../components/MDSelect";
import MDSnackbar from "../../components/MDSnackbar";

// React Router
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { RegexCode } from "../../config/validationRegex";
import { editAdmin } from "../functions/adminFormFunctions/index";

import { useSelector, useDispatch } from "react-redux";
import { selectTenant, logout } from "../../redux/userSlice";

import { addGoogleProvider } from "../../apis/tenant";
import { updateOidcProvider } from "../../apis/tenant";

function EditIapDetails(props) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isDisabled, setIsDisabled] = useState(false);
  // let isDisabled = false;

  let { state } = useLocation();

  const user = useSelector(selectTenant);
  const dispatch = useDispatch();

  // const [access, setAccess] = useState(state.admin.isAdmin);
  const [enable, setEnable] = useState(state.data.enabled);
  const [displayName, setDisplayName] = useState(state.data.displayName);
  const [providerId, setProviderId] = useState(state.data.providerId);
  const [clientId, setClientId] = useState(state.data.clientId);
  const [issuer, setIssuer] = useState(state.data.issuer);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [infoSuccessSB, setInfoSuccessSB] = useState(false);
  // const [access, setAccess] = useState();
  const [responseType, setResponseType] = useState({ idToken: true });

  const handleChange = (event) => {
    // console.log("event data", event.target.value);
    setEnable(event.target.value);
  };

  const handleButtonChange = (error) => {
    // console.log("changing", error);
    if (error) {
      // console.log("entering if");
      // setIsDisabled(true);
      // isDisabled = true;
    } else {
      // isDisabled = false;
      // setIsDisabled(false);
    }
  };

  const closeSuccessSB = () => {
    setSuccessSB(false);
    navigate("/identity-providers");
  };
  const closeErrorSB = () => setErrorSB(false);

  const onSubmit = async (data) => {
    const dataToSend = {
      displayName,
      subdomain: user.subdomain,
      providerId,
      clientId,
      responseType,
      issuer,
    };

    if (providerId == "Google") {
      // console.log("Google Update");
      const googleData = {
        subdomain: user.subdomain,
        enabled: enable,
      };
      setInfoSuccessSB(true);
      addGoogleProvider(googleData)
        .then((result) => {
          if (result.status === 200) {
            // console.log("added Successfully");
            setSuccessSB(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(googleData);
    } else {
      // console.log(dataToSend);
      let updateResponse = await updateOidcProvider(dataToSend);
      if (updateResponse.status === 200) {
        setSuccessSB(true);
      } else if (updateResponse.status === 403) {
        console.log("Unauthorised: Logging Out");
        dispatch(logout());
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = "/sign-in";
      } else if (updateResponse.status === 500) {
        setErrorSB(true);
        setErrorMsg(updateResponse.data.message);
      } else {
        setErrorMsg(updateResponse.data.message);
        setErrorSB(true);
      }
    }
  };

  return (
    <>
      {infoSuccessSB && (
        <MDSnackbar
          icon="notifications"
          title="SaaS Admin Portal"
          content={`updating Google IDP details is in progress`}
          // dateTime="11 mins ago"
          open={infoSuccessSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}

          // bgWhite
        />
      )}
      {successSB ? (
        <MDSnackbar
          color="success"
          icon="check"
          title="SaaS Admin Portal"
          content={`Edited details for user successfully`}
          // dateTime="11 mins ago"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}

          // bgWhite
        />
      ) : (
        <MDSnackbar
          color="error"
          icon="warning"
          title="SaaS Admin Portal"
          content={
            errorMsg == ""
              ? `An error occured while editing user`
              : `${errorMsg}`
          }
          // dateTime="11 mins ago"
          open={errorSB}
          onClose={closeErrorSB}
          close={closeErrorSB}
          // bgWhite
        />
      )}
      {providerId == "Google" ? (
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign="center"
            >
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Edit Identity Provider
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                color="white"
                my={1}
              >
                Enter the following information to update Identity Provider
                details
              </MDTypography>
            </MDBox>

            <MDBox pt={4} pb={3} px={3}>
              <MDBox
                component="form"
                role="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Display Name"
                        variant="standard"
                        fullWidth
                        id="displayName"
                        defaultValue={state.data.displayName}
                        onChange={(e) => {
                          setDisplayName(e.target.value);
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDDrop
                        label="Enable"
                        variant="standard"
                        fullWidth
                        id="enableValue"
                        sx={{ m: 1.1, minWidth: 120 }}
                      >
                        <InputLabel>Enable</InputLabel>
                        <MDSelect
                          variant="standard"
                          fullWidth
                          id="enable"
                          value={enable}
                          label="enable"
                          onChange={handleChange}
                          required
                        >
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </MDSelect>
                      </MDDrop>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="ProviderID"
                        variant="standard"
                        fullWidth
                        id="providerID"
                        defaultValue={state.data.providerId}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="ClientId"
                        variant="standard"
                        fullWidth
                        id="clientId"
                        defaultValue={state.data.clientId}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    disabled={isDisabled}
                  >
                    update
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      ) : (
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign="center"
            >
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Edit Identity Provider
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                color="white"
                my={1}
              >
                Enter the following information to update Identity Provider
                details
              </MDTypography>
            </MDBox>

            <MDBox pt={4} pb={3} px={3}>
              <MDBox
                component="form"
                role="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Display Name"
                        variant="standard"
                        fullWidth
                        id="displayName"
                        defaultValue={state.data.displayName}
                        onChange={(e) => {
                          setDisplayName(e.target.value);
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="issuer"
                        variant="standard"
                        fullWidth
                        id="issuer"
                        defaultValue={state.data.issuer}
                        onChange={(e) => {
                          setIssuer(e.target.value);
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="ProviderID"
                        variant="standard"
                        fullWidth
                        id="providerID"
                        defaultValue={state.data.providerId}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="ClientId"
                        variant="standard"
                        fullWidth
                        id="clientId"
                        defaultValue={state.data.clientId}
                        onChange={(e) => {
                          setClientId(e.target.value);
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    disabled={isDisabled}
                  >
                    update
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      )}
    </>
  );
}

export default EditIapDetails;
