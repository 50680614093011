import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import MDBox from "../../../components/MDBox";

import { useMaterialUIController, setLayout } from "../../../context";

function PageLayout({ background, children }) {
	const [, dispatch] = useMaterialUIController();
	const { pathname } = useLocation();

	useEffect(() => {
		setLayout(dispatch, "page");
	}, [pathname]);

	return (
		<MDBox
			width="100vw"
			height="100%"
			minHeight="100vh"
			bgColor={background}
			sx={{ overflowX: "hidden" }}
		>
			{children}
		</MDBox>
	);
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
	background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
	background: PropTypes.oneOf(["white", "light", "default"]),
	children: PropTypes.node.isRequired,
};

export default PageLayout;
