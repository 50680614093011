import Grid from "@mui/material/Grid";

import MDBox from "../../../components/MDBox";

import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

import ReportsBarChart from "../../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../../examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useSelector } from "react-redux";

import { selectVendor } from "../../../redux/userSlice";
import { Card } from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import { useState } from "react";
import DefaultProjectCard from "../../../examples/Cards/ProjectCards/DefaultProjectCard";

function ExtraLinksPage() {
	const vendor = useSelector(selectVendor);
	let asmDashboardUrl = vendor?.asmDashboardUrl;
	let logMonDashboardUrl = vendor?.logMonDashboardUrl;
	const [errorSB, setErrorSB] = useState(false);
	const closeErrorSB = () => setErrorSB(false);

	return (
		//     <DashboardLayout style= {{ height: "100vh" }}>
		//     <DashboardNavbar />
		//     <div
		//     className="main-content-container px-4 container-fluid"
		//     style={{
		//         margin: "0px",
		//         padding: "0px",
		//         overflow: "hidden",
		//         minHeight: "100vh",
		//     }}
		// >

		//     {!!REACT_APP_DS_USAGE ? (
		//         <>
		//         {window.open(REACT_APP_DS_USAGE, '_blank', 'noopener,noreferrer')}
		//         <div
		//             style={{
		//                 display: "flex",
		//                 justifyContent: "center",
		//                 alignItems: "center",
		//                 height: "100%",
		//             }}
		//         >
		//             <h2>ASM Dashboard opened in a new window.</h2>
		//         </div>
		//         </>

		//     ) : (
		//         <div
		//             style={{
		//                 display: "flex",
		//                 justifyContent: "center",
		//                 alignItems: "center",
		//                 height: "100%",
		//             }}
		//         >
		//             <h2>It seems you don't have the access to dashboard</h2>
		//         </div>
		//     )}

		// </div>

		// </DashboardLayout>
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				{/* <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={2}
                mt={-3}
                py={3}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Direct links
                </MDTypography>

              </MDBox>
              <MDBox pt={3} mx={4} mt={2} py={3}>
                <Grid container spacing={6}>
                  <Grid item>
                    <MDTypography
                      variant="h6"
                      color="#344767"
                      onClick={() => {
                        !!REACT_APP_DS_USAGE
                          ? window.open(
                              REACT_APP_DS_USAGE,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          : setErrorSB(true);
                      }}
                    >
                      ASM Dashboard{" "}
                    </MDTypography>
                    <MDTypography
                      variant="h6"
                      color="#344767"
                      onClick={() => {
                        !!REACT_APP_LOG_MON_PAGE
                          ? window.open(
                              REACT_APP_LOG_MON_PAGE,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          : setErrorSB(true);
                      }}
                    >
                      Log Monitoring Dashboard{" "}
                    </MDTypography>
                 
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox> */}
	  		{
			process.env.REACT_APP_TENENT_ENV=="VM"
			?<MDBox p={2}>
			<Grid container spacing={12}>
				<Grid item xs={12} md={12} xl={12}>
					<DefaultProjectCard
						title="Monitoring Dashboards"
						description="These dashboards display metrics and general information about any Google Cloud service."
						action={{
							type: "external",
							route: logMonDashboardUrl,
							color: "info",
							label: "view dashboard",
						}}
					/>
				</Grid>
			</Grid>
			</MDBox>
			
			:<MDBox p={2}>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6} xl={6}>
					<DefaultProjectCard
						title="ASM Dashboards"
						description="Get an overview of all services in your mesh, providing you critical, service-level metrics"
						action={{
							type: "external",
							route: asmDashboardUrl,
							color: "info",
							label: "view dashboard",
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6} xl={6}>
					<DefaultProjectCard
						title="Monitoring Dashboards"
						description="These dashboards display metrics and general information about any Google Cloud service."
						action={{
							type: "external",
							route: logMonDashboardUrl,
							color: "info",
							label: "view dashboard",
						}}
					/>
				</Grid>
			</Grid>
		</MDBox>
			}
			</MDBox>
		</DashboardLayout>
	);
}

export default ExtraLinksPage;
