import apiClient from "../apiClient";

export const getAllAdmin = async () => {
	const response = await apiClient.get("/api/vendors/list");
	return response;
};

export const getDefaultVendor = async () => {
	const response = await apiClient.get("/api/default-vendor/get");
	return response;
};

export const addAdmin = async (endpoint, dataToSend) => {
	const response = await apiClient.post(endpoint, dataToSend);
	return response;
};

export const updateAdmin = async (dataToSend) => {
	const response = await apiClient.post("/api/vendors/update", dataToSend);
	return response;
};

export const deleteAdmin = async (tenantid) => {
	const response = await apiClient.post("/api/vendors/delete", tenantid);
	return response;
};

export const loginAdmin = async (idToken, email) => {
	const response = await apiClient.post("/api/vendors/authenticate", {
		idToken: idToken,
		email: email,
	});
	return response;
};

export const updateDefaultVendor = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/default-vendor/update",
		dataToSend
	);
	return response;
};

//vm base routes

export const getDefaultVmVendorDetail = async () => {
	const response = await apiClient.get("/api/default-vendor/vm-get");
	return response;
};

export const updateDefaultVmVendor = async (dataToSend) => {
	const response = await apiClient.post(
		"/api/default-vendor/vm-update",
		dataToSend
	);
	return response;
};
