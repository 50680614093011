	// react-router-dom components
	import { Link } from "react-router-dom";

	// @mui material components
	import Card from "@mui/material/Card";
	import Checkbox from "@mui/material/Checkbox";
	import * as React from "react";
	import MDBox from "../../components/MDBox";
	import MDTypography from "../../components/MDTypography";
	import MDInput from "../../components/MDInput";
	import MDButton from "../../components/MDButton";
	import Select, { SelectChangeEvent } from "@mui/material/Select";

	// import styled from "@emotion/styled";
	import { styled } from "@mui/material/styles";

	import validator from "validator";

	// Images
	import bgImage from "../../assets/images/bg-sign-up-cover.jpeg";
	import {
		Grid,
		Icon,
		IconButton,
		InputLabel,
		MenuItem,
		Switch,
		Tooltip,
		tooltipClasses,
		Typography,
	} from "@mui/material";

	// import { addTenant } from "../../apis/tenant/index";
	import { Tenant } from "../../schemas";
	import { useForm } from "react-hook-form";
	import { useState } from "react";
	import MDDrop from "../../components/MDDropdown";

	import { useSelector, useDispatch } from "react-redux";
	import { selectVendor, logout } from "../../redux/userSlice";
	import { RegexCode } from "../../config/validationRegex";
	import { addTenant } from "../functions/tenantFormFunctions";
	import MDSnackbar from "../../components/MDSnackbar";
	import { useNavigate } from "react-router-dom";
	import {
		regions as regionTest,
		zones as zoneTest,
	} from "../../config/gcpZoneAndRegions";
	import { getRegions } from "../../apis/tenant";
	import { getZones } from "../../apis/tenant";
	import { useEffect } from "react";

	const alphanumericNo = Math.random().toString(36).substr(2, 6);

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "#f5f5f9",
			color: "rgba(0, 0, 0, 0.87)",
			maxWidth: 520,
			fontSize: theme.typography.pxToRem(12),
			border: "1px solid #dadde9",
		},
	}));

	function AddNewVmTenantForm() {
		const dispatch = useDispatch();
		const navigate = useNavigate();

		const {
			register,
			handleSubmit,
			setError,
			clearErrors,
			formState: { errors },
		} = useForm({ mode: "onChange" });

		// const [subnetSize, setSubnetSize] = useState("");
		const [tier, setTier] = useState("vm");
		const [regions, setRegions] = useState([]);
		const [zones, setZones] = useState([]);
		const [region, setRegion] = useState("");
		const [zone, setZone] = useState("");
		const freemiumProjectId = process.env.REACT_APP_FREEMIUM_PROJECT_ID;
		const vendor = useSelector(selectVendor);
		const subDomain = vendor?.subDomain;
		const freemiumSubdomain = vendor?.freemiumSubdomain;
		const enterpriseSubdomain = vendor?.enterpriseSubdomain;
		const [mysubDomain, setSubDomain] = useState("");
		const [projectId, setProjectId] = useState("");
		const [bqDatasetId, setbqDatasetId] = useState("");
		const [domain, setDomain] = useState("");
		const [infoSB, setInfoSB] = useState(false);
		const [errorSB, setErrorSB] = useState(false);
		const [errorMessage, setErrorMessage] = useState("");
		const [diskType, setDiskType] = useState("pd-standard");
		const [additionalDisks, setAdditionalDisks] = useState([]);
		// const [myInfoSB, mySetInfoSB] = useState(false);

		const handleChange = (event) => { //not in use
			setDiskType(event.target.value);
			}

		const updateZone = (event) => {
			setZone(event.target.value);
		};

		const updateRegion = (event) => {
			setRegion(event.target.value);
		};

		const autofillBasedOnSubdomain = (e) => {
			var text = e.target.value;
			setSubDomain(text);
			if (text.length !== 0) {
				setDomain(text + "." + subDomain);
				setProjectId(text + "-" + alphanumericNo);
				setbqDatasetId(text + "_saas_dsid");
			} else {
				setProjectId("");
				setbqDatasetId("");
				setDomain("");
			}
		};

		useEffect(() => {
			getRegions()
			.then((response) => {
				if (response.status === 200) {
					// console.log("regions", response.data);
					setRegions(response.data);
				}
			})
			.catch((error) => {
				// alert(`Oops, an error occured while fetching regions`);
				if (error.response.status === 403) {
					console.log("Unauthorised: Logging Out");
					dispatch(logout());
					localStorage.removeItem("persist:root");
					localStorage.clear();
					window.location.href = "/sign-in";
				}
				console.log(error);
			});
			// setRegions(regionTest)
		}, []);


		useEffect(() => {
			getZones(region)
				.then((response) => {
					if (response.status === 200) {
						// console.log("zones", response.data);
						setZones(response.data);
					}
				})
				.catch((error) => {
					// alert(`Oops, an error occured while fetching zones`);
					if (error.response.status === 403) {
						console.log("Unauthorised: Logging Out");
						dispatch(logout());
						localStorage.removeItem("persist:root");
						localStorage.clear();
						window.location.href = "/sign-in";
					}
					console.log(error);
				});
				// setZones(zoneTest)
		}, [region]);

		const filteredZone = () => {
			return (
				<>
					<Select
						// variant= "standard"
						// fullWidth
						id="zone"
						value={zone}
						label="zone"
						onChange={updateZone}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: "300px",
									width: "auto",
								},
							},
						}}
						sx={{ height: "30px" }}
						// {...register("tier",{required: true})}
						// error={Boolean(errors.tier)}
					>
						{zones.locations ? (
							zones.locations.map(
								(location, index) =>
									location.includes(region) && (
										<MenuItem key={index} value={location}>
											{location}
										</MenuItem>
									)
							)
						) : (
							<MenuItem>
								Failed to load zones, please refresh the page.
							</MenuItem>
						)}
					</Select>
				</>
			);
		};

		const closeInfoSB = () => {
			setInfoSB(false);
			navigate("/tenant-onboard-status?subdomain=" + mysubDomain);

		};
		const closeErrorSB = () => setErrorSB(false);

		const onSubmit = async (data) => {
			data.tenantId = mysubDomain;
			data.tierval = "vm";
			data.region = region;
			data.zone = zone;
			data.domain = domain;
			data.diskType = diskType;
			data.bqDatasetId = bqDatasetId;
			data.projectId = projectId;
			data.emailVerified = false;
			data.addDisk = additionalDisks;
			// console.log("data", data);
			const res = await addTenant(data);
		
			if (res.status == 200) {
				setInfoSB(true);
			} else {
				setErrorSB(true);
				setErrorMessage(res.data.message);
			}
			// onboardTenant();
		};

	const addDisk = () =>  setAdditionalDisks([...additionalDisks, { diskType: 'pd-standard', diskSize: 10 }]);
	
	const removeDisk = (index) => {
		const updatedDisks = [...additionalDisks];
    	updatedDisks.splice(index, 1);
		setAdditionalDisks(updatedDisks);
	};

	const handleDiskChanges = (index, event) =>{
		const { name, value } = event.target;
		const updatedDisks = [...additionalDisks];
		updatedDisks[index][name.split("_")[0]] = value;
		setAdditionalDisks(updatedDisks);
		console.log("updatedDisks >> ",additionalDisks)
	}
		return (
			<>
			{infoSB && (
				<MDSnackbar
					icon="notifications"
					title="SaaS Admin Portal"
					content="You'll be redirected to tenant onboarding status page"
					// dateTime="11 mins ago"
					open={infoSB}
					onClose={closeInfoSB}
					close={closeInfoSB}
				/>
			)}
			{errorSB && (
				<MDSnackbar
					color="error"
					icon="warning"
					title="SaaS Admin Portal"
					content={errorMessage}
					// dateTime="11 mins ago"
					open={errorSB}
					onClose={closeErrorSB}
					close={closeErrorSB}

					// bgWhite
				/>
			)}
				<MDBox pt={6} pb={3}>
					<Card>
						<MDBox
							variant="gradient"
							bgColor="info"
							borderRadius="lg"
							coloredShadow="success"
							mx={2}
							mt={-3}
							p={3}
							mb={1}
							textAlign="center"
						>
							<MDTypography
								variant="h4"
								fontWeight="medium"
								color="white"
								mt={1}
							>
								Add Tenant
							</MDTypography>
							<MDTypography
								display="block"
								variant="button"
								color="white"
								my={1}
							>
								Edit the following information of a Tenant
							</MDTypography>
						</MDBox>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox
								component="form"
								role="form"
								onSubmit={handleSubmit(onSubmit)}
							>
								<Grid container spacing={2}>
									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Tenant ID"
												variant="standard"
												fullWidth
												// onChange={(e) =>
												// 	autofillBasedOnSubdomain(e)
												// }
												{...register("tenantId", {
													required: "Required",
													maxLength: {
														value: 6,
														message:
															"Please enter less than 6 characters",
													},
													pattern: {
														value: RegexCode.tenantIdRegex,
														message:
															"White Space, Capital and Special Characters are not allowed",
													},
													onChange: (e) =>
														autofillBasedOnSubdomain(e),
												})}
												error={
													Boolean(errors.tenantId) ||
													Boolean(errors.capitalTenantId)
												}
												helperText={
													errors.capitalTenantId
														? errors.capitalTenantId
																.message
														: errors.tenantId
														? errors.tenantId.message
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Organization Name"
												variant="standard"
												fullWidth
												id="orgName"
												{...register("orgName", {
													required: {
														value: true,
														message: "Required",
													},
													pattern: {
														value: !RegexCode.orgNameRegex,
														message:
															"Special Characters are not allowed",
													},
													maxLength: {
														value: 20,
														message:
															"Maximum 20 allowed characters",
													},
												})}
												error={Boolean(errors.orgName)}
												helperText={
													errors.orgName
														? errors.orgName.message
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="email"
												label="Admin E-mail"
												variant="standard"
												fullWidth
												id="adminEmail"
												{...register("adminEmail", {
													required: {
														value: true,
														message: "Required",
													},
													pattern: {
														value: RegexCode.email,
														message:
															"Enter a valid email",
													},
												})}
												error={Boolean(errors.adminEmail)}
												helperText={
													errors.adminEmail
														? errors.adminEmail.message
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Organization Logo URL"
												variant="standard"
												fullWidth
												id="orgLogo"
												{...register("orgLogo", {
													required: {
														value: true,
														message: "Required",
													},
													pattern: {
														value: RegexCode.orgLogo,
														message:
															"Enter a valid URL",
													},
												})}
												error={Boolean(errors.orgLogo)}
												helperText={
													errors.orgLogo
														? errors.orgLogo.message
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Domain"
												variant="standard"
												fullWidth
												id="domain"
												InputProps={{
													readOnly: true,
												}}
												value={domain}
											/>
										</MDBox>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDDrop
												label="Name"
												variant="standard"
												fullWidth
												id="region"
												
											>
												<InputLabel>
													Region
												</InputLabel>
												<Select
													// variant= "standard"
													// fullWidth
													id="region"
													value={region}
													label="region"
													onChange={updateRegion}
													MenuProps={{
														PaperProps: {
															style: {
																maxHeight:
																	"300px",
																width: "auto",
															},
														},
													}}
													sx={{ height: "30px" }}
													// {...register("tier",{required: true})}
													// // error={Boolean(errors.tier)}
												>
													{regions.locations ? (
														regions.locations.map(
															(
																location,
																index
															) => (
																<MenuItem
																	key={
																		index
																	}
																	value={
																		location
																	}
																>
																	{
																		location
																	}
																</MenuItem>
															)
														)
													) : (
														<MenuItem>
															Failed to load
															regions, please
															refresh the page
															.
														</MenuItem>
													)}
												</Select>
											</MDDrop>
										</MDBox>
									</Grid>

									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDDrop
												label="Name"
												variant="standard"
												fullWidth
												id="zone"
												required
												
											>
												<InputLabel>
													Zones
												</InputLabel>

												{region != "" ? (
													filteredZone()
												) : (
													<Select
														id="zone"
														value={zone}
														label="zone"
														onChange={
															updateZone
														}
														MenuProps={{
															PaperProps: {
																style: {
																	maxHeight:
																		"300px",
																	width: "auto",
																},
															},
														}}
														sx={{
															height: "30px",
														}}
													>
														<MenuItem>
															Please Select
															Region.
														</MenuItem>
													</Select>
												)}
											</MDDrop>
										</MDBox>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Subnet IP"
												variant="standard"
												fullWidth
												placeholder="10.175.1.0/25"
												id="subnetIp"
												{...register("subnetIp", {
													required: true,
													pattern: RegexCode.cidrRegex,
												})}
												error={Boolean(
													errors.subnetIp
												)}
												helperText={
													errors.subnetIp
														? "Invalid Subnet IP"
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item xs={12} lg={3}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Machine Type"
												placeholder="e2-standard-4"
												variant="standard"
												fullWidth
												id="machineType"
												{...register(
													"machineType",
													{
														required: true,
														// pattern: RegexCode.gcpRegex,
													}
												)}
												error={Boolean(
													errors.machineType
												)}
												helperText={
													errors.machineType
														? "Invalid Machine type"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item xs={12} lg={3}>
										<MDBox mb={2}>
											<MDInput
												type="number"
												label="Min Count"
												variant="standard"
												fullWidth
												placeholder="5"
												id="minCount"
												{...register("minCount", {
													required: true,
													pattern: RegexCode.numbersRegex,
												})}
												error={Boolean(
													errors.minCount
												)}
												helperText={
													errors.minCount
														? "Invalid Min count"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item xs={12} lg={3}>
										<MDBox mb={2}>
											<MDInput
												type="number"
												label="Max Count"
												variant="standard"
												fullWidth
												placeholder="10"
												id="maxCount"
												{...register("maxCount", {
													required: true,
													pattern: RegexCode.numbersRegex,
												})}
												error={Boolean(
													errors.maxCount
												)}
												helperText={
													errors.maxCount
														? "Invalid Max count"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item xs={11.5} lg={2.5}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Port Number"
												variant="standard"
												fullWidth
												id="portNumber"
												{...register("portNumber", {
													required: {
														value: true,
														message: "Required",
													},
													pattern: {
														value: RegexCode.port,
														message:
															"Enter a valid port number between 1 to 65535, Only numbers allowed",
													},
												})}
												error={Boolean(errors.portNumber)}
												helperText={
													errors.portNumber
														? errors.portNumber.message
														: ""
												}
											/>
										</MDBox>
									</Grid>

									<Grid item xs={0.5} lg={0.5} mb={2} mt={-1.5}>
											<MDBox mb={12} xs={12} lg={4} style={{"display":"flex", "align-items": "center", "gap": "15px", "marginBottom":"0"}}>
													<Grid item xs={12}>
														<HtmlTooltip
															style={{ position: "relative", left: "50%", top: "50%", textAlign: "left",}}
															placement="right"
															title={
																<React.Fragment>
																	<p> Port On Which Application Is Listening, Eg: 5000 </p>
																</React.Fragment>
															}  
															// open
															>
															<Icon sx={{cursor: "pointer"}} fontSize="small"> help </Icon>
														</HtmlTooltip>
													</Grid>
											</MDBox>
									</Grid>

									<Grid item xs={12} lg={6}>
										<MDBox mb={2}>
											<MDInput
												type="number"
												label="Boot Disk Size"
												variant="standard"
												placeholder="10"
												fullWidth
												id="diskSize"
												{...register("diskSize", {
													required: true,
													pattern: RegexCode.diskSize,
												})}
												error={Boolean(
													errors.diskSize
												)}
												helperText={
													errors.diskSize
														? "Invalid Disk Size, Minimum 10 Gb"
														: ""
												}
											/>
										</MDBox>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MDBox mb={0}>
										<MDDrop
											label="Name"
											variant="standard"
											fullWidth
											id="tierDropdown"
											sx={{ minWidth: 120 }}
										>
											<InputLabel>Disk Type</InputLabel>
											<Select
												id="diskType"
												value={diskType}
												label="diskType"
												onChange={handleChange}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: "300px",
															width: "auto",
														},
													},
												}}
												sx={{ height: "30px" }}
											>
												<MenuItem value={"pd-standard"}>
												pd-standard												
												</MenuItem>
												<MenuItem value={"pd-balanced"}>
												pd-balanced											
												</MenuItem>
												<MenuItem value={"pd-ssd"}>
												pd-ssd												
												</MenuItem>
												<MenuItem value={"pd-extreme"}>
												pd-extreme												
												</MenuItem>
											</Select>
										</MDDrop>
										</MDBox>
									</Grid>
									{/*  */}
									<Grid item xs={11.5} lg={11.5} mb={2} >
										<InputLabel style={{cursor:"pointer", pointerEvents:"none"}} xs={11.5} lg={11.5} mb={2} >
											<MDButton
												onClick={()=>addDisk()}
												color="info"
												size="small"
												variant="outlined"
												style={{pointerEvents:"auto"}}
											>
												Additional Disk +
											</MDButton>
										</InputLabel>									
									</Grid>
									<Grid item xs={0.5} lg={0.5} mb={2} mt={-1.5}>
											<MDBox mb={12} xs={12} lg={4} style={{"display":"flex", "align-items": "center", "gap": "15px", "marginBottom":"0"}}>
													<Grid item xs={12}>
														<HtmlTooltip
															style={{ position: "relative", left: "50%", top: "50%", textAlign: "left",}}
															placement="right"
															title={
																<React.Fragment>
																	<p> Disk's Will Be pd-standard And 10 GB By Default </p>
																</React.Fragment>
															}  
															// open
															>
															<Icon sx={{cursor: "pointer"}} fontSize="small"> help </Icon>
														</HtmlTooltip>
													</Grid>
											</MDBox>
									</Grid>		

									{
										additionalDisks.map((currentDisk, index)=>(
											<>
												<Grid item xs={11} lg={5.5} key={`disk_${index}`}>
												<MDBox mb={2}>
													<MDInput
														type="number"
														id={`diskSize_${index}`}
														name="size"
														label="Disk Size"
														variant="standard"
														placeholder="10"
														fullWidth
														// value={currentDisk.size}
														// onChange={(e)=>handleDiskChanges(index,e)}
														{...register(`diskSize_${index}`, {
															required: true,
															pattern: RegexCode.diskSize,
															onChange: (e)=>{handleDiskChanges(index,e)} 
														})}
														error={Boolean(
															errors[`diskSize_${index}`]
														)}
														helperText={
															errors[`diskSize_${index}`]
																? "Invalid Disk Size"
																: ""
														}
													/>
												</MDBox>
												</Grid>
												<Grid item xs={11} lg={5.5}>
													<MDBox mb={2}>
													<MDDrop
														label="Name"
														variant="standard"
														fullWidth
														id={`tierDropdown_${index}`}
														sx={{ minWidth: 120 }}
													>
														<InputLabel>Disk Type</InputLabel>
														<Select
															name="diskType"
															id={`tierDropdown_${index}`}
															value={currentDisk.diskType} 
															label={`tierDropdown`}
															onChange={(e)=>handleDiskChanges(index,e)}
															MenuProps={{
																PaperProps: {
																	style: {
																		maxHeight: "300px",
																		width: "auto",
																	},
																},
															}}
															sx={{ height: "30px" }}
														>
															<MenuItem value={"pd-standard"}>
															pd-standard												
															</MenuItem>
															<MenuItem value={"pd-balanced"}>
															pd-balanced											
															</MenuItem>
															<MenuItem value={"pd-ssd"}>
															pd-ssd												
															</MenuItem>
															<MenuItem value={"pd-extreme"}>
															pd-extreme												
															</MenuItem>
														</Select>
													</MDDrop>
													</MDBox>
												</Grid>
												<Grid item xs={1} lg={1} style={{paddingLeft:0, paddingTop:0}}>
													<MDBox mb={2} key={`disk_${index}`}>
													<InputLabel onClick={()=>removeDisk(index)} style={{cursor:"pointer"}}>
														<MDButton
															color="info"
															size="large"
															variant="outlined"
															iconOnly={true}
															style={{border:"0"}}
														>
															<Icon fontSize="small">close</Icon>
														</MDButton>
													</InputLabel>	
													</MDBox>
												</Grid>
											</>
										))
									}
								</Grid>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										onClick={handleSubmit(onSubmit)}
									>
										Add Tenant
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</Card>
				</MDBox>
			</>
		);
	}

	export default AddNewVmTenantForm;