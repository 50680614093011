import * as React from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import AddTenantUserForm from "../../../forms/addTenantUser";

function AddTenantUser() {
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<AddTenantUserForm />
		</DashboardLayout>
	);
}

export default AddTenantUser;
