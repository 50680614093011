import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput

import MDDropdown from "./MDDropdown";

const MDDrop = forwardRef(({ error, success, disabled, ...rest }, ref) => (
	<MDDropdown
		{...rest}
		ref={ref}
		ownerState={{ error, success, disabled }}
	/>
));

// Setting default values for the props of MDInput
MDDrop.defaultProps = {
	error: false,
	success: false,
	disabled: false,
};

// Typechecking props for the MDInput
MDDrop.propTypes = {
	error: PropTypes.bool,
	success: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default MDDrop;
