import Grid from "@mui/material/Grid";

import MDBox from "../../../components/MDBox";

import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

import ReportsBarChart from "../../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../../examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/userSlice";

function CostPage() {

    const user = useSelector(selectUser);
    let REACT_APP_DS_USAGE = user?.costDataStudioUrl;

	return (
		<DashboardLayout style= {{ height: "100vh" }}>
			<DashboardNavbar />
            <div
            className="main-content-container px-4 container-fluid"
            style={{
                margin: "0px",
                padding: "0px",
                overflow: "hidden",
                minHeight: "100vh",
            }}
        >
           
           
            {!!REACT_APP_DS_USAGE ? (
                <iframe
                    title="datastudio1"
                    height="100vh"
                    src={REACT_APP_DS_USAGE}
                    frameBorder="0"
                    style={{ border: "0", minHeight: "100vh", width: "100%" }}
                    allowFullScreen
                ></iframe>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <h2>It seems you don't have the access to dashboard</h2>
                </div>
            )}
        
        
        
        </div>
			
		</DashboardLayout>
	);
}

export default CostPage;