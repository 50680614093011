import { useNavigate } from "react-router-dom";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDSnackbar from "../../../components/MDSnackbar";
import breakpoints from "../../../assets/theme/base/breakpoints";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

// Authentication layout components
import BasicLayout from "../../authentication/components/BasicLayout";

// Images
import bgImage from "../../../assets/images/bg-sign-in-basic.jpeg";
import GoogleButton from "react-google-button";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../../config/firebase.config";
import { setUserData } from "../../../redux/userSlice";
import { setTenantData } from "../../../redux/userSlice";
import { setVendorData } from "../../../redux/userSlice";
import { useDispatch } from "react-redux";
import { loginTenant } from "../../../apis/tenant/index";
import { loginAdmin } from "../../../apis/admin";
import React, { useEffect, useState } from "react";
import { selfOnboardTenant } from "../../../apis/tenant/index";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { RegexCode } from "../../../config/validationRegex";

const generateUniqueTenantid = () => {
	const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
	let uniqueID = "";

	for (let i = 0; i < 6; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		uniqueID += characters.charAt(randomIndex);
	}

	return uniqueID;
};

const alphanumericNo = Math.random().toString(36).substr(2, 6);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function Basic(props) {
	//regex for validation

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onChange" });

	const navigate = useNavigate();
	const [rememberMe, setRememberMe] = useState(false);
	const dispatch = useDispatch();
	const handleSetRememberMe = () => setRememberMe(!rememberMe);
	const tenantId = alphanumericNo;

	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [tabValue, setTabValue] = useState(0);

	const [successSB, setSuccessSB] = useState(false);
	const [errorSB, setErrorSB] = useState(false);

	const [errormsg, setErrormsg] = useState("");

	// const freemiumSubdomain = "freemium.appdm.searceinc.net";
	const [domain, setDomain] = useState("");
	const [UniqueTenantId, setUniqueTenantId] = useState("");

	const closeSuccessSB = () => {
		setSuccessSB(false);
	};
	const closeErrorSB = () => setErrorSB(false);

	useEffect(() => {
		const uniqueID = generateUniqueTenantid();
		setUniqueTenantId(uniqueID);

		// console.log(tabValue);
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		/** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */

		window.addEventListener("resize", handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () =>
			window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => setTabValue(newValue);

	const onboardTenant = () => {
		const logoUrl = document.querySelector("#logo-url").value;
		const orgName = document.querySelector("#org-name").value;
		const adminEmail = document.querySelector("#admin-email").value;
		const subdomain = document.querySelector("#tenantId").value;
		// const domain = document.querySelector("#domain").value;
		const tier = "freemium";
		// setSubmitDisabled(true);
		// const orgName = e.target.feOrgName.value;
		// const subdomain = e.target.feSubdomainName.value;
		// console.log(subdomain);
		// const adminEmail = e.target.feAdminEmail.value;
		// const logoUrl = e.target.feLogoUrl.value;
		// const domain = e.target.feDomain.value;
		const tenantJSON = {
			orgName,
			adminEmail,
			subdomain,
			logoUrl,
			domain,
			tier,
		};
		// console.log(tenantJSON);

		selfOnboardTenant(tenantJSON)
			.then((result) => {
				if (result.status === 200) {
					console.log("called successfully");
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const onSubmit = (data) => {
		// console.log("selfonboard",data);
		onboardTenant();
	};

	const VendorsignInWithGoogle = (e) => {
		e.preventDefault();
		const provider = new GoogleAuthProvider();
		const expiryDays = 1;
		signInWithPopup(auth, provider)
			.then((_) => {
				// console.log(auth.currentUser);
				const { displayName, email, photoURL, uid, accessToken } =
					auth.currentUser;

				loginAdmin(accessToken, email)
					.then((result) => {
						console.log("RESULT ", result.data);
						if (result.status === 200) {
							let d = new Date();
							d.setTime(
								d.getTime() + expiryDays * 24 * 60 * 60 * 1000
							);
							document.cookie = `saas-user-token=${result.data.idToken}; expires=${d}; path=/`;
								console.log("result is",result);
							dispatch(
								setUserData({
									uid: uid,
									name: displayName,
									email: result?.data?.email,
									photo: result?.data?.logoUrl,
									userType: "vendor",
									costDataStudioUrl:
										result?.data?.dataStudioUrls
											?.costDataStudioUrl,
									usageDataStudioUrl:
										result?.data?.dataStudioUrls
											?.usageDataStudioUrl,
								})
							);
							dispatch(

								setVendorData({
									isAdmin: result?.data?.isAdmin,
									landingPageDataStudioUrl:
										result?.data?.dataStudioUrls
											?.landingPageDataStudioUrl,
									asmDashboardUrl:
										result?.data?.dataStudioUrls
											?.asmDashboardUrl,
									logMonDashboardUrl:
										result?.data?.dataStudioUrls
											?.logMonDashboardUrl,

									// domain: result?.data?.domain,

									// freemiumSubdomain:
									// 	result?.data?.tierDomain
									// 		?.freemiumSubdomain, //null
									// enterpriseSubdomain:
									// 	result?.data?.tierDomain
									// 		?.enterpriseSubdomain, //null
									subDomain:
										result?.data
											?.vmSubdomain,
								})
							);

							// window.location.href = "/tenants";
							// <Navigate to="/tenants" />;
							navigate("/");
						}
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	//authentication
	const auth = getAuth();
	//set email and password
	const [email, setEmail] = useState(props.email);
	const [password, setPassword] = useState(props.password);

	const handleSignIn = (event) => {
		event.preventDefault();
		// console.log({ auth, email, password });

		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				// const user = userCredential.user;
				const { displayName, email, photoURL, uid, accessToken } =
					userCredential.user;
				const name = email.substring(0, email.indexOf("@"));
				const expiryDays = 1;
				loginTenant(accessToken, email).then((result) => {
					console.log(result.data);
					if (result.status === 200) {
						let d = new Date();
						d.setTime(
							d.getTime() + expiryDays * 24 * 60 * 60 * 1000
						);
						document.cookie = `saas-user-token=${result.data.idToken}; expires=${d}; path=/`;
						// document.getElementById("modal").close();
						// setShowModal(false);

						dispatch(
							setTenantData({
								domain: result?.data?.tenent?.domain,
								tier: result?.data?.tenent?.tier,
								subdomain: result?.data?.tenent?.subdomain,
								callbackUrl: result?.data?.tenent?.callbackUrl,
							})
						);
						dispatch(
							setUserData({
								uid: uid,
								name: name,
								email: result?.data?.tenent?.adminEmail,
								photo: result?.data?.tenent?.logoUrl,
								userType: "tenant",
								costDataStudioUrl:
									result?.data?.tenent?.costDataStudioUrl,
								usageDataStudioUrl:
									result?.data?.tenent?.usageDataStudioUrl,
								landingPageDataStudioUrl : result?.data?.tenent?.landingPageDataStudioUrl
							})
						);
						setSuccessSB(true);

						// window.location.href = "/tenant-users";
						navigate("/");
					}
				}).catch((error)=>{
					setErrormsg(error?.response?.data?.message || "Invalid User")
					setErrorSB(true);
				});
			})
			.catch((error) => {
				var errorCode = error.code;
				const errorMessage = error.message;
				// console.log(errorCode);
				if (errorCode === "auth/wrong-password") {
					setErrormsg("Password is Incorrect please try again");
				} else if (errorCode === "auth/user-not-found") {
					setErrormsg("Email address is Incorrect please try again");
				} else {
					setErrormsg(error.code);
				}
				setErrorSB(true);
				console.log(errorCode, errorMessage);
			});
	};

	return (
		<BasicLayout image={bgImage}>
			<Card p={2}>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography
						variant="h4"
						fontWeight="medium"
						color="white"
						mt={1}
					>
						Sign in as
					</MDTypography>
					<MDBox mt={3}>
						<AppBar position="static">
							<Tabs
								orientation={tabsOrientation}
								value={tabValue}
								onChange={handleSetTabValue}
							>
								<Tab
									label="Tenant"
									icon={
										<Icon
											fontSize="small"
											sx={{ mt: -0.25 }}
										>
											home
										</Icon>
									}
									// {...a11yProps(0)}
								/>
								{/* <Tab
									label="Self-Onboarding"
									icon={
										<Icon
											fontSize="small"
											sx={{ mt: -0.25 }}
										>
											settings
										</Icon>
									}
									// {...a11yProps(1)}
								/> */}
								<Tab
									label="Vendor"
									icon={
										<Icon
											fontSize="small"
											sx={{ mt: -0.25 }}
										>
											email
										</Icon>
									}
									// {...a11yProps(2)}
								/>
							</Tabs>
						</AppBar>
					</MDBox>
				</MDBox>

				<MDBox pt={4} pb={3} px={3}>
					<TabPanel value={tabValue} index={0}>
						{successSB ? (
							<MDSnackbar
								color="success"
								icon="check"
								title="SaaS Admin Portal"
								content={`Welcome`}
								dateTime={new Date().toLocaleString()}
								open={successSB}
								onClose={closeSuccessSB}
								close={closeSuccessSB}

								// bgWhite
							/>
						) : (
							<MDSnackbar
								color="error"
								icon="warning"
								title="SaaS Admin Portal"
								content={errormsg}
								dateTime={new Date().toLocaleString()}
								open={errorSB}
								onClose={closeErrorSB}
								close={closeErrorSB}

								// bgWhite
							/>
						)}
						{/* <MDBox
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GoogleButton onClick={(e) => TenantsignInWithGoogle(e)} />
            </MDBox> */}

						<MDBox pt={4} pb={3} px={3}>
							<MDBox component="form" role="form">
								<MDBox mb={2}>
									<MDInput
										type="email"
										label="Email"
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										fullWidth
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDInput
										type="password"
										label="Password"
										value={password}
										onChange={(e) =>
											setPassword(e.target.value)
										}
										fullWidth
									/>
								</MDBox>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										onClick={(event) => {
											handleSignIn(event);
										}}
									>
										sign in
									</MDButton>
								</MDBox>
								<MDBox mt={3} mb={1} textAlign="center">
									<MDTypography variant="button" color="text">
										Forgot your password?{" "}
										<MDTypography
											component={Link}
											to="/reset-password"
											variant="button"
											color="info"
											fontWeight="medium"
											textGradient
										>
											Reset Password
										</MDTypography>
									</MDTypography>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel>
					{/* <TabPanel value={tabValue} key={tabValue} index={1}>
						<MDBox>
							<MDBox
								component="form"
								role="form"
								onSubmit={handleSubmit(onSubmit)}
							>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<MDBox mb={2}>
											<MDInput
												type="text"
												label="Tenant Id"
												variant="standard"
												id="tenantId"
												value={UniqueTenantId}
												InputProps={{
													readOnly: true,
												}} //**
												fullWidth
												{...register("tenantId", {
													required: "Required",
													maxLength: {
														value: 6,
														message:
															"Please enter less than 6 characters",
													},
													pattern: {
														value: RegexCode.tenantIdRegex,
														message:
															"White Space, Capital and Special Characters are not allowed",
													},
													//   onChange: (e) => autofillBasedOnSubdomain(e),
												})}
												error={
													Boolean(errors.tenantId) ||
													Boolean(
														errors.capitalTenantId
													)
												}
												helperText={
													errors.capitalTenantId
														? errors.capitalTenantId
																.message
														: errors.tenantId
														? errors.tenantId
																.message
														: ""
												}
											/>
										</MDBox>
									</Grid>
								</Grid>
								<MDBox mb={2}>
									<MDInput
										type="text"
										label="Organization Name"
										variant="standard"
										fullWidth
										id="org-name"
										{...register("orgName", {
											required: {
												value: true,
												message: "Required",
											},
											pattern: {
												value: !RegexCode.orgNameRegex,
												message:
													"Special Characters are not allowed",
											},
											maxLength: {
												value: 20,
												message:
													"Maximum 20 allowed characters",
											},
										})}
										error={Boolean(errors.orgName)}
										helperText={
											errors.orgName
												? errors.orgName.message
												: ""
										}
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDInput
										type="email"
										label="Admin E-mail"
										variant="standard"
										fullWidth
										id="admin-email"
										{...register("adminEmail", {
											required: {
												value: true,
												message: "Required",
											},
											pattern: {
												value: RegexCode.email,
												message: "Enter a valid email",
											},
										})}
										error={Boolean(errors.adminEmail)}
										helperText={
											errors.adminEmail
												? errors.adminEmail.message
												: ""
										}
									/>
								</MDBox>

								<MDBox mb={2}>
									<MDInput
										type="text"
										label="Logo Url"
										variant="standard"
										fullWidth
										id="logo-url"
										{...register("orgLogo", {
											required: {
												value: true,
												message: "Required",
											},
											pattern: {
												value: RegexCode.orgLogo,
												message: "Enter a valid URL",
											},
										})}
										error={Boolean(errors.orgLogo)}
										helperText={
											errors.orgLogo
												? errors.orgLogo.message
												: ""
										}
									/>
								</MDBox>

								<MDBox mt={4} mb={1}>
									<MDButton
										variant="gradient"
										color="info"
										fullWidth
										// onClick={onboardTenant}
										onClick={handleSubmit(onSubmit)}
									>
										Onboard
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</TabPanel> */}
					<TabPanel value={tabValue} index={1}>
						<MDBox
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<GoogleButton
								onClick={(e) => VendorsignInWithGoogle(e)}
							/>
						</MDBox>
					</TabPanel>
				</MDBox>
			</Card>
		</BasicLayout>
	);
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
export default Basic;
