// MUI components
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MDDrop from "../../../components/MDDropdown";
import MDSelect from "../../../components/MDSelect";
import MDSnackbar from "../../../components/MDSnackbar";

// React Router
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import { RegexCode } from "../../config/validationRegex";
// import { editAdmin } from "../functions/adminFormFunctions/index";

import { useSelector } from "react-redux";
import { selectTenant } from "../../../redux/userSlice";

import { addGoogleProvider } from "../../../apis/tenant";
import { updateSamlProvider, updateOidcProvider } from "../../../apis/tenant";
import subStr from "../../../helperFunctions/subString";

function EditIapDetailsSAML(props) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isDisabled, setIsDisabled] = useState(false);
  // let isDisabled = false;

  let { state } = useLocation();

  const user = useSelector(selectTenant);

  // const [access, setAccess] = useState(state.admin.isAdmin);
  const [enable, setEnable] = useState(state.data.enabled);
  const [displayName, setDisplayName] = useState(state.data.displayName);
  const [providerId, setProviderId] = useState(state.data.providerId);
  const [clientId, setClientId] = useState(state.data.clientId);
  const [entityId, setEntityId] = useState(state.data.idpEntityId);
  const [ssoUrl, setSsoUrl] = useState(state.data.ssoURL);
  const [x509Certificates, setX509Certificates] = useState(
    state.data.x509Certificates
  );
  const [rpEntityId, setRpEntityId] = useState(state.data.rpEntityId);
  const [callbackURL, setCallbackURL] = useState(state.data.callbackURL);

  const [successSB, setSuccessSB] = useState(false);
  const [infoSuccessSB, setInfoSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // const [access, setAccess] = useState();

  const handleChange = (event) => {
    // console.log("event data", event.target.value);
    setEnable(event.target.value);
  };

  const handleButtonChange = (error) => {
    // console.log("changing", error);
    if (error) {
      // console.log("entering if");
      // setIsDisabled(true);
      // isDisabled = true;
    } else {
      // isDisabled = false;
      // setIsDisabled(false);
    }
  };

  const closeSuccessSB = () => {
    setSuccessSB(false);
    navigate("/identity-providers");
  };
  const closeErrorSB = () => setErrorSB(false);

  const onSubmit = async () => {
    const dataToSend = {
      // subdomain : subStr(providerId,".","b"),
      subdomain: user.subdomain,
      displayName: displayName,
      enabled: enable,
      providerId: providerId,
      idpEntityId: entityId,
      ssoURL: ssoUrl,
      x509Certificates: x509Certificates,
      rpEntityId: rpEntityId,
      callbackURL: callbackURL,
    };

    // console.log("data in idp edit for saml", dataToSend);
    let updateResponse = await updateSamlProvider(dataToSend);
    if (updateResponse.status === 200) {
      setSuccessSB(true);
    } else if (updateResponse.status === 403) {
      console.log("Unauthorised: Logging Out");
      dispatch(logout());
      localStorage.removeItem("persist:root");
      localStorage.clear();
      window.location.href = "/sign-in";
    } else if (updateResponse.status === 500) {
      setErrorMsg(updateResponse.data.message);
      setErrorSB(true);
    }
    else
    {
      setErrorMsg(updateResponse.data.message);
			setErrorSB(true);
    }
    if (providerId == "Google") {
      // console.log("Google Update12");
      const googleData = {
        subdomain: user.subdomain,
        enabled: enable,
      };
      setInfoSuccessSB(true);
      addGoogleProvider(googleData)
        .then((result) => {
          if (result.status === 200) {
            // console.log("added Successfully");
            setSuccessSB(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorSB(true);
        });
      // console.log(googleData);
    }
    // console.log(dataToSend);
  };

  return (
    <>
      {infoSuccessSB && (
        <MDSnackbar
          icon="notifications"
          title="SaaS Admin Portal"
          content={`Updating Google Idp details is in progress`}
          // dateTime="11 mins ago"
          open={infoSuccessSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}

          // bgWhite
        />
      )}
      {successSB ? (
        <MDSnackbar
          color="success"
          icon="check"
          title="SaaS Admin Portal"
          content={`Edited details of idp successfully`}
          // dateTime="11 mins ago"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}

          // bgWhite
        />
      ) : (
        <MDSnackbar
          color="error"
          icon="warning"
          title="SaaS Admin Portal"
          content={errorMsg==''
          ? `Error Occurred on Edit details of Idp` : `${errorMsg}`}
          // dateTime="11 mins ago"
          open={errorSB}
          onClose={closeErrorSB}
          close={closeErrorSB}
          // bgWhite
        />
      )}
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Edit Identity Provider
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter the following information to update Identity Provider
              details
            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Display Name"
                      variant="standard"
                      fullWidth
                      id="displayName"
                      defaultValue={state.data.displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="ProviderID"
                      variant="standard"
                      fullWidth
                      id="providerID"
                      defaultValue={state.data.providerId}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="IP Entity ID"
                      variant="standard"
                      fullWidth
                      id="ipEntityId"
                      defaultValue={state.data.idpEntityId}
                      onChange={(e) => setEntityId(e.target.value)}

                      // InputProps={{
                      //   readOnly: true,
                      // }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="SSOUrl"
                      variant="standard"
                      fullWidth
                      id="ssoUrl"
                      defaultValue={state.data.ssoURL}
                      onChange={(e) => setSsoUrl(e.target.value)}

                      // InputProps={{
                      //   readOnly: true,
                      // }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="RREntityID"
                      variant="standard"
                      fullWidth
                      id="rrEntityId"
                      defaultValue={state.data.rpEntityId}
                      onChange={(e) => setRpEntityId(e.target.value)}

                      // InputProps={{
                      //   readOnly: true,
                      // }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="CallBackURL"
                      variant="standard"
                      fullWidth
                      id="callBackUrl"
                      defaultValue={state.data.callbackURL}
                      onChange={(e) => setCallbackURL(e.target.value)}

                      // InputProps={{
                      //   readOnly: true,
                      // }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      multiline
                      rows={5}
                      label="X.509 certificates"
                      variant="standard"
                      fullWidth
                      id="x.509certificates"
                      defaultValue={state.data.x509Certificates}
                      onChange={(e) => setX509Certificates([e.target.value])}
                      // InputProps={{
                      //   readOnly: true,
                      // }}
                      //   onChange={(e) => setX509Certificates([e.target.value])}
                    />
                  </MDBox>
                </Grid>
              </Grid>

              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  disabled={isDisabled}
                >
                  update
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
}

export default EditIapDetailsSAML;
