// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

import colors from "../../../../assets/theme/base/colors";
import typography from "../../../../assets/theme/base/typography";
//mui image/ logo
import MDAvatar from "../../../../components/MDAvatar";
import burceMars from "../../../../assets/images/bruce-mars.jpg";
import { padding } from "@mui/system";

function TenantInfoCard({ title, info, logo, action, shadow, metadata }) {
	const labels = [];
	const values = [];
	const metadataLabels = [];
	const metadataValues = [];
	const { socialMediaColors } = colors;
	const { size } = typography;
	// console.log(info.email);
	// Convert this form `objectKey` of the object key in to this `object key`
	if (info) {
		Object.keys(info).forEach((el) => {
			if (el.match(/[A-Z\s]+/)) {
				const uppercaseLetters = el.match(/[A-Z][a-z]*/g); // Match multiple uppercase characters
				let newElement = el;
				uppercaseLetters.forEach((letter) => {
					newElement = newElement.replace(
						letter,
						` ${letter.toLowerCase()}`
					);
				});
				labels.push(newElement);
			} else {
				labels.push(el);
			}
		});

		// Push the object values into the values array
		Object.values(info).forEach((el) => values.push(el));
	}

	if (metadata) {
		Object.keys(metadata).forEach((el) => {
			if (el.match(/[A-Z\s]+/)) {
				const uppercaseLetters = el.match(/[A-Z][a-z]*/g); // Match multiple uppercase characters
				let newElement = el;
				uppercaseLetters.forEach((letter) => {
					newElement = newElement.replace(
						letter,
						` ${letter.toLowerCase()}`
					);
				});
				metadataLabels.push(newElement);
			} else {
				metadataLabels.push(el);
			}
		});

		// Push the object values into the values array
		Object.values(metadata).forEach((el) => metadataValues.push(el));
	}

	// Render the card info items
	const renderItems = labels.map((label, key) => (
		<MDBox key={label} display="flex" py={1} pr={11}>
			<MDTypography
				variant="button"
				fontWeight="bold"
				textTransform="capitalize"
			>
				{label}: &nbsp;
			</MDTypography>
			<MDTypography variant="button" fontWeight="regular" color="text">
				&nbsp;{values[key]}
			</MDTypography>
		</MDBox>
	));

	// Render the card info items
	const renderMetadataItems = metadataLabels.map((label, key) => (
		<MDBox key={label} display="flex" py={1} pr={11}>
			<MDTypography
				variant="button"
				fontWeight="bold"
				textTransform="capitalize"
			>
				{label}: &nbsp;
			</MDTypography>
			<MDTypography variant="button" fontWeight="regular" color="text">
				&nbsp;{metadataValues[key]}
			</MDTypography>
		</MDBox>
	));

	return (
		<Card sx={{ boxShadow: !shadow && "none" }}>
			<MDBox p={0}>
				{/* <MDBox opacity={0.3}>
					<Divider />
				</MDBox> */}
				<MDBox>
					<Grid container spacing={3} alignItems="center">
						<Grid item>
							<MDAvatar
								src={logo.logo[0]}
								alt="profile-image"
								size="xl"
								shadow="sm"
							/>
						</Grid>
						<Grid item>
							<MDBox height="100%" mt={0.5} lineHeight={1}>
								<MDTypography variant="h6" fontWeight="medium">
									{logo.subdomain}
								</MDTypography>
								<MDTypography
									variant="button"
									color="text"
									fontWeight="regular"
								>
									{logo.tier}
								</MDTypography>
							</MDBox>
						</Grid>
					</Grid>

					{info && renderItems}
					{metadata && renderMetadataItems}
				</MDBox>
			</MDBox>
		</Card>
	);
}

// Setting default props for the ProfileInfoCard
TenantInfoCard.defaultProps = {
	shadow: true,
};

// Typechecking props for the ProfileInfoCard

TenantInfoCard.propTypes = {
	title: PropTypes.string.isRequired,
	info: PropTypes.objectOf(PropTypes.string).isRequired,
	action: PropTypes.shape({
		route: PropTypes.string.isRequired,
		tooltip: PropTypes.string.isRequired,
	}).isRequired,
	shadow: PropTypes.bool,
};

export default TenantInfoCard;
