import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import EditAdminForm from "../../../forms/editAdmin";

function EditAdmin(props) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EditAdminForm />
    </DashboardLayout>
  );
}

export default EditAdmin;
